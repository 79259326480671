import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// Services
import { getCurrentRecord, updateRecord } from '../../../services/apis/record'

// Components
import AppMessage from '../../../components/shared/AppMessage'
import BadgeStep from '../../../components/shared/BadgeStep'

// Methods
import { getWorkspaceParams } from '../../../common/methods/params'
import { getRecord } from '../../../common/methods/storage'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import ButtonStar from '../../../components/shared/ButtonStar'

// Constants
import { conversation } from '../../../constants/lists/conversation'
import { PAGES, PAGES_ROUTES } from '../../../constants/Pages'
import { REACTIONS } from '../../../constants/reactions'

function Conversation () {
  const navigate = useNavigate()

  const { token } = useParams()
  const { url } = getWorkspaceParams()

  const record = getRecord()

  const currentConversation = conversation.find(cns => cns.id === token)

  const [currentOldWomanMessage, setCurrentOldWomanMessage] = useState<any>(null)
  const [messageSelected, setMessageSelected] = useState<any>(-1)

  useEffect(() => {
    getCurrentUserConversation()
    getCurrentOldWomanMessage()
  }, [token])

  const getCurrentUserConversation = () => {
    if (!token || !(currentConversation && record)) return

    let conversation_list = record.conversations?.split(';') ?? []
    conversation_list = conversation_list.map((conversation: string) => JSON.parse(conversation))

    const currentIndex = conversation_list.findIndex((convers: any) => convers.id === token)
    if (currentIndex !== -1) setMessageSelected(conversation_list[currentIndex].message)
  }

  const getCurrentOldWomanMessage = () => {
    if (currentConversation) {
      const oldWomanMessages = currentConversation.app_messages
      const messageIndex = oldWomanMessages.findIndex(message => message.key === currentOldWomanMessage?.key)

      if (messageIndex < oldWomanMessages.length - 1) {
        setCurrentOldWomanMessage(oldWomanMessages[messageIndex + 1])
      } else {
        setCurrentOldWomanMessage(currentConversation.response)
      }
    }
  }

  const getPreviousOldWomanMessage = () => {
    if (currentConversation) {
      const oldWomanMessages = currentConversation.app_messages
      const messageIndex = oldWomanMessages.findIndex(message => message.key === currentOldWomanMessage?.key)

      if (messageIndex > 0) {
        setCurrentOldWomanMessage(oldWomanMessages[messageIndex - 1])
      } else if (currentConversation.response.key === currentOldWomanMessage?.key) {
        const last = oldWomanMessages.length - 1
        setCurrentOldWomanMessage(oldWomanMessages[last])
      }
    }
  }

  const onChangeSelected = (value: number) => {
    setMessageSelected(value)
    setCurrentOldWomanMessage(currentConversation?.response)
  }

  const formatConversationToSave = (record: any) => {
    let conversation_list: any[] = []

    if (record && token) {
      conversation_list = record.conversations?.split(';') ?? []
      conversation_list = conversation_list.map((conversation: string) => JSON.parse(conversation))

      const currentIndex = conversation_list.findIndex((conver: any) => conver.id === token)

      if (currentIndex !== -1) conversation_list[currentIndex].message = messageSelected
      else conversation_list.push({ id: token, message: messageSelected })
    }

    conversation_list = conversation_list.map((conversation: any) => JSON.stringify(conversation))

    return conversation_list.join(';') ?? null
  }

  const getAllowShowOptions = () => {
    return currentConversation &&
           currentOldWomanMessage?.key >= currentConversation.app_messages.length &&
           currentOldWomanMessage?.key < currentConversation.response.key
  }

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

  const onSetStep = async () => {
    if (!record || !currentConversation) return

    const { type } = record
    const { step, next } = currentConversation

    const conversation_list = formatConversationToSave(record)

    await updateRecord({
      step: (type === 'game' && step === 4) ? 27 : (step ?? 0) + 1,
      type: type ?? 'search',
      conversations: conversation_list
    })
      .then(async () => {
        if (type === 'game' && step === 4) await getCurrentRecord()

        let nextPage = next

        if (next.includes('decision') && isMobile()) {
          const pageDecisionIndex = PAGES_ROUTES.findIndex(page => page.route === next)

          if (pageDecisionIndex !== -1) {
            nextPage = PAGES_ROUTES[pageDecisionIndex].next?.SIMPLE ?? next
          }
        }

        navigate(`/${url}${(type === 'game' && step === 4) ? PAGES.TALENTS : nextPage}`)
      })
  }

  const getPrevious = () => {
    if (currentConversation?.step === 13) {
      if (record) {
        const feature = record.features.find((feature: any) => feature.id === '748608c0-90a8-11ed-948b-834ce149ae0d' && feature.type === 'nouns')

        if (feature && feature.profiles.reaction === REACTIONS.DISLIKE) {
          navigate(`${url}${currentConversation.previous}`)
        } else {
          navigate(`/${url}${PAGES.DISCIPLINES_ITEMS}748608c0-90a8-11ed-948b-834ce149ae0d`)
        }
      }
    } else navigate(`/${url}${currentConversation?.previous}`)
  }

  return (
    <article className="bg-main bg-oldwoman bg-compass" id="home">
      <section className="w-screen h-screen overflow-hidden relative cloud-page">
          <div className="gradient-purple" />
          {
            (currentConversation?.step !== 1 || currentOldWomanMessage?.key !== 1) &&
            <ButtonRedirect
              text="return"
              action="return"
              linkReturn={''}
              callback={ currentOldWomanMessage?.key === 1 ? getPrevious : getPreviousOldWomanMessage}
            />
          }
          <BadgeStep currentStep={currentConversation?.step} />

          {
            currentConversation && (currentConversation.user_message.length === 0 ||
            currentOldWomanMessage?.key < currentConversation?.app_messages.length || messageSelected > -1) &&
            <ButtonRedirect
              text="next"
              action="next"
              callback={ currentOldWomanMessage?.key === currentConversation?.response.key ? onSetStep : getCurrentOldWomanMessage }
              linkReturn={`/conversation/${token}`}
            />
          }

          <div className="h-full content-background overflow-hidden py-4 relative">
            <AppMessage
              index={0}
              useAnimationLoading={false}
              size="w-1/2 max-w-[800px] h-auto"
              title={currentOldWomanMessage?.message.title}
              message={currentOldWomanMessage?.message.content}
            />

            {
               getAllowShowOptions() &&
              <div className="h-full z-10">
                <div key={currentConversation?.id} className={`${getAllowShowOptions() ? 'user-conversation-group' : ''} z-[120]`}>
                  <div>
                    {
                      currentConversation?.user_message.map(user_message => {
                        return (
                          <ButtonStar
                            key={user_message.key}
                            type='white'
                            text={user_message.message.title}
                            selected={messageSelected === user_message.key}
                            callback={ () => messageSelected === -1 ? onChangeSelected(user_message.key) : null }
                            size="max-[480px]:w-[147px] max-[480px]:h-[42px] w-[200px] h-[54px] xl:w-[216px] xl:h-[60px]"
                            textSize="max-[480px]:text-[10.5px]"
                          />
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            }
          </div>

      </section>
    </article>
  )
}

export default Conversation

import { Link, useNavigate } from 'react-router-dom'
import { getWorkspaceParams } from '../../common/methods/params'

// Images
import arrowDownButton from '../../assets/images/buttons/arrow-down-btn.svg'
import btnNext from '../../assets/images/buttons/btn-next.svg'
import btnReturn from '../../assets/images/buttons/btn-return.svg'

interface ButtonProps {
  action?: string,
  state?: any,
  text: string,
  size?: string,
  type?: string,
  loading?: boolean,
  next?: boolean,
  linkReturn?: string,
  borderColor?: string,
  showDesing?: boolean,
  customStyle?: string,
  callback?: () => void,
  style?: 'simple' | 'complex',
  position?: 'left' | 'right' | 'center',
  bubbleSize?: 'small' | 'big'
}

const ButtonRedirect = ({ action = 'callback', text, state, size, type, next, loading = false, linkReturn, borderColor, style = 'complex', position = 'center', showDesing = false, customStyle = '', callback, bubbleSize = 'small' }: ButtonProps) => {
  const { url } = getWorkspaceParams()
  const navigate = useNavigate()

  const returnPage = () => {
    if (linkReturn && linkReturn.length > 0) {
      navigate(`/${url}${linkReturn}`)
    }
    if (callback) callback()
  }
  return (
    <>
      {
        action === 'return' &&
        <button onClick={returnPage} className={`absolute top-[30px] left-[40px] z-[450] w-[40px] h-[40px] max-[480px]:left-[auto] max-[480px]:right-[calc((100dvw*.9)-20px)] ${bubbleSize === 'small' ? 'max-[480px]:top-[calc((100dvw*.2)-5px)]' : 'max-[480px]:top-[calc((100dvw*.2))]'} lg:w-[50px] lg:h-[50px] 2xl:w-[55px] 2xl:h-[55px] text-[0px] bg-cover transition ease opacity-60 max-[480px]:opacity-100 hover:opacity-100 hover:scale-110 right-arrow`} style={{ backgroundImage: `url(${btnReturn})` }}>Return</button>
      }
      { action === 'next' &&
        <>
          <div className={`${style === 'complex' ? 'w-full h-[25%] fixed bottom-0 right-0 left-0 z-[400] before:absolute before:bottom-0 before:h-[100px] before:bg-[linear-gradient(0deg,rgb(98,10,209,55%),transparent)] before:left-0 before:right-0 max-[480px]:h-[20%]' : ''}`}>
            <Link onClick={callback} to={linkReturn && linkReturn.length > 0 ? ('/' + url + linkReturn) : ''} state={state} className={`absolute ${style === 'complex' ? 'top-0' : 'bottom-[5%] max-[480px]:top-[calc(100%-108px)] max-[480px]:bottom-[auto]'} z-[250] w-[235px] max-[480px]:w-[136px] max-[480px]:h-[38px] sm:w-[235px] md:w-[235px] lg:w-[245px] xl:w-[255px] 2xl:w-[260px] h-[135px] text-[0px] bg-center bg-contain bg-no-repeat transition-all ease-in opacity-100 drop-shadow-[0px_0px_18px_rgba(255,255,255,.65)] hover:scale-110 ${customStyle}`}
            style={{
              backgroundImage: `url(${btnNext})`,
              left: position === 'left' ? '30px' : position === 'center' ? '0' : 'auto', // Ajuste para center
              right: position === 'right' ? '30px' : position === 'center' ? '0' : 'auto', // Ajuste para center
              margin: position === 'center' ? '0 auto' : '0'
            }}>Next</Link>
          </div>
        </>
      }
      {
        action !== 'return' && action !== 'next' &&
        <button
          onClick={callback}
          disabled={loading}
          type={type ? 'submit' : 'button'}
          className={`relative bg-[#070625] rounded-[50px] py-[5px] px-[7px] text-[14px] xl:text-base fhd:text-[24px] leading-4 ff-cp--bold text-white bg-cover border border-solid ${showDesing ? 'right-arrow left-arrow' : ''} w-[162px] h-[42px] min-w-[162px] min-h-[42px] xl:w-[200px] xl:h-[59px] fhd:w-[293px] fhd:h-[71px] xl:mb-4 opacity-70 transition ease-in hover:opacity-100 hover:scale-110 max-[480px]:text-[12px]`}
          style={{ borderColor, boxShadow: `0px 0px 2px 0px ${borderColor}` }}>
          <div className="grid relative place-content-center w-full h-full border-[0.5px] border-solid border-[#FFFFFF]/[.5] rounded-[50px]">
            <span>{text}</span>
          </div>
          {
            showDesing &&
            <div className="absolute top-[calc(100%-4px)] left-0 right-0 m-auto w-[30px] h-[35px] bg-no-repeat bg-cover bg-center"
              style={{ backgroundImage: `url(${arrowDownButton})` }} ></div>
          }
        </button>
      }
    </>
  )
}

export default ButtonRedirect

import { useEffect, useState } from 'react';
import bgMessageMobile from '../../assets/images/bubble-oldwoman-mobile.svg';
import bgMessage from '../../assets/images/bubble-oldwoman.svg';

interface MessageProps {
    title?: string;
    message?: string;
    index: number;
    size?: string;
    useAnimationLoading?: boolean
}

const AppMessage = ({ message, title, size = 'w-[350px] h-[90px]', index = -1, useAnimationLoading = true }: MessageProps) => {
  const [canShow, setCanShow] = useState<boolean>(false)
  const [isloading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const delay = 1500 * index
    const delay2 = delay + 1500
    setTimeout(() => {
      setCanShow(true)
    }, delay)
    setTimeout(() => {
      setLoading(false)
    }, delay2)
  }, [index])

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

  return (
    canShow
      ? <div className="w-full h-min my-1">
        {
            isloading && useAnimationLoading
              ? <div className="chat-bubble">
                    <div className="typing">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                </div>
              : <div className="flex justify-center">
                    <div
                        className={`${size} min-w-[525px] z-[100] aspect-[5] grid place-content-center mt-[-6px] pt-[22px] pb-[18px] pl-5 pr-5 xl:pl-8 xl:pr-10 ff-cp--normal text-white text-center bg-no-repeat bg-cover max-[480px]:max-w-[100%] max-[480px]:min-w-[50%] max-[480px]:w-[calc(100dvw-5px)] max-[480px]:py-3 max-[480px]:bg-center  max-[480px]:aspect-[4.5] ${useAnimationLoading ? 'animation-charge' : ''}`}
                        style={{ backgroundImage: `url(${isMobile() ? bgMessageMobile : bgMessage})` }}
                    >
                        {
                            title?.split('<br />')
                              .map((phrase, index) => {
                                return (
                                        <>
                                            <p key={phrase} className={`ff-cp--bold max-[480px]:text-[14px] max-[480px]:leading-5 ${message ? 'text-[calc(15px+.6dvw)] leading-[calc(18px+.6dvw)]' : 'text-[calc(20px+.45dvw)] leading-[calc(22px+.45dvw)]'} text-white`}> {phrase} </p>
                                        </>
                                )
                              })
                        }
                        {
                            message?.split('<br />')
                              .map((phrase, index) => {
                                return (
                                        <>
                                            <p key={phrase} className="ff-cp--regular text-center text-[calc(18px+.28dvw)] leading-[calc(16px+.28dvw)] max-[480px]:text-[12px] max-[480px]:leading-[14px]"> {phrase} </p>
                                        </>
                                )
                              })
                        }
                    </div>
                </div>
        }
    </div>
      : <></>
  )
}

export default AppMessage

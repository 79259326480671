export const CAREER_CLASSIFICATION = {
  OPTIMA: 'OPTIMA',
  IDEAL: 'IDEAL',
  DEFIANT: 'DEFIANT',
  AMBIGUOUS: 'AMBIGUOUS',
  COMPLEMENT: 'COMPLEMENT'
}

export const CAREER_CLASSIFICATION_LABEL = {
  OPTIMA: 'Óptima',
  IDEAL: 'Ideal',
  DEFIANT: 'Advertencia',
  AMBIGUOUS: 'Ambigua',
  COMPLEMENT: 'Complementaria'
}

export const CAREER_CLASSIFICATION_COLOR = {
  OPTIMA: '#52008B',
  IDEAL: '#00038B',
  COMPLEMENT: '#7D008C',
  DEFIANT: '#F7007C',
  AMBIGUOUS: '#C00087'
}

export const CAREER_CLASSIFICATION_LIST = [CAREER_CLASSIFICATION.IDEAL, CAREER_CLASSIFICATION.OPTIMA, CAREER_CLASSIFICATION.COMPLEMENT, CAREER_CLASSIFICATION.AMBIGUOUS, CAREER_CLASSIFICATION.DEFIANT]

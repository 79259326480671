import { Draggable, DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd'
import React from 'react'

import starConqueror from '../../assets/images/stars/star-verb-conqueror.svg'
import starCreator from '../../assets/images/stars/star-verb-creator.svg'
import starDisabled from '../../assets/images/stars/star-verb-disabled.svg'
import starDiver from '../../assets/images/stars/star-verb-diver.svg'
import starWeaver from '../../assets/images/stars/star-verb-weaver.svg'

class DraggableCard extends React.Component<{ feature: any, index: any, disabled: boolean }> {
  render () {
    const startVerbs = [
      starConqueror,
      starCreator,
      starDiver,
      starWeaver
    ]

    return (
      <Draggable
        draggableId={this.props.feature.id}
        index={this.props.index}
        isDragDisabled={this.props.disabled}
      >
        {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
          <div ref={providedDraggable.innerRef}
            {...providedDraggable.draggableProps}
            {...providedDraggable.dragHandleProps}
          >
            <div
              className="relative flex flex-col items-center justify-center gap-y-[2px] text-center bg-contain bg-no-repeat w-[66px] h-fit cursor-grab z-50 inherit">
                <img width="48" height="60" src={this.props.disabled ? starDisabled : startVerbs[this.props.feature.order - 1]} />
                <label className={'ff-cp--bold text-white text-xs tracking-wide max-[480px]:text-[11px] max-[480px]:leading-[11px]'}>
                  {this.props.feature.name}
                </label>
            </div>

          </div>
        )}
      </Draggable>
    )
  }
}

export default DraggableCard

import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import Joyride from 'react-joyride'
import Slider from 'react-slick'

// Services
import { createReference, deleteReference } from '../../services/apis/career_reference'
import { getAllByInstitutionAndCareer } from '../../services/apis/course'
import { createCourseLikeByList } from '../../services/apis/course_like'
import { getMeshWithData } from '../../services/apis/mesh'

// Components
import ButtonRedirect from '../../components/shared/ButtonRedirect'
import CourseCard from '../../components/shared/CourseCard'
import DashboardMenu from '../../components/shared/DarshboardMenu'
import OptionButton from '../../components/shared/OptionButton'
import Tooltip from '../../components/shared/Tooltip'

// Constants
import { REACTIONS } from '../../constants/reactions'

// Methods
import { getWorkspaceParams } from '../../common/methods/params'
import { getDemoMeshStatus, getUser } from '../../common/methods/storage'

// Images
import avatarDefault from '../../assets/images/avatar-default.svg'
import bgCounter from '../../assets/images/bg-counter-tinder.svg'
import iconBackWhite from '../../assets/images/icons/icon-back-white.svg'
import iconHeart from '../../assets/images/icons/icon-heart.svg'
import iconStarBig from '../../assets/images/icons/icon-hearth.svg'
import arrowLeft from '../../assets/images/icons/icon-left-mesh.svg'
import arrowRight from '../../assets/images/icons/icon-right-mesh.svg'
import iconStar from '../../assets/images/icons/icon-star-point.svg'
import { isMobile } from '../../common/methods/devices'
import IntroTooltip from '../../components/intro/IntroTooltip'
import { MESH } from '../../constants/tutorial'

const Tinder = () => {
  const navigate = useNavigate()
  const { token } = useParams()

  const { url } = getWorkspaceParams()
  const user = getUser()
  const tutorialStatus = getDemoMeshStatus()

  const [selectedList, setSelectedList] = useState<any[]>([])
  const [courses, setCourses] = useState<any[]>([])
  const [restCourses, setRestCourses] = useState<any[]>([])
  const [loading, setLoading] = useState<any>({ status: true, course: '', reaction: '' })

  const [career, setCareer] = useState<any>(null)
  const [institution, setInstitution] = useState<any>(null)
  const [currentIndex, setCurrentIndex] = useState(1)

  const [loadingPage, setLoadingPage] = useState<boolean>(true)

  const [run, setRunTutorial] = useState<boolean>(false)
  const [stepList, setStepList] = useState<any>([])
  const [step, setStepTutorial] = useState<number>(0)

  const [loadingTutorial, setLoadingTutorial] = useState<boolean>(true)

  const sliderRef = useRef<any>(null)

  useEffect(() => {
    getMesh()
    setTimeout(() => {
      setLoadingPage(false)
    }, 800)
  }, [])

  useEffect(() => {
    if (tutorialStatus) {
      setStepList([...MESH])
      setStepTutorial(2)
      setRunTutorial(true)
      setLoadingTutorial(false)
    }
  }, [])

  const getCourses = (institution_id: string, career_id: string) => {
    getAllByInstitutionAndCareer(institution_id, career_id)
      .then(data => {
        setCourses(data.courses)
        setLoading({ status: false, course: '', reaction: '' })
      })
  }

  const getMesh = () => {
    if (token) {
      getMeshWithData(token)
        .then(data => {
          if (data.status) {
            setInstitution(data.institution)
            setCareer(data.career)
            getCourses(data.institution.id, data.career.career_id)
          }
        })
    }
  }

  const onChangeSelectedList = (course: any, reaction: string) => {
    setLoading({ status: true, course: course.id, reaction })

    const selected_list = selectedList

    const _index = selected_list.findIndex(sl => sl.id === course.id)

    if (_index === -1) selected_list.push({ ...course, course, reaction, course_id: course.id })
    else selected_list[_index].reaction = reaction

    setSelectedList([...selected_list])

    setLoading({ status: false, course: '', reaction: '' })
    handleNextClick()
  }

  const getCourseReaction = (_course: any) => selectedList.find((course: any) => course.id === _course.id)?.reaction ?? null

  const onGoToNextStep = () => {
    if (token) {
      createCourseLikeByList(selectedList, token)
        .then(data => {
          if (data.status) {
            redirectToPage(`/${url}/result/${token}`)
          }
        })
    }
  }

  const redirectToPage = (page: string) => navigate(page)

  const ArrowLeft = (props: any) => {
    const { currentSlide } = props
    const isVisible = currentSlide !== 0
    return (
            <button type='button' onClick={handleBackClick} className={`slick-prev ${isVisible ? '' : 'hidden'} w-[50px!important] h-[50px!important] opacity-70 scale-100 hover:opacity-100 hover:scale-110 before:!hidden pull-left max-[480px]:left-[auto!important] max-[480px]:right-[calc(100%-30px)] max-[480px]:w-[40px!important] max-[480px]:h-[40px!important]`}><img src={arrowLeft} className="pointer-events-none" alt="" /></button>
    )
  }

  const ArrowRight = (props: any) => {
    const { currentSlide, count } = props
    const isVisible = currentSlide <= count - 1
    return (
            <button type='button' onClick={handleNextClick} className={`slick-next ${isVisible ? '' : 'hidden'} w-[50px!important] h-[50px!important] opacity-70 scale-100 hover:opacity-100 hover:scale-110 before:!hidden pull-right max-[480px]:right-[auto!important] max-[480px]:left-[calc(100%-30px)] max-[480px]:w-[40px!important] max-[480px]:h-[40px!important]`}><img src={arrowRight} className="pointer-events-none" alt="" /></button>
    )
  }

  const handleNextClick = () => {
    if (sliderRef.current && currentIndex < (courses.length + restCourses.length)) {
      sliderRef.current.slickNext()
    }
  }

  const handleBackClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev()
    }
  }

  const settings = {
    className: 'slick-center-slider',
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile() ? 1 : 3,
    slidesToScroll: isMobile() ? 1 : 3,
    centerMode: true,
    centerPadding: '30px',
    variableWidth: false,
    draggable: false,
    afterChange: (index: number) => {
      setCurrentIndex(index + 1)
    }
  }

  const handleFavourite = async () => {
    if (career.career_reference_id != null) {
      await deleteReference(career.career_reference_id)
        .then(data => {
          if (data.status) {
            setCareer({
              ...career,
              career_reference_id: null
            })
          }
        })
    } else {
      await createReference(career.career_id)
        .then(data => {
          if (data.status) {
            setCareer({
              ...career,
              career_reference_id: data.data.id
            })
          }
        })
    }
  }

  useEffect(() => {
    if (currentIndex === courses.length - 1) {
      const coursesRest = courses.filter(({ id }, index) => !selectedList.map(selc => selc.course_id).includes(id) && index !== courses.length - 1)
      if (coursesRest.length > 0) {
        setRestCourses([...coursesRest])
      }
    }
  }, [selectedList])

  const handleCallback = (data: any) => {
    if (!run) return

    const { action, index, type } = data

    if (action === 'next' && index === step && type === 'step:after') setStepTutorial(step + 1)
    if (action === 'prev' && index === step && type === 'step:after') setStepTutorial(step - 1)
    document.querySelectorAll('.joyride-element-show').forEach(function (elemento) {
      elemento?.classList.remove('joyride-element-show')
    })

    if (data.step?.target !== 'body' && !!data.step?.target) {
      const targetElements = document.querySelectorAll(data.step?.target)

      if (typeof data.step.index === 'number' && data.step.index >= 0 && data.step.index < targetElements.length) {
        targetElements.forEach((element, index) => {
          if (index === data.step.index) {
            element.classList.add('joyride-element-show')
          } else {
            element.classList.remove('joyride-element-show')
          }
        })
      } else {
        targetElements.forEach(element => {
          element.classList.add('joyride-element-show')
        })
      }
    }

    if (type === 'tour:end') {
      setLoadingTutorial(true)
      setStepTutorial(0)
      setRunTutorial(false)
    }
  }

  return (
        <article id="tinder" className='institution-wrap bg-main-alt bg-hearth'>
            {
            !loadingTutorial && (
            <Joyride
                callback={handleCallback}
                steps={stepList}
                continuous
                hideCloseButton
                disableScrolling
                disableCloseOnEsc
                disableOverlayClose
                showSkipButton={false}
                stepIndex={step}
                run={run}
                tooltipComponent={IntroTooltip}
                styles={{
                  options: {
                    arrowColor: '#60DFFF',
                    overlayColor: 'rgba(0, 0, 12, 0.9)'
                  }
                }}
            />
            )}
            <section className="w-screen h-screen quiz-screen-background flex flex-col pt-6 px-6 pb-2 max-[480px]:py-0 max-[480px]:px-0">
                <DashboardMenu currentPage={2} disable={tutorialStatus} logoPosition='TOP' />
                {
                    courses.length === selectedList.length && courses.length > 0 &&
                    <ButtonRedirect text='next' action='next' callback={onGoToNextStep} linkReturn={`/courses/${token}`} customStyle='bottom-[-5px_!important] sm:bottom-[-5px_!important] md:bottom-[-8px_!important] lg:bottom-[-5px_!important]' />
                }

                <div className="w-full h-[50px] max-[480px]:h-auto max-[480px]:mb-[10px] max-[480px]:px-3.5">
                    <div className="flex items-center justify-between mt-[-2px] max-[480px]:flex-col max-[480px]:gap-y-4 max-[480px]:w-full">
                        <div className="flex max-[480px]:gap-y-4 max-[480px]:w-full">
                            <Link to={`/${url}/institution/${token}`} className={`w-[250px] h-[50px] ${isMobile() ? '' : 'dashboard-gradient'} bg-cover flex items-center justify-start gap-x-4 px-4 border-2 border-t-[#ffffff86] border-l-[#ffffff86] border-r-[#ffffff86] border-b-0 rounded-tl-[30px] rounded-tr-[8px] transition-all ease-in-out duration-200 opacity-50 hover:opacity-100 max-[480px]:w-[50px] max-[480px]:h-[60px] max-[480px]:border-none max-[480px]:p-0 max-[480px]:self-start max-[480px]:pt-[5px] max-[480px]:opacity-100`}>
                                <div className="w-[30px] h-[30px] rounded-full border border-[#ffffff7a] flex items-center justify-center hover:scale-105 hover:opacity-100 max-[480px]:w-[32px] max-[480px]:h-[32px]">
                                    <img src={iconBackWhite} alt="icon-back" width="12" height="12" />
                                </div>
                                {
                                  !isMobile() &&
                                  <div className="flex flex-col items-start justify-center">
                                    <p className="ff-m--regular text-white text-[12px] leading-none">Volver</p>
                                    <p className="ff-cp--bold text-white text-[16px] leading-none">Malla</p>
                                  </div>
                                }
                            </Link>
                            <div className="mt-[4px] z-[100] w-[310px] h-[50px] dashboard-gradient bg-cover flex items-center justify-start gap-x-4 px-4 border-2 border-t-[#fff] border-l-[#fff] border-r-[#fff] border-b-[#320a4e] rounded-tl-[30px] rounded-tr-[8px] max-[480px]:mt-0 max-[480px]:h-auto max-[480px]:border-none max-[480px]:w-full max-[480px]:justify-center max-[480px]:bg-none max-[480px]:pr-[45px]">
                                {
                                  !isMobile() &&
                                  <img src={iconHeart} alt="icon-heart" width="30" height="30" />
                                }
                                <p className="text-white ff-cp--bold text-[20px] leading-none">Revisar Malla</p>
                            </div>
                        </div>
                        {
                          !isMobile() &&
                          <div className="flex gap-x-1 items-center justify-between mr-2">
                            <div className="w-[36px] h-[36px] rounded-full border border-white overflow-hidden mr-2">
                                <img src={avatarDefault} alt="" className="w-[44px] h-[44px]" />
                            </div>
                            <p className="ff-cp--bold text-[22px] text-white whitespace-nowrap">
                                {
                                    user &&
                                    (user.name + ' ' + user.last_name)
                                }
                            </p>
                          </div>
                        }
                    </div>
                </div>
                <div className="w-full flex-1 dashboard-gradient border-2 h-[calc(100%-120px)] overflow-auto border-white/[.8] rounded-b-xl rounded-tl-none rounded-tr-[50px] max-[480px]:border-[1px] max-[480px]:w-[calc(100%-25px)] max-[480px]:h-[calc(100%-65px)] max-[480px]:mx-auto max-[480px]:rounded-2xl max-[480px]:overflow-hidden max-[480px]:rounded-b-none max-[480px]:border-b-0">
                    <div className="w-full h-full flex flex-col gap-y-4 px-[37px] py-[28px] rounded-tr-[20px] rounded-br-[20px] relative max-[480px]:px-3">
                        <div className="w-full flex flex-col">
                            {
                              !isMobile() &&
                              <div className="w-full flex items-center gap-[10px] mb-[5px] xl:mb-[10px] justify-center">
                                <div className="flex items-center relative gap-4">
                                    <Tooltip text='Agregar a Carreras Seleccionadas' color="#fff" backgroundColor="#070625" direction="bottom" extraClass='h-[32px]'>
                                        <button onClick={handleFavourite} className="w-[30px] h-[24px] bg-cover bg-no-repeat cursor-pointer" style={{ backgroundImage: `url(${iconStarBig})` }}></button>
                                    </Tooltip>
                                    <div className="w-[310px] text-center">
                                        <p className="ff-cp--bold text-[16px] leading-none text-white">{career?.career}</p>
                                    </div>
                                </div>
                                <div className='w-[20px] h-[20px]' style={{ backgroundImage: `url(${iconStar})` }} />
                                <div className="flex items-center relative gap-4">
                                    <div className="w-[310px] text-center">
                                        <h2 className="ff-cp--bold text-[16px] leading-none text-white">{institution?.name}</h2>
                                    </div>
                                    <Tooltip text='Agregar a Carreras Seleccionadas' color="#fff" backgroundColor="#070625" direction="bottom" extraClass='h-[32px]'>
                                        <button onClick={handleFavourite} className="w-[30px] h-[24px] bg-cover bg-no-repeat cursor-pointer" style={{ backgroundImage: `url(${iconStarBig})` }}></button>
                                    </Tooltip>
                                </div>
                            </div>
                            }
                            <div className="w-full flex justify-center max-w-[1127px] m-auto relative z-10">
                                <div className="w-[86px] h-[27px] xl:w-[116px] xl:h-[36px] flex justify-center items-center after:absolute after:top-[13px] xl:after:top-[17px] bg-cover after:w-full after:h-[1px] after:bg-white after:z-[-1]" style={{ backgroundImage: `url(${bgCounter})` }}>
                                    <span className='w-[92px] h-[21px] text-center ff-cp--bold text-[11px] xl:text-[16px] leading-[21px] text-white'>{currentIndex > courses.length ? courses.length : currentIndex}/{courses.length}</span>
                                </div>
                            </div>
                        </div>
                        {
                            !loadingPage &&
                            <div className="w-full max-h-[435px] flex-1 flex flex-col justify-center animation-charge z-[200] max-[480px]:my-auto">
                                <div className="discipline-slider h-full relative rounded-[20px]">
                                    <Slider ref={sliderRef} {...settings}
                                        prevArrow={<ArrowLeft className="absolute top-[180px] left-1 z-10"/>}
                                        nextArrow={<ArrowRight className="absolute top-[180px] right-0 z-10 max-[480px]:fixed" count={selectedList.length}/>}
                                    >
                                        {
                                            courses.map((course: any) => {
                                              return (
                                                    <CourseCard
                                                        key={course.id}
                                                        course={course}
                                                    >
                                                        <div className="slick-selector w-fit h-[220px] flex gap-1 justify-center z-50 max-[480px]:grid-cols-[124px_34px_124px] max-[480px]:justify-items-center">
                                                            <OptionButton
                                                                url="DISLIKE"
                                                                useIcon={false}
                                                                type="FINGER"
                                                                width='w-[155px] max-[480px]:w-[126px] xl:w-[241px]'
                                                                isSelected={getCourseReaction(course) === REACTIONS.DISLIKE}
                                                                callback={() => loading.status ? null : onChangeSelectedList(course, REACTIONS.DISLIKE)}
                                                            />
                                                            <OptionButton
                                                                url="N/A"
                                                                width='w-[155px] max-[480px]:w-[128px] xl:w-[241px]'
                                                                useIcon={false}
                                                                isSelected={getCourseReaction(course) === REACTIONS.DUDE}
                                                                callback={() => loading.status ? null : onChangeSelectedList(course, REACTIONS.DUDE)}
                                                            />
                                                            <OptionButton
                                                                url="LOVE"
                                                                width='w-[155px] max-[480px]:w-[126px] xl:w-[241px]'
                                                                useIcon={false}
                                                                type="FINGER"
                                                                isSelected={getCourseReaction(course) === REACTIONS.LIKE}
                                                                callback={() => loading.status ? null : onChangeSelectedList(course, REACTIONS.LIKE)}
                                                            />
                                                        </div>
                                                    </CourseCard>
                                              )
                                            })
                                        }
                                        {
                                          restCourses.map((course: any) => {
                                            return (
                                                  <CourseCard
                                                      key={course.id}
                                                      course={course}
                                                  >
                                                      <div className="slick-selector w-fit h-[220px] flex gap-1 justify-center z-50 max-[480px]:grid-cols-[124px_54px_124px] max-[480px]:justify-items-center">
                                                          <OptionButton
                                                              url="DISLIKE"
                                                              useIcon={false}
                                                              type="FINGER"
                                                              width='w-[241px] max-[480px]:w-[124px]'
                                                              isSelected={getCourseReaction(course) === REACTIONS.DISLIKE}
                                                              callback={() => loading.status ? null : onChangeSelectedList(course, REACTIONS.DISLIKE)}
                                                          />
                                                          <OptionButton
                                                              url="N/A"
                                                              width='w-[241px]  max-[480px]:w-[124px]'
                                                              useIcon={false}
                                                              isSelected={getCourseReaction(course) === REACTIONS.DUDE}
                                                              callback={() => loading.status ? null : onChangeSelectedList(course, REACTIONS.DUDE)}
                                                          />
                                                          <OptionButton
                                                              url="LOVE"
                                                              width='w-[241px]  max-[480px]:w-[124px]'
                                                              useIcon={false}
                                                              type="FINGER"
                                                              isSelected={getCourseReaction(course) === REACTIONS.LIKE}
                                                              callback={() => loading.status ? null : onChangeSelectedList(course, REACTIONS.LIKE)}
                                                          />
                                                      </div>
                                                  </CourseCard>
                                            )
                                          })
                                        }
                                        <></>
                                        <></>
                                    </Slider>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {
                  !isMobile() &&
                  <div className='w-full h-[50px]'></div>
                }
            </section>
        </article>
  )
}

export default Tinder

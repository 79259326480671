import { useEffect, useState } from 'react';

// Constants
import { CONSTELATION_URL, DESCRIPTION_CARD_URL, MESSAGE_CARD_URL } from '../../constants/cards';

interface CardProps {
  discipline?: any;
  state: string | null,
  color: string
}

const CardNoun = ({ discipline, state, color }: CardProps) => {
  const [constelation, setConstelation] = useState<string>('')
  const [card, setCard] = useState<string>('')
  const [descriptionCard, setDescriptionCard] = useState<string>('')

  useEffect(() => {
    const _url = MESSAGE_CARD_URL.find(url => url.includes(color))
    if (_url) setCard(_url)
  }, [color])

  useEffect(() => {
    if (discipline) {
      const _url = CONSTELATION_URL.find(url => url.includes(discipline.id))
      const _descriptionUrl = DESCRIPTION_CARD_URL.find(url => url.includes(discipline.code))
      if (_url) setConstelation(_url)
      if (_descriptionUrl) setDescriptionCard(_descriptionUrl)
    }
  }, [state])

  return (
   <div className="w-full h-full max-w-[750px] max-h-[620px] flex flex-col items-center justify-between justify-items-center z-50">
    <div className="relative h-[82%] max-[480px]:w-[95%] aspect-[1.15]">
      <div className="w-full h-full bg-contain bg-no-repeat bg-center drop-shadow-[0px_0px_26px_rgba(255,255,255,.19)]"
          style={{ backgroundImage: `url(${constelation})` }} />
      <div className="absolute flex flex-wrap flex-col items-center justify-between top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
        <div className="w-[210px] h-[58px] max-[480px]:w-[144px] max-[480px]:h-[40px] flex items-center justify-center pb-[10px] max-[480px]:pb-[7px] bg-cover bg-center bg-no-repeat text-[16px] text-white leading-[16px]" style={{ backgroundImage: `url(${card})` }}>
            <span className="ff-cp--bold">{discipline?.name}</span>
        </div>
        <div
          className='w-[238px] h-[96px] max-[480px]:w-[164px] max-[480px]:h-[70px] text-white bg-cover bg-center bg-no-repeat text-[14px]'
          style={{ backgroundImage: `url(${descriptionCard})` }}>
          <div className='w-full h-full py-2 px-5 max-[480px]:px-3.5 text-center max-[480px]:text-[11px] max-[480px]:leading-[13px] leading-4 flex justify-center items-center'>
            <span className="ff-m--regular">{discipline?.description}</span>
          </div>
        </div>
      </div>
    </div>
   </div>
  )
}

export default CardNoun

export const MESSAGES = {
  REGISTER: {
    SUCCESS: 'El usuario ha sido registrado correctamente',
    WRONG: {
      REPEAT: 'Este correo ya está registrado',
      PASSWORD_MATCH: 'Las contraseñas deben coincidir',
      FAILED: 'El usuario no ha sido registrado',
      EMPTY: 'Debe llenar todos los campos',
      EMAIL: {
        PATH: 'Debe ingresar un correo electrónico válido'
      },
      TERMS: 'Para continuar debe aceptar los términos y condiciones'
    }
  },
  RECOVERY: {
    PASSWORD: {
      WRONG: {
        EMPTY: 'Debe ingresar el correo',
        EXPIRED: 'Invalid token'
      }
    }
  }
}

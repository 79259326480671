import courseCard from '../../assets/images/cards/bg-card-tinder.svg';

interface CardProps {
  course?: any;
  loading?: boolean,
  children: any
}

const CourseCard = ({ course, children = <div></div> }: CardProps) => {
  return (
   <div className="w-fit h-[360px] xl:h-[450px] flex flex-col gap-y-3 items-center justify-center">
      <div className={'w-[223px] h-[149px] xl:w-[298px] xl:h-[199px] z-50 opacity-100 max-[480px]:h-[149.5px]'}
      style={{ transition: 'transform ease-in-out 400ms, translate ease-in-out 400ms, opacity ease-in-out 700ms' }}>
        <div className={'w-[223px] h-[149px] xl:w-[298px] xl:h-[199px] relative bg-cover bg-no-repeat bg-center place-content-center max-[480px]:h-[149.5px]'}>
          <div className="bg-cover w-full h-full relative grid place-content-center">
            <div className="w-[223px] h-[149px] xl:w-[298px] xl:h-[199px] bg-cover max-[480px]:h-[149.5px]" style={{ backgroundImage: `url(${courseCard})` }}></div>
            {/*  <div className="absolute right-[50px] top-[50px] text-[25px] leading-[20px] ff-cp--bold text-[#FFD300]">{index}/ {total}</div> */}
            <div className="absolute text-[14px] xl:text-[20px] leading-none text-[#F408B2] text-center top-[70px] left-[38px] right-[38px] ff-cp--bold break-words max-[480px]:top-0 max-[480px]:left-5 max-[480px]:right-5 max-[480px]:bottom-[15px] max-[480px]:pt-[45px] max-[480px]:text-[12px] max-[480px]:leading-[14px] max-[480px]:justify-center max-[480px]:items-center max-[480px]:flex">{course?.name}</div>
          </div>
        </div>
      </div>
      {children}
   </div>
  )
}

export default CourseCard

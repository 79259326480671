import { Navigate, Outlet, useParams } from 'react-router-dom'
import { getToken } from '../../common/methods/storage'

const ProtectedRoute = () => {
  const auth = getToken()
  const { workspace, group } = useParams()

  return auth
    ? (
    <Outlet />
      )
    : (
    <Navigate to={`/${workspace}/${group}/login`} />
      )
}

export default ProtectedRoute

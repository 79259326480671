export const PAGES = {
  LOGIN: '/login',
  LOADING_1: '/loading/a4606e52-9213-11ed-a1eb-0242ac120002',
  LOADING_2: '/loading/a460712c-9213-11ed-a1eb-0242ac120002',
  DECISION_1: '/decision/7dad568f-8cac-4cea-8dc2-3aaf90ebdd01',
  DECISION_2: '/decision/ef3e3485-e2c5-4e83-846f-5d36bfdbad60',
  DECISION_3: '/decision/1cb13cbb-6ad9-43dc-ae51-59f89e3b0b18',
  DECISION_4: '/decision/fb9a1152-e0ca-40c0-9030-bb04dec99b3f',
  LOADING_5: '/loading/z0656e78-9813-11fd-a4e0-0242ac120602',
  SPONSOR: '/sponsored',
  CONVERSATION_1: '/conversation/1fc14356-9938-4ce8-9124-c2f156d7aa28',
  CONVERSATION_2: '/conversation/5f12d57d-a5a7-4779-822f-cc13828c076a',
  CONVERSATION_3: '/conversation/7985e694-e61e-45a5-a659-3aa757d154d6',
  CONVERSATION_4: '/conversation/9c70d5ad-24ce-4ef0-9b5c-9bff6d5fdf9f',
  CONVERSATION_5: '/conversation/6e69729e-af31-408b-b7b5-8c8244365212',
  CONVERSATION_6: '/conversation/dadd773b-1846-4bd2-8f8f-7a19553e093f',
  QUESTION: '/question',
  INITIAL_INPUT: '/initial-input',
  GEOLOCALIZATION: '/geolocalization',
  PROFESSIONS: '/professions',
  TALENTS: '/talents',
  DISCIPLINES: '/disciplines/',
  DISCIPLINES_ITEMS: '/disciplines/items/',
  SELECTED_MOTIVATION: '/verbs/motivation',
  SELECTED_NEEDS: '/verbs/need',
  SELECTED_VALUES: '/verbs/values',
  SELECTED_INTERNAL_TOOLS: '/verbs/internal_tools',
  SELECTED_EXTERNAL_TOOLS: '/verbs/external_tools',
  SELECTED_PROFILE_VERBS: '/verbs/profile_verbs',
  TIERLIST_MOTIVATION: '/needs/motivation',
  TIERLIST_NEEDS: '/needs/need',
  TIERLIST_VALUES: '/needs/values',
  TIERLIST_INTERNAL_TOOLS: '/needs/internal_tools',
  TIERLIST_EXTERNAL_TOOLS: '/needs/external_tools',
  TIERLIST_PROFILE_VERBS: '/needs/profile_verbs',
  PROFILES: '/profiles',
  DASHBOARD: '/dashboard',
  CAREERS: '/dashboard/careers',
  MESHS: '/dashboard/mesh',
  CAREER: '/career/',
  GAME: '/game',
  DASHBOARD_HOME: '/dashboard',
  RESULT: {
    GENERAL: '/results',
    NOUNS: '/result-nouns',
    VOCATIONAL: '/result-vocational'
  },
  RECOVERY: {
    SEND_MESSAGE: '/recovery/send-message',
    EXPIRED: '/recovery/link-expired'
  },
  EXPERIENCE_DECISION: '/decision/experience',
  PROFILE_DETAIL: '/profile-detail'
}

export const PAGES_ROUTES = [
  {
    route: PAGES.CONVERSATION_1,
    step: 1
  },
  {
    route: PAGES.DECISION_1,
    step: 2,
    previous: PAGES.CONVERSATION_1,
    next: {
      SPONSORED: PAGES.SPONSOR,
      SIMPLE: PAGES.QUESTION
    }
  },
  {
    route: PAGES.QUESTION,
    step: 3
  },
  {
    route: PAGES.DECISION_2,
    step: 25,
    previous: PAGES.SELECTED_PROFILE_VERBS,
    next: {
      SIMPLE: PAGES.PROFILES
    }
  },
  {
    route: PAGES.DECISION_3,
    step: 26,
    previous: PAGES.PROFILE_DETAIL,
    next: {
      SIMPLE: '/loading/a460712c-9213-11ed-a1eb-0242ac120002'
    }
  },
  {
    route: PAGES.DECISION_4,
    step: 27,
    previous: PAGES.DASHBOARD,
    next: {
      SIMPLE: '/loading/a460712c-9213-11ed-a1eb-0242ac120002'
    }
  }
]

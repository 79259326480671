import { useEffect, useState } from 'react'

// Images
import buttonStart from '../../assets/images/btn-start-white.svg'
import buttonPurpleReverse from '../../assets/images/button-star-purple-reverse.svg'
import diamondDarkPurple from '../../assets/images/diamonds/diamong-dark-purple.svg'
import diamondLightPurple from '../../assets/images/diamonds/diamong-light-purple.svg'
import diamondPurple from '../../assets/images/diamonds/diamong-purple.svg'
import diamondWhite from '../../assets/images/diamonds/diamong-white.svg'
import { REACTIONS_DATA } from '../../constants/reactions'

const DragReaction = ({ callback, feature, currentReaction, useAlternativeNames = false }: { feature: any, currentReaction: string, callback: (data: any, reaction: string) => void, useAlternativeNames?: boolean }) => {
  const [rangeValue, setRangeValue] = useState< 0 | 1 | 1.5 | 2 | 3>(1.5)
  const [reaction, setReaction] = useState<any>('')

  const setValueInitialRange = () => {
    let value: 0 | 1 | 1.5 | 2 | 3 = 1.5

    if (currentReaction === 'LOVE') value = 3
    else if (currentReaction === 'LIKE') value = 2
    else if (currentReaction === 'N/A') value = 1
    else if (currentReaction === 'DISLIKE') value = 0

    setRangeValue(value)
  }

  const updateValueRange = () => {
    if (rangeValue !== 1.5) {
      const reaction_data = REACTIONS_DATA[rangeValue]

      setReaction(reaction_data)
      callback(feature, reaction_data.CODE)
    }
  }

  useEffect(() => {
    setValueInitialRange()
  }, [])

  useEffect(() => {
    updateValueRange()
  }, [rangeValue])

  return (
        <div className="flex flex-col items-center z-[450]">
            <div className="w-[165px] h-[45px] max-[480px]:w-[90px] max-[480px]:h-[25px] flex items-center justify-center pb-2 bg-cover bg-no-repeat bg-center max-[480px]:pb-1"
                style={{ backgroundImage: `url(${buttonPurpleReverse})` }}>
                <span className="ff-cp--bold text-white text-base max-[480px]:text-[9px] max-[480px]:leading-[16px]">{(useAlternativeNames ? reaction?.ALTERNATIVE_TITLE : reaction?.TITLE) ?? '---'}</span>
            </div>
            <div className="w-[200px] h-[59px] max-[480px]:w-[138px] max-[480px]:h-[37px] max-[480px]:gap-[1px] max-[480px]:pt-[10px] max-[480px]:pb-[5px] max-[480px]:px-[16px] bg-cover bg-center pt-[20px] pb-[12px] px-6 gap-[5px] flex flex-col justify-between max-[480px]:justify-center place-items-center drop-shadow-[0px_0px_10px_#ffffff]" style={{ backgroundImage: `url(${buttonStart})` }}>
                <div className="w-full flex justify-between place-items-center">
                    <div className="w-[6px] h-[6px] max-[480px]:w-[4px] max-[480px]:h-[4px] bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${diamondDarkPurple})` }}></div>
                    <div className="w-[6px] h-[6px] max-[480px]:w-[4px] max-[480px]:h-[4px] bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${diamondPurple})` }}></div>
                    <div className="w-[6px] h-[6px] max-[480px]:w-[4px] max-[480px]:h-[4px] bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${diamondLightPurple})` }}></div>
                    <div className="w-[6px] h-[6px] max-[480px]:w-[4px] max-[480px]:h-[4px] bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${diamondWhite})` }}></div>
                </div>
                <input value={rangeValue} type='range' min='0' max='3' step='0.5'
                        onChange={(e) => setRangeValue(parseInt(e.target.value) as 0 | 1 | 2 | 3)}
                        className="w-[calc(100%+8px)] max-[480px]:w-[calc(100%+4px)] bg-transparent overflow-hidden">
                </input>
            </div>
        </div>
  )
}

export default DragReaction

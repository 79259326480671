import { useFormik } from 'formik'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

// Services
import { forgotPassword } from '../../../services/apis/auth'

// Methods
import { getWorkspaceParams } from '../../../common/methods/params'

// Components
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import ButtonStar from '../../../components/shared/ButtonStar'
import ErrorMessage from '../../../components/shared/ErrorMessage'

// Models
import { MessageResult } from '../../../models/MessageResult'

// Constants
import { PAGES } from '../../../constants/Pages'
import { MESSAGES } from '../../../constants/response_message'

// Images
import burblePopup from '../../../assets/images/burble-popup.svg'
import iconEmail from '../../../assets/images/icons/icon-email.svg'
import ButtonReturn from '../../../components/shared/ButtonReturn'

const RecoveryResetPassword = () => {
  const { url, workspace, group } = getWorkspaceParams()

  const navigate = useNavigate()

  const [message, setMessage] = React.useState(new MessageResult(false, false, ''))

  const [emailForRecovery, setEmailForRecovery] = React.useState<string>('')
  const [showConfirmPopup, setShowConfirmPopup] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const validateSchema = Yup.object().shape({
    email: Yup.string().email(MESSAGES.REGISTER.WRONG.EMAIL.PATH).required(MESSAGES.REGISTER.WRONG.EMPTY)
  })

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validateSchema,
    initialValues: {
      email: '',
      role: 'STUDENT',
      domain: workspace,
      group
    },
    onSubmit: async (values) => {
      setIsLoading(true)
      await forgotPassword(values)
        .then(data => {
          if (data.status) {
            // navigate(`/${ url + PAGES.RECOVERY.SEND_MESSAGE }`, { state: { email: values.email, token: data.recovery_token } })
            setEmailForRecovery(values.email)
            setShowConfirmPopup(true)
          } else {
            setMessage({ isView: true, status: data.status, message: data.message })
          }
        })
      setIsLoading(false)
    },
    validate: values => {
      validateSchema.validate(values).catch(err => {
        const error: string = err.errors[0]
        setMessage({ isView: true, status: false, message: error })
      })
    }
  })

  const onResendEmail = async () => {
    await forgotPassword({ email: emailForRecovery, role: 'STUDENT', domain: workspace, group })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <article className="bg-main auth-page bg-auth recovery-page">
      <section className="grid grid-rows-1 w-screen h-screen px-8 max-[480px]:px-1">
        <ButtonReturn action="return" linkReturn={`/${url}/login`}></ButtonReturn>

        <div className="grid relative w-[1071px] items-center justify-self-center after:w-[534px] after:h-[700px] after:absolute after:bottom-0 after:left-0 after:right-0 after:mx-auto after:opacity-10 after:bg-gradient-to-t after:from-violet-600 after:to-transparent bg-login max-[480px]:w-full max-[480px]:after:w-full">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="flex flex-col h-full gap-5 place-content-center">
                <div className="grid gap-1">
                  <label className="ff-m--regular text-base text-white" htmlFor="email">Ingrese su correo electrónico</label>
                  <div className="relative">
                    <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[17px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconEmail})` }}></span>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="pl-[45px]"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      placeholder="name@example.com"
                    />
                  </div>
                </div>
                {message.isView && !message.status && (
                    <div className="h-[50px] mb-4 mt-4">
                      <ErrorMessage title={message.message}></ErrorMessage>
                    </div>
                )}
                <div className="flex justify-center">
                  <ButtonRedirect type="submit" text="Recuperar" borderColor='#A41AE8' showDesing next loading={isLoading}></ButtonRedirect>
                </div>
              </div>
            </div>
          </form>
        </div>

        {
          showConfirmPopup &&
          <div className="fixed flex items-center justify-center inset-0 bg-[#06010F]/50 z-[110]">
             <div className="flex flex-col justify-center items-center w-[680px] aspect-square pt-[60px] bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${burblePopup})` }}>
              <h3 className="ff-cp--bold text-center text-white text-[30px] font-bold leading-7 mb-4 max-[480px]:text-[18px]">Correo enviado</h3>
              <p className="ff-cp--light max-w-[490px] text-center text-white text-[20px] font-normal mb-[20px] max-[480px]:text-[14px] max-[480px]:w-[75%]">
                Enviamos un correo a {emailForRecovery}
              </p>
              <ButtonStar
                text='Regresar a Log in'
                type='purple'
                textSize='max-[480px]:text-[10px]'
                callback={() => navigate(`/${url + PAGES.LOGIN}`)}></ButtonStar>
              <div className="max-w-[210px] text-center">
                <button onClick={onResendEmail} className="text-center text-white text-[14px] font-bold underline underline-offset-4 opacity-70 hover:opacity-100 max-[480px]:text-[12px]">¿No recibiste el correo? Click aquí para reenviar.</button>
              </div>
            </div>
          </div>
        }
      </section>
    </article>
  )
}

export default RecoveryResetPassword

import bgButtonStar from '../../assets/images/btn-star.svg';
import bgButtonStartWhite from '../../assets/images/btn-start-white.svg';

interface ButtonProps {
    text: string;
    textSize?: string;
    type: 'white' | 'purple';
    size?: string;
    animation?: boolean;
    selected?: boolean;
    callback?: () => void
    icon?: string;
}

const ButtonStar = ({ text, type, callback, size = 'w-[200px] h-[54px] xl:w-[216px] xl:h-[60px]', animation = true, selected = false, textSize, icon }: ButtonProps) => {
  return (
        <button onClick={callback} className={`user-option ${size} max-[480px]:w-[130px] max-[480px]:h-[36px] max-[480px]:pt-[4px] max-[480px]:text-[12px] transition-all ease-in ff-cp--bold bg-cover justify-center items-center pt-2 px-4 mb-[20px] scale-110 max-[480px]:opacity-100 ${animation ? (selected ? 'scale-[1.3]' : `opacity-70 hover:opacity-100 hover:scale-[1.3] ${type === 'purple' ? 'hover:drop-shadow-[0px_0px_10px_#A41AE8]' : 'hover:drop-shadow-[0px_0px_10px_white]'}`) : ''} leading-none`}
        style={{ backgroundImage: `url(${(type === 'white' && !selected) ? bgButtonStartWhite : bgButtonStar})` }}>
            {
              icon &&
              <img src={icon} alt='icon' className="w-6 h-6" />
            }
            <p className={`text-white ${textSize ?? (animation ? 'text-[calc(12px+.15dvw)] leading-[calc(12px+.15dvw)]' : 'text-[calc(5px+.60dvw)] leading-[calc(5px+.72dvw)]')}`}>{text}</p>
        </button>
  )
}

export default ButtonStar

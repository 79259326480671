import logo from '../../assets/images/logos/logo.svg'

function PageTitle ({ title = { text: '', color: '#60DFFF' }, subtitle = { text: '', size: '' }, useExtra = false, extra = <div></div>, useLink = false, link = <div></div> }) {
  return (
    <div className="col-span-2 px-[35px] relative self-center">
      <img src={logo} alt="" className="absolute left-[195px] top-[-95px] w-[61px] h-[61px]" />
      <h2 className={`ff-cp--bold text-[70px] text-[${title.color}] justify-self-center mb-4 leading-none`}>{title.text}</h2>
      <p className={`ff-m--regular ${subtitle.size} text-white`}>{subtitle.text}</p>
      {useExtra && extra}
      {useLink && link}
    </div>
  )
}

export default PageTitle

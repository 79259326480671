import { API_ROUTES } from './../../constants/api_routes'
import { headers } from '../../constants/connection'
import { getRecord } from '../../common/methods/storage'

interface InstitutionProps {
    career_id: string,
    page?: number,
    size?: number,
    gestion?: string,
    level?: string,
    match?: boolean,
    name?: string,
    order?: boolean,
    signal?: AbortSignal
}

export const getAllByCareerId = async ({ career_id, page = 0, size = 25, gestion = 'ALL', level = 'ALL', match = false, name = '', signal, order = false }: InstitutionProps) => {
  const record = getRecord()
  if (record) {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.INSTITUTION.GET.ALL.BY.CAREER}${record.id}/${career_id}?page=${page}&size=${size}&gestion=${gestion}&level=${level}&match=${match}&name=${name}&order=${order}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      signal
    })
      .then((response) => response.json())
      .then(data => {
        return data
      })
      .catch(() => {
        return { instutions: [], count: 0 }
      })
  }
}

import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

// Images
import btnMeshYellowLight from '../../assets/images/buttons/btn-mesh-yellow-light.svg'
import btnMeshYellow from '../../assets/images/buttons/btn-mesh-yellow.svg'
import bgCardActive from '../../assets/images/cards/bg-card-active.svg'
import bgCardActiveMobile from '../../assets/images/cards/bg-card-institution-active-mobile.svg'
import bgCardMobile from '../../assets/images/cards/bg-card-institution-mobile.svg'
import bgCard from '../../assets/images/cards/bg-card.svg'
import bgHearth from '../../assets/images/cards/bg-hearth.svg'
import iconStarBig from '../../assets/images/icons/icon-star-big.svg'

// Methods
import { isMobile } from '../../common/methods/devices'
import { getWorkspaceParams } from '../../common/methods/params'
import { getUser } from '../../common/methods/storage'

interface CardProps {
    title: string,
    percent?: string,
    institution: any,
    hasMesh: boolean,
    onHovered?: boolean
}

const CardInstitution = ({ institution, title, percent, hasMesh, onHovered }: CardProps) => {
  const { url } = getWorkspaceParams()
  const navigate = useNavigate()
  const user = getUser()
  const currentInstitution = institution.id === user.workspace?.institution_id

  const [hovered, setHovered] = useState<boolean>(false)

  const bgCardActiveIns = isMobile() ? bgCardActiveMobile : bgCardActive
  const bgCardIns = isMobile() ? bgCardMobile : bgCard

  return (
        <button
            key={institution.id}
            className={`relative w-[208px] h-[249px] grid bg-no-repeat bg-cover max-[480px]:w-[163px] max-[480px]:h-[129px] ${currentInstitution ? 'cursor-pointer' : ''}`}
            style={{
              backgroundImage: `url(${hovered || currentInstitution ? bgCardActiveIns : bgCardIns})`
            }}
            onMouseEnter={() => {
              if (!isMobile()) setHovered(true)
            }}
            onMouseLeave={() => {
              if (!isMobile()) setHovered(false)
            }}
            onClick={() => {
              if (currentInstitution || (isMobile() && hasMesh)) {
                navigate(`/${url}/institution/${institution.mesh_id}`);
              }
            }}
        >
            { percent !== '??'
              ? (
              <div className={`absolute top-[20px] right-[23px] w-[37px] h-[33px] flex pt-[8px] justify-center bg-cover max-[480px]:top-2 max-[480px]:right-3 max-[480px]:w-[32px] max-[480px]:h-[28px] max-[480px]:pt-[5.5px] ${percent !== '??' ? 'opacity-100 brightness-[1.2]' : 'opacity-50'}`} style={{ backgroundImage: `url(${bgHearth})` }}>
                <div className="ff-cp--bold text-white text-[11px] max-[480px]:text-[9px]">{percent ? (<>{percent}{ percent !== '??' && <span className="text-[11px] max-[480px]:text-[9px]">%</span>}</>) : '??'}</div>
              </div>
                )
              : (
                  institution.id === user.workspace?.institution_id && (
                  <div className="absolute top-[19px] right-[23px] z-10 center w-[24px] h-[24px] max-[480px]:top-2 max-[480px]:right-3">
                    <img src={iconStarBig} className={'favorite-step w-[27px] h-[27px] opacity-100'} alt=""/>
                  </div>
                  )
                )}

            <div className="p-[21px] max-[480px]:py-2.5 max-[480px]:px-3.5">
                <div className="mb-[8px]">
                    <span className={`block w-[81px] h-[14px] pt-[1px] ff-cp--bold px-[8px] mb-[6px] text-white text-center text-[8.5px] max-[480px]:text-[7px] max-[480px]:h-[12px] ${institution?.type === 'UNIVERSITY' ? 'bg-[#A533FF]' : 'bg-[#008AC5]'} rounded-xl`}>
                        {institution?.type === 'UNIVERSITY' ? 'Universidad' : 'Instituto'}
                    </span>
                    <span className={`block w-[81px] h-[14px] pt-[1px] ff-cp--bold px-[8px] ${institution?.gestion === 'PRIVATE' ? 'bg-[#7D3BF3]' : 'bg-[#006196]'} text-white text-center text-[8.5px] rounded-xl max-[480px]:text-[7px] max-[480px]:h-[12px]`}>{institution?.gestion === 'PRIVATE' ? 'Privado' : 'Público'}</span>
                </div>
                <p data-te-toggle="tooltip" title={title} className="ff-cp--bold pr-[12px] text-[14px] text-white leading-none overflow-hidden max-[480px]:text-[12px] max-[480px]:text-left max-[480px]:pr-0" style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 4 }}>{title}</p>
            </div>
            {
              hasMesh && !isMobile() && (
                <div className="flex justify-center pb-[20px] px-[15px] items-end">

                    { currentInstitution
                      ? (
                        <div className="w-full flex items-center justify-center gap-x-1 px-[7px] pt-[10px] border-t border-t-[#42324E]">
                        {
                            !!user.workspace?.logo &&
                            <div className="min-w-[45px] h-[45px] flex items-center justify-center">
                                <img className="w-[36px] h-[36px]" src={user.workspace.logo} alt="" />
                            </div>
                        }
                        <span className="text-[9px] text-white leading-[10px]">{user.workspace.title}</span>
                        </div>
                        )
                      : (
                        <Link
                            to={`/${url}/institution/${institution.mesh_id}`}
                            className={`${onHovered ? 'w-[180px] h-[70px] relative bottom-[-10px]' : 'w-[162px] h-[39px]'} p-[6px] flex justify-center items-center text-[11px] text-center text-white bg-cover bg-center opacity-80 hover:opacity-100`}
                            style={{ backgroundImage: `url(${onHovered ? btnMeshYellowLight : btnMeshYellow})` }}
                        >
                        Ver malla
                        </Link>
                        )}
                </div>
              )}
        </button>
  )
}

export default CardInstitution

import arrowDown from '../../assets/images/icons/icon-chevron-down.svg'
import arrowLeft from '../../assets/images/icons/icon-chevron-left.svg'
import arrowRight from '../../assets/images/icons/icon-chevron-right.svg'
import arrowUp from '../../assets/images/icons/icon-chevron-up.svg'

export const ArrowUp = (props: any) => {
  const { onClick } = props
  return (
        <button type='button' onClick={onClick} className='slick-prev before:!hidden pull-left'><img src={arrowUp} className="pointer-events-none" alt="" /></button>
  )
}

export const ArrowDown = (props: any) => {
  const { onClick } = props
  return (
        <button type='button' onClick={onClick} className='slick-next before:!hidden pull-right'><img src={arrowDown} className="pointer-events-none" alt="" /></button>
  )
}

export const ArrowLeft = (props: any) => {
  const { onClick, arrow, width, height, currentSlide } = props
  const isVisible = currentSlide !== 0
  return (
        <button type='button' onClick={onClick} className={`slick-prev z-[450!important] before:!hidden ${isVisible ? '' : 'hidden'} pull-left ${width ?? ''} ${height ?? ''}`}><img src={arrow ?? arrowLeft} className="pointer-events-none" alt="" /></button>
  )
}

export const ArrowRight = (props: any) => {
  const { onClick, arrow, width, height, currentSlide, count, canShow = true } = props
  const isVisible = canShow && currentSlide < count - 1
  return (
        <button type='button' onClick={onClick} className={`slick-next before:!hidden ${isVisible ? '' : 'hidden'} pull-right ${width ?? ''} ${height ?? ''}`}><img src={arrow ?? arrowRight} className="pointer-events-none" alt="" /></button>
  )
}

import { useState } from 'react'
// Images
import btnSmall from '../../assets/images/buttons/btn-small.svg'
import bgCardActiveMobile from '../../assets/images/cards/bg-card-active-mobile.svg'
import bgCareerActive from '../../assets/images/cards/bg-card-active.svg'
import bgCareer from '../../assets/images/cards/bg-card-mesh.svg'
import bgCardMobile from '../../assets/images/cards/bg-card-mobile.svg'
import bgHeart from '../../assets/images/cards/bg-hearth.svg'

import { isMobile } from '../../common/methods/devices'

interface CardProps {
    mesh: any,
    size?: string,
    callback?: () => void
}

const CardMesh = ({ mesh, size, callback }: CardProps) => {
  const [hovered, setHovered] = useState<boolean>(false)

  const bgCardActiveMesh = isMobile() ? bgCardActiveMobile : bgCareerActive
  const bgCardMesh = isMobile() ? bgCardMobile : bgCareer

  return (
        <button
            key={mesh.id}
            onMouseEnter={() => {
              if (!isMobile()) setHovered(true)
            }}
            onMouseLeave={() => {
              if (!isMobile()) setHovered(false)
            }}
            onClick={callback} className={'relative grid grid-rows-2 w-[208px] h-[249px] transition-all ease-in-out duration-300 bg-cover cursor-pointer max-[480px]:w-[160px] max-[480px]:h-[129px] max-[480px]:grid-rows-[76px_53px]'} style={{ backgroundImage: `url(${hovered ? bgCardActiveMesh : bgCardMesh})` }}>
            <div className="row-1 pt-[19px] px-[23px] max-[480px]:pl-4 max-[480px]:pr-2.5 max-[480px]:pt-3">
                <div className={'absolute w-[37px] h-[34px] right-[23px] top-[20px] flex items-center justify-center bg-cover max-[480px]:w-[32px] max-[480px]:h-[28px] max-[480px]:pt-0 max-[480px]:top-2.5 max-[480px]:right-2 max-[480px]:pb-[2px]'} style={{ backgroundImage: `url(${bgHeart})` }}>
                    <span className={'ff-cp--bold text-[11px] text-white max-[480px]:text-[9px]'}>{mesh.percentage ? (<>{mesh.percentage}<span className={'text-[7px] max-[480px]:text-[9px]'}>%</span></>) : '??'}</span>
                </div>
                <div className="mb-[8px] max-[480px]:mb-1.5">
                    <span className={`block w-[81px] h-[14px] pt-[1px] ff-cp--bold px-[8px] mb-[6px] text-white text-center text-[8.5px] ${mesh.institution?.type === 'UNIVERSITY' ? 'bg-[#A533FF]' : 'bg-[#008AC5]'} rounded-xl mx-[480px]:text-[6px] max-[480px]:h-[10px] max-[480px]:leading-[11px] max-[480px]:mb-[4px]`}>
                        {mesh.institution?.type === 'UNIVERSITY' ? 'Universidad' : 'Instituto'}
                    </span>
                    <span className={`block w-[81px] h-[14px] pt-[1px] ff-cp--bold px-[8px] ${mesh.institution?.gestion === 'PRIVATE' ? 'bg-[#7D3BF3]' : 'bg-[#006196]'} text-white text-center text-[8.5px] rounded-xl mx-[480px]:text-[6px] max-[480px]:h-[10px] max-[480px]:leading-[8px]`}>{mesh.institution?.gestion === 'PRIVATE' ? 'Privado' : 'Público'}</span>
                </div>
                <h3 className={'ff-cp--bold h-[55px] text-[14px] text-white mb-1 leading-none overflow-hidden text-clip whitespace-pre-wrap break-words max-[480px]:text-[12px] max-[480px]:leading-[12px] max-[480px]:text-left max-[480px]:h-[30px]'}>{mesh.career}</h3>

            </div>
            <div className={'row-1 px-[23px] py-[10px] grid min-[481px]:grid-rows-2 max-[480px]:px-4 max-[480px]:pt-2 max-[480px]:pb-3 max-[480px]:h-full'}>
                <div className='max-w-[150px] m-auto row-1 max-[480px]:max-w-full max-[480px]:w-full max-[480px]:h-full max-[480px]:m-0 max-[480px]:content-center'>
                    <p className="text-[10px] text-white mb-1 leading-none max-[480px]:mb-0">{mesh.institution.name}</p>
                </div>
               {
                !isMobile() &&
                <div className="row-1">
                    <button className="instruction-step w-[162px] h-[39px] text-white text-[10px] text-center leading-[34px] bg-cover bg-center bg-no-repeat opacity-70 hover:opacity-100" style={{ backgroundImage: `url(${btnSmall})` }}>Volver a revisar malla</button>
                </div>
               }
            </div>
        </button>
  )
}

export default CardMesh

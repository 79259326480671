import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LOGOUT } from '../../actions/type'
import iconHero from '../../assets/images/icon-hero.svg'
import { getWorkspaceParams } from '../../common/methods/params'
import ButtonRedirect from '../../components/shared/ButtonRedirect'
import { cleanDataStorage } from '../../helpers/auth'

function Home () {
  const { url } = getWorkspaceParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  cleanDataStorage()
  dispatch({ type: LOGOUT })

  return (
    <section id="home">
      <div className="w-screen h-screen flex items-center justify-center overflow-hidden bg-cover bg-auth">
        <div className="w-full h-full flex items-center justify-center after:w-[554px] after:h-[700px] after:absolute after:bottom-0 after:left-0 after:right-0 after:mx-auto after:opacity-10 after:bg-gradient-to-t after:from-violet-600 after:to-transparent after:max-w-[100%] z-10">
          <div className="relative bg-intro h-full aspect-[1.34] inset-0 m-auto flex items-end justify-center">
            <div className="w-full h-[60%] max-[480px]:h-[100%] max-[480px]:justify-evenly flex flex-col items-center justify-between z-50 pt-4 max-[480px]:pt-14 fhd:pt-[100px] min-[481px]:mb-[10%]">
              <h2 className='ff-cp--bold text-white text-[32px] max-[480px]:text-[15px] xl:text-[48px] justify-self-center fhd:text-[54px]'>Construye tu destino</h2>
              <img src={iconHero} className='w-[40px] h-[32px] max-[480px]:w-[30px] max-[480px]:h-[22px] xl:w-[67px] xl:h-[54px]' alt="" />
              <div className='relative h-[102px] max-[480px]:h-[50px] xl:h-[150px] fhd:h-[190px] flex flex-col gap-y-4 items-center'>
                <ButtonRedirect callback={() => navigate(`/${url}/register`)} type='button' text='Regístrate' borderColor='#A41AE8' showDesing></ButtonRedirect>
                <ButtonRedirect callback={() => navigate(`/${url}/login`)} type='button' text='Ingresar' borderColor='#A41AE8'></ButtonRedirect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Home

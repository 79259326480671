import { Provider } from 'react-redux'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import ProtectedRoute from '../components/auth/protect-route'
import Layout from '../layout/layout'
import Login from '../pages/auth/Login'
import Register from '../pages/auth/Register'
import RecoveryChangePassword from '../pages/auth/recovery/RecoveryChangePassword'
import RecoveryChangeSuccess from '../pages/auth/recovery/RecoveryChangeSuccess'
import RecoveryLinkExpired from '../pages/auth/recovery/RecoveryLinkExpired'
import RecoveryResetPassword from '../pages/auth/recovery/RecoveryResetPassword'
import RecoverySendEmail from '../pages/auth/recovery/RecoverySendEmail'
import Career from '../pages/web/Career'
import DashboardCareer from '../pages/web/Dashboard/Career'
import ExperienceDecision from '../pages/web/Dashboard/ExperienceDecision'
import Dashboard from '../pages/web/Dashboard/Home'
import DashboardMesh from '../pages/web/Dashboard/Mesh'
import ResultsNouns from '../pages/web/Dashboard/ResultNouns'
import ResultsVerbs from '../pages/web/Dashboard/ResultVerbs'
import Results from '../pages/web/Dashboard/Results'
import Game from '../pages/web/Game'
import Home from '../pages/web/Home'
import Institution from '../pages/web/Institution'
import Loading from '../pages/web/Loading'
import Conversation from '../pages/web/Quiz/Conversation'
import Decision from '../pages/web/Quiz/Decision'
import Disciplines from '../pages/web/Quiz/Disciplines'
import DisciplinesItems from '../pages/web/Quiz/DisciplinesItems'
import InitialInput from '../pages/web/Quiz/InitialInput'
import Interests from '../pages/web/Quiz/Interests'
import Needs from '../pages/web/Quiz/Needs'
import Professions from '../pages/web/Quiz/Professions'
import ProfileDetail from '../pages/web/Quiz/ProfileDetail'
import Profiles from '../pages/web/Quiz/Profiles'
import Question from '../pages/web/Quiz/Question'
import Sponsored from '../pages/web/Quiz/Sponsored'
import Talents from '../pages/web/Quiz/Talents'
import Verbs from '../pages/web/Quiz/Verbs'
import Result from '../pages/web/Result'
import Tinder from '../pages/web/Tinder'
import store from '../services/auth'

const RedirectOld = () => {
  const { workspace } = useParams()
  const newPath = workspace?.replace('_', '/')
  return <Navigate to={`/${newPath}`} />
}

const App = () => {
  return (
    <Provider store={store}>
      <Layout>
        <Routes>
          <Route path="/"
            element={<Navigate to="/movematch/group" replace />}>
          </Route>
          <Route path="/:workspace" element={<RedirectOld />} />
          <Route path="/:workspace/:group/" element={<Home />} />
          <Route path="/:workspace/:group/login" element={<Login />} />
          <Route path="/:workspace/:group/register" element={<Register />} />
          <Route path="/:workspace/:group/recovery/reset-password" element={<RecoveryResetPassword />} />
          <Route path="/:workspace/:group/recovery/send-message" element={<RecoverySendEmail />} />
          <Route path="/:workspace/:group/recovery/change-password/:token" element={<RecoveryChangePassword />} />
          <Route path="/:workspace/:group/recovery/link-expired" element={<RecoveryLinkExpired />} />
          <Route path="/:workspace/:group/recovery/change-success" element={<RecoveryChangeSuccess />} />
          <Route path='/' element={<ProtectedRoute />}>
            <Route path="/:workspace/:group/loading/:token" element={<Loading />} />
            <Route path="/:workspace/:group/decision/:token" element={<Decision />} />
            <Route path="/:workspace/:group/game" element={<Game />} />
            <Route path="/:workspace/:group/initial-input" element={<InitialInput />} />
            <Route path="/:workspace/:group/question" element={<Question />} />
            <Route path="/:workspace/:group/conversation/:token" element={<Conversation />} />
            <Route path="/:workspace/:group/talents" element={<Talents />} />
            <Route path="/:workspace/:group/interests" element={<Interests />} />
            <Route path="/:workspace/:group/institution/:token" element={<Institution />} />
            <Route path="/:workspace/:group/professions" element={<Professions />} />
            <Route path="/:workspace/:group/courses/:token" element={<Tinder />} />
            <Route path="/:workspace/:group/result/:token" element={<Result />} />
            <Route path="/:workspace/:group/needs/:token" element={<Needs />} />
            <Route path="/:workspace/:group/disciplines/:id" element={<Disciplines />} />
            <Route path="/:workspace/:group/disciplines/items/:token" element={<DisciplinesItems />} />
            <Route path="/:workspace/:group/disciplines" element={<Disciplines />} />
            <Route path="/:workspace/:group/profiles" element={<Profiles />} />
            <Route path="/:workspace/:group/career/:token" element={<Career />} />
            <Route path="/:workspace/:group/dashboard" element={<Dashboard />} />
            <Route path="/:workspace/:group/dashboard/careers" element={<DashboardCareer />} />
            <Route path="/:workspace/:group/dashboard/mesh" element={<DashboardMesh />} />
            <Route path="/:workspace/:group/verbs/:token" element={<Verbs/>} />
            <Route path="/:workspace/:group/profile-detail" element={<ProfileDetail />} />
            <Route path="/:workspace/:group/results" element={<Results />} />
            <Route path="/:workspace/:group/result-nouns" element={<ResultsNouns />} />
            <Route path="/:workspace/:group/result-vocational" element={<ResultsVerbs />} />
            <Route path="/:workspace/:group/sponsored" element={<Sponsored />} />
            <Route path="/:workspace/:group/decision/experience" element={<ExperienceDecision />} />
          </Route>
        </Routes>
      </Layout>
    </Provider>
  )
}

export default App

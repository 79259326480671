import { useEffect, useRef, useState } from 'react'
import Joyride from 'react-joyride'
import { Link, useParams } from 'react-router-dom'

// Services
import { getCareerByIdWithClassification } from '../../services/apis/career'
import { getAllByCareerId } from '../../services/apis/institution'

// Methods
import { getWorkspaceParams } from '../../common/methods/params'
import { getDemoMeshStatus, getRecord, getUser } from '../../common/methods/storage'

// Components
import CardCareerDetail from '../../components/cards/CardCareerDetail'
import CardInstitution from '../../components/cards/CardInstitution'

// Constants
import { ADMINISTRATION, EDUCATION_LEVEL } from '../../constants/Administration'
import { MESH } from '../../constants/tutorial'

// Images
import avatarDefault from '../../assets/images/avatar-default.svg'
import iconBackWhite from '../../assets/images/icons/icon-back-white.svg'
import iconDoubleArrow from '../../assets/images/icons/icon-double-arrow.svg'
import iconSearch from '../../assets/images/icons/icon-search.svg'
import logoCareers from '../../assets/images/logos/logo-careers.svg'
import { isMobile } from '../../common/methods/devices'
import IntroTooltip from '../../components/intro/IntroTooltip'
import DashboardMenu from '../../components/shared/DarshboardMenu'

const Career = () => {
  const { token } = useParams()
  const { url } = getWorkspaceParams()

  const user = getUser()

  const tutorialStatus = getDemoMeshStatus()

  const listInnerRef = useRef<any>()

  const abortControllerRef = useRef<AbortController>(new AbortController())
  const gestionRef = useRef<any>(null)
  const levelRef = useRef<any>(null)

  const [career, setCareer] = useState<any>(null)
  const [administrationOptions, setAdministrationOptions] = useState<any>(ADMINISTRATION[0])
  const [levelOptions, setLevelOptions] = useState<any>(EDUCATION_LEVEL[0])

  const [currPage, setCurrPage] = useState<number>(0)
  const [prevPage, setPrevPage] = useState<number>(-1)
  const [lastList, setLastList] = useState<boolean>(false)
  const [institutions, setInstitutions] = useState<any>([])

  const [showLevelPopup, setShowLevelPopup] = useState<boolean>(false)
  const [showAdministrationPopup, setShowAdministrationPopup] = useState<boolean>(false)

  const [chargeInstitutions, setChargeInstitutions] = useState<boolean>(false)

  const [run, setRunTutorial] = useState<boolean>(false)
  const [stepList, setStepList] = useState<any>([])
  const [step, setStepTutorial] = useState<number>(0)

  const [search, setSearch] = useState<string>('')
  const [loadingTutorial, setLoadingTutorial] = useState<boolean>(true)

  useEffect(() => {
    if (!lastList && prevPage !== currPage && chargeInstitutions) {
      updateData(institutions)
    }
  }, [chargeInstitutions])

  useEffect(() => { getCareer() }, [token])

  useEffect(() => {
    if (tutorialStatus) {
      setStepList([...MESH])
      setRunTutorial(true)
      setLoadingTutorial(false)
    }
  }, [])

  useEffect(() => {
    setInstitutions([])
    setLastList(false)
    setPrevPage(-1)
    setCurrPage(0)
    setChargeInstitutions(true)
  }, [administrationOptions, levelOptions, search])

  const getCareer = async () => {
    if (token) {
      const record: any = getRecord()
      const data = await getCareerByIdWithClassification(token, record?.id)
      setCareer(data.data)
    }
  }

  const updateData = async (institution_list: any[]) => {
    if (token) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
      abortControllerRef.current = new AbortController()
      const signal = abortControllerRef.current.signal

      await getAllByCareerId(
        {
          career_id: token,
          page: currPage,
          size: 20,
          gestion: administrationOptions.code,
          level: levelOptions.code,
          match: false,
          name: search,
          signal,
          order: true
        })
        .then(data => {
          if (!data.institutions.length) {
            setLastList(true)
          } else {
            setPrevPage(currPage - 1)
          }
          setInstitutions([...(currPage === 0 ? [] : institution_list), ...data.institutions])
          setChargeInstitutions(false)
        })
    }
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight >= (scrollHeight - 1) && !chargeInstitutions) {
        setCurrPage(currPage + 1)
        setChargeInstitutions(true)
      }
    }
  }

  const handleSearch = (event: any) => {
    setInstitutions([])
    setSearch(event.target.value)
  }

  const handleCallback = (data: any) => {
    if (!run) return

    const { action, index, type } = data

    if (action === 'next' && index === step && type === 'step:after') setStepTutorial(step + 1)
    if (action === 'prev' && index === step && type === 'step:after') setStepTutorial(step - 1)
    document.querySelectorAll('.joyride-element-show').forEach(function (elemento) {
      elemento?.classList.remove('joyride-element-show')
    })

    if (data.step?.target !== 'body' && !!data.step?.target) {
      const targetElements = document.querySelectorAll(data.step?.target)

      if (typeof data.step.index === 'number' && data.step.index >= 0 && data.step.index < targetElements.length) {
        targetElements.forEach((element, index) => {
          if (index === data.step.index) {
            element.classList.add('joyride-element-show')
          } else {
            element.classList.remove('joyride-element-show')
          }
        })
      } else {
        targetElements.forEach(element => {
          element.classList.add('joyride-element-show')
        })
      }
    }

    if (type === 'tour:end') {
      setLoadingTutorial(true)
      setStepTutorial(0)
      setRunTutorial(false)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (levelRef.current?.contains(event.target)) setShowLevelPopup(true)
      else setShowLevelPopup(false)
    }

    window.addEventListener('click', handleClickOutside)

    return () => window.removeEventListener('click', handleClickOutside)
  }, [levelRef])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (gestionRef.current?.contains(event.target)) setShowAdministrationPopup(true)
      else setShowAdministrationPopup(false)
    }

    window.addEventListener('click', handleClickOutside)

    return () => window.removeEventListener('click', handleClickOutside)
  }, [gestionRef])

  return (

    <article id="career" className='bg-main-alt'>
      {
        !loadingTutorial && (
          <Joyride
            callback={handleCallback}
            steps={stepList}
            continuous
            hideCloseButton
            disableScrolling
            disableCloseOnEsc
            disableOverlayClose
            showSkipButton={false}
            stepIndex={step}
            run={run}
            tooltipComponent={IntroTooltip}
            styles={{
              options: {
                arrowColor: '#60DFFF',
                overlayColor: 'rgba(0, 0, 12, 0.9)'
              }
            }}
          />
        )}
      <section className="w-screen h-screen quiz-screen-background flex flex-col pt-6 px-6 max-[480px]:pt-0 max-[480px]:px-0">
        <DashboardMenu currentPage={0} logoPosition='TOP' />
        <div className="w-full h-[50px] flex justify-between max-[480px]:h-auto max-[480px]:mb-[10px] max-[480px]:px-3.5">
          <div className="w-full flex items-center justify-between max-[480px]:flex-col max-[480px]:gap-y-4 max-[480px]:w-full">
            <div className="flex items-center max-[480px]:w-full">
              <Link
                to={`/${url}/dashboard/careers`}
                className="card-wrap w-[250px] h-[50px] dashboard-gradient bg-cover flex items-center justify-start gap-x-4 px-4 border-2 border-t-[#ffffff86] border-l-[#ffffff86] border-r-[#ffffff86] border-b-0 rounded-tl-[30px] rounded-tr-[8px] transition-all ease-in-out duration-200 opacity-50 hover:opacity-100 max-[480px]:w-[50px] max-[480px]:h-[60px] max-[480px]:border-none max-[480px]:p-0 max-[480px]:self-start max-[480px]:pt-[5px] max-[480px]:opacity-100"
                style={{ background: isMobile() ? 'none' : 'linear-gradient(180deg, rgba(164, 26, 232, 0.1) -22%, rgba(164, 26, 232, 0.00) 1240%)' }}>
                <div className="w-[30px] h-[30px] rounded-full border border-[#ffffff7a] flex items-center justify-center hover:scale-105 hover:opacity-100 max-[480px]:w-[32px] max-[480px]:h-[32px]">
                  <img src={iconBackWhite} alt="back" width="12" height="12" />
                </div>
                {
                  !isMobile() &&
                  <div className="flex flex-col items-start justify-center">
                    <p className="ff-cp--bold text-white text-[16px] leading-none">Carreras Candidatas</p>
                  </div>
                }
              </Link>
              <div className="flex gap-x-3 items-center max-[480px]:flex-col max-[480px]:w-full max-[480px]:pr-[45px] max-[480px]:pt-[15px] max-[480px]:gap-y-[5px]">
                <div className={`tab-institution mt-[4px] z-[100] w-[310px] h-[50px] dashboard-gradient bg-cover flex items-center justify-start gap-x-4 px-4 border-2 border-t-[#fff] border-l-[#fff] border-r-[#fff] border-b-[#320a4e] rounded-tl-[30px] rounded-tr-[8px] max-[480px]:mt-0 max-[480px]:h-auto max-[480px]:border-none max-[480px]:w-full max-[480px]:justify-center max-[480px]:bg-none ${isMobile() ? '' : 'dashboard-gradient'}`}>
                  {
                    !isMobile() &&
                    <div className="w-[30px] h-[30px] rounded-full border border-[#ffffff7a] flex items-center justify-center hover:scale-105 hover:opacity-100">
                      <img src={logoCareers} alt="logo carrers" width="12" height="12" />
                    </div>
                  }
                  <p className="text-white ff-cp--bold text-[20px] leading-none">Instituciones</p>
                </div>
                <div className="relative flex items-center w-[200px] xl:w-[367px] top-[2px] max-[480px]:w-full">
                  <input type="search" className="w-full z-10 text-white pl-[18px] text-[10px] h-[36px] bg-[#070625] rounded-full border border-solir border-white " placeholder="Escribe una carrera en mente..." onChange={handleSearch} />
                  <button className="w-[26px] h-[26px] absolute top-[5px] right-[5px] z-10 bottom-[5px] bg-cover transition ease opacity-70 hover:opacity-100" style={{ backgroundImage: `url(${iconSearch})` }}></button>
                </div>
              </div>
            </div>
            {
              !isMobile() &&
              <div className="flex items-center">
                <div className="w-[36px] h-[36px] rounded-full border-2 border-white overflow-hidden mr-2">
                  <img src={avatarDefault} alt="" className="w-[36px] h-[36px]" />
                </div>
                <p className="ff-cp--bold text-[14px] xl:text-[20px] text-white whitespace-nowrap">
                  {
                    user &&
                    (user.name + ' ' + user.last_name)
                  }
                </p>
              </div>
            }
          </div>
        </div>
        <div className={`w-full ${isMobile() ? 'h-[calc(100%-95px)]' : 'h-[calc(100%-110px)] dashboard-gradient'} relative gap-x-4 overflow-hidden border-2 border-white/[.8] rounded-b-xl rounded-tl-none rounded-tr-[50px] max-[480px]:border-0 max-[480px]:rounded-none`}>
          <div className="flex h-full relative careers-step max-[480px]:flex-col">
            <div className="h-full sidebar-border max-[480px]:h-[70px]">
              <CardCareerDetail currentCareer={career} />
            </div>
            <div className="card-wrap w-full h-full pr-[10px] pt-6 rounded-tr-[20px] rounded-br-[20px] flex justify-items-center items-start max-[480px]:pt-7 max-[480px]:pr-0 max-[480px]:relative max-[480px]:before:absolute max-[480px]:before:top-2.5 max-[480px]:before:right-4 max-[480px]:before:left-4 max-[480px]:before:h-[2px] max-[480px]:before:bg-white max-[480px]:pb-[75px]">
              <div
                ref={listInnerRef}
                onScroll={onScroll}
                className="w-full max-h-full grid grid-cols-[repeat(auto-fit,208px)] gap-y-[15px] pb-5 overflow-auto overflow-x-hidden simple-scroll max-[480px]:gap-x-1.5 max-[480px]:gap-y-2.5 max-[480px]:grid-cols-[repeat(auto-fit,160px)] max-[480px]:justify-center max-[480px]:m-0 max-[480px]:px-2">
                {
                  institutions.map((item: any) => {
                    return (
                      <CardInstitution
                        key={item.id}
                        institution={item}
                        title={item.name}
                        percent={item.percentage}
                        onHovered={tutorialStatus}
                        hasMesh={item.has_mesh}
                      />
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
       {
        !isMobile() &&
        <div className="w-full flex-1 min-h-[50px] flex items-center gap-x-8">
          <div className="w-1/2 h-full flex items-center justify-start gap-x-10 px-[45px] xl:px-28"></div>
          <div className="w-1/2 h-full flex items-center justify-end gap-x-10 pl-[80px] xl:px-28">
            <div className="flex gap-x-1 xl:gap-x-3 relative">
              <div className="w-[81px] h-[32px]">
                <p className="ff-m--regular text-[14px] leading-none text-white">Nivel educativo</p>
              </div>
              <button
                ref={levelRef}
                onClick={() => setShowLevelPopup(!showLevelPopup)}
                className="w-[96px] h-[32px] bg-[#070625] border border-white cursor-pointer flex items-center justify-between rounded-3xl px-2.5">
                <p className="col-span-8 ff-m--bold text-[10px] leading-none text-white">{levelOptions.name}</p>
                <img src={iconDoubleArrow} alt="double-arrow-icon" width="10" height="10" />
              </button>
              {
                showLevelPopup &&
                <div className="absolute bottom-9 right-0 z-10 bg-[#f9f9f9] w-[100px] h-fit rounded-lg py-2">
                  {
                    EDUCATION_LEVEL.map(level => {
                      return (
                        <button
                          key={level.id}
                          className="h-[25px] px-2 flex items-center cursor-pointer hover:bg-[#e7e7e7]"
                          onClick={() => {
                            setLevelOptions(level)
                            setShowLevelPopup(false)
                          }}
                        >
                          <p className="text-[#070625] text-[12px] leading-none">{level.name}</p>
                        </button>
                      )
                    })
                  }
                </div>
              }

            </div>
            <div className="flex gap-x-2 relative">
              <div className="w-[81px] h-[32px]">
                <p className="ff-m--regular text-[14px] leading-none text-white">Tipo de gestión</p>
              </div>
              <button
                ref={gestionRef}
                onClick={() => setShowAdministrationPopup(!showAdministrationPopup)}
                className="w-[96px] h-[32px] bg-[#070625] border border-white cursor-pointer flex items-center justify-between rounded-3xl px-2.5">
                <p className="col-span-8 ff-m--bold text-[10px] leading-none text-white">{administrationOptions.name}</p>
                <img src={iconDoubleArrow} alt="double-arrow" width="10" height="10" />
              </button>
              {
                showAdministrationPopup &&
                <div className="absolute bottom-9 right-0 z-10 bg-[#f9f9f9] w-[100px] h-fit rounded-lg py-2">
                  {
                    ADMINISTRATION.map(administration => {
                      return (
                        <button
                          key={administration.id}
                          className="h-[25px] px-2 flex items-center cursor-pointer hover:bg-[#e7e7e7]"
                          onClick={() => {
                            setAdministrationOptions(administration)
                            setShowAdministrationPopup(false)
                          }}
                        >
                          <p className="text-[#070625] text-[12px] leading-none">{administration.name}</p>
                        </button>
                      )
                    })
                  }
                </div>
              }
            </div>
          </div>
        </div>
       }
      </section>
    </article>
  )
}

export default Career

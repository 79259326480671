import { API_ROUTES } from '../../constants/api_routes'
import { headers } from '../../constants/connection'

import { getRecord, setFeatures, setRecord } from '../../common/methods/storage'

interface ProfileProps {
    id?: string,
    feature?: any,
    feature_id: string,
    record_id?: string,
    selected: boolean | null,
    reaction: 'LIKE' | 'DISLIKE' | 'N/A' | 'LOVE' | null,
    weight: number | null,
}

export const createProfilesByList = async (profileList: ProfileProps[]) => {
  const record = getRecord()
  if (record) {
    const data = {
      profile_list: profileList.map((profile: ProfileProps) => ({
        feature_id: profile.feature_id,
        selected: profile.selected,
        reaction: profile.reaction,
        weight: profile.weight
      })),
      record_id: record.id
    }

    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.PROFILE.CREATE.BY.LIST}`, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then(data => {
        setFeatures(profileList)
        return data
      })
      .catch((error: any) => {
        console.log({ error })
        return false
      })
  }
}

export const updateProfilesByList = async (profileList: ProfileProps[]) => {
  const record = getRecord()

  if (record) {
    const data = {
      profile_list: profileList,
      record_id: record.id
    }

    for (const profile of profileList) {
      record.features.forEach((feature: any) => {
        if (feature.id === profile.feature_id) {
          feature.profiles.selected = profile.selected
          feature.profiles.reaction = profile.reaction
          feature.profiles.weight = profile.weight
        }
      })
    }

    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.PROFILE.CREATE.BY.LIST}`, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then(data => {
        if (data.status) {
          setRecord(record)
          return data
        }
      })
      .catch(() => {
        return false
      })
  }
}

export const ADMINISTRATION = [
  {
    id: 1,
    code: 'ALL',
    name: 'Todas'
  },
  {
    id: 2,
    code: 'PUBLIC',
    name: 'Público'
  },
  {
    id: 3,
    code: 'PRIVATE',
    name: 'Privado'
  }
]

export const EDUCATION_LEVEL = [
  {
    id: 1,
    code: 'ALL',
    name: 'Todas'
  },
  {
    id: 2,
    code: 'UNIVERSITY',
    name: 'Universidad'
  },
  {
    id: 3,
    code: 'INSTITUTE',
    name: 'Instituto'
  }
]

import { useEffect, useState } from 'react'
import Joyride from 'react-joyride'
import { Link, useNavigate, useParams } from 'react-router-dom'

// Services
import { getCountByInstitutionAndCareer } from '../../services/apis/course'
import { getMeshWithData, getResultMesh } from '../../services/apis/mesh'
import { setFavouriteStatus, setTutorialStatus } from '../../services/apis/record'
import { setDemoMeshStatus } from '../../services/apis/user'

// Methods
import { isMobile } from '../../common/methods/devices'
import { getWorkspaceParams } from '../../common/methods/params'
import { getDemoCareerStatus, getDemoMeshStatus, getRecord, getTutorialStatus, getUser, setRecord, setUser } from '../../common/methods/storage'

// Components
import IntroTooltip from '../../components/intro/IntroTooltip'
import ButtonStar from '../../components/shared/ButtonStar'
import DashboardMenu from '../../components/shared/DarshboardMenu'
import TitleMessage from '../../components/shared/TitleMessage'

// Constants
import { PAGES } from '../../constants/Pages'
import { MESH } from '../../constants/tutorial'

// Images
import avatarDefault from '../../assets/images/avatar-default.svg'
import bgCounter from '../../assets/images/bg-counter-tinder.svg'
import burblePopup from '../../assets/images/burble-popup.svg'
import btnResult from '../../assets/images/buttons/btn-result.svg'
import compassLight from '../../assets/images/compass-light.svg'
import iconBackWhite from '../../assets/images/icons/icon-back-white.svg'
import iconHeart from '../../assets/images/icons/icon-heart.svg'
import bgHearthBig from '../../assets/images/icons/icon-hearth-big-star.svg'
import iconStarBig from '../../assets/images/icons/icon-hearth.svg'
import iconStar from '../../assets/images/icons/icon-star-point.svg'
import iconDashboardMobile from '../../assets/images/logos/icon-dashboard-menu-mobile.svg'

const Result = () => {
  const navigate = useNavigate()
  const { token } = useParams()

  const { url } = getWorkspaceParams()
  const user = getUser()
  const record = getRecord()

  const tutorialStatus = getDemoMeshStatus()

  const [courseCount, setCourseCount] = useState<number>(0)
  const [result, setResult] = useState<{ percentage: number, title: string, content: string }>({ percentage: 0, title: '', content: '' })

  const [career, setCareer] = useState<any>(null)
  const [institution, setInstitution] = useState<any>(null)

  const [showPanel, setShowPanel] = useState<boolean>(false)
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [messagePanel, setMessagePanel] = useState<string>('')
  const [statusSelectedOption, setStatusSelectedOption] = useState<'HELP' | 'CONTACT'>('HELP')

  const [run, setRunTutorial] = useState<boolean>(false)
  const [stepList, setStepList] = useState<any>([])
  const [step, setStepTutorial] = useState<number>(0)

  const [loadingTutorial, setLoadingTutorial] = useState<boolean>(true)

  useEffect(() => {
    getMesh()
    getResult()
    getCloseTutorial()
  }, [])

  const getMesh = () => {
    if (token) {
      getMeshWithData(token)
        .then(data => {
          if (data.status) {
            setInstitution(data.institution)
            setCareer(data.career)
            getCoursesCount(data.institution.id, data.career.career_id)
          }
        })
    }
  }

  const getCoursesCount = (institution_id: string, career_id: string) => {
    getCountByInstitutionAndCareer(institution_id, career_id)
      .then(count => {
        setCourseCount(count)
      })
  }

  const getCloseTutorial = async () => {
    const mainTutorialStatus = getTutorialStatus();
    const secondTutorialStatus = getDemoCareerStatus();

    if (mainTutorialStatus && !secondTutorialStatus && !tutorialStatus) {
      setRecord({
        ...record,
        demo: false
      })
      await setTutorialStatus(false)
    }
  }

  const getResult = () => {
    if (token) {
      getResultMesh(token)
        .then(data => {
          if (data.status) {
            setResult({ percentage: data.percentage, title: data.title, content: data.content })
          }
        })
    }
  }

  const selectNeedHelp = () => {
    setMessagePanel(`No pierdas la emoción y comunícate de una ves con ${institution.name} para comentarles tu interés sobre la carrera.`)
    setStatusSelectedOption('HELP')
    showPanelLoad()
  }

  const selectContactInstitution = () => {
    setMessagePanel(`Contacta con la ${institution.name} para comunicarles tu interés por la carrera que acabas de revisar.`)
    setStatusSelectedOption('CONTACT')
    showPanelLoad()
  }

  const showPanelLoad = () => {
    setShowPanel(true)
  }

  const hidePanel = () => {
    setShowPanel(false)
  }

  useEffect(() => {
    getMesh()
    if (tutorialStatus) {
      setStepList([...MESH])
      setStepTutorial(3)
      setRunTutorial(true)
      setLoadingTutorial(false)
    }
  }, [])

  const handleTutorialEnd = async () => {
    if (tutorialStatus) {
      setUser({ ...user, demo_mesh: false })
      setRecord({ ...record, demo: false })
      await setDemoMeshStatus(false)
      await setTutorialStatus(false)
      await setFavouriteStatus(true)
      /* if (!getTutorialStatus() && !getDemoMenuStatus()) {
        await setDemoCareerStatus(false)
      } */
      setTimeout(() => {
        navigate(`/${url}${PAGES.DASHBOARD_HOME}`)
      }, 800);
    }
  }

  const handleCallback = (data: any) => {
    if (!run) return

    const { action, index, type } = data

    document.querySelectorAll('.joyride-element-show').forEach(function (elemento) {
      elemento?.classList.remove('joyride-element-show')
    })

    if (data.step?.target !== 'body' && !!data.step?.target) {
      const targetElement = document.querySelector(data.step?.target)
      targetElement.classList.add('joyride-element-show')
    }

    if (action === 'next' && index === step && type === 'step:after') setStepTutorial(step + 1)
    if (action === 'prev' && index === step && type === 'step:after') setStepTutorial(step - 1)

    if (type === 'tour:end') {
      setLoadingTutorial(true)
      setStepTutorial(0)
      setRunTutorial(false)
    }
  }

  return (
        <article id="career" className='bg-main-alt overflow-hidden'>
            {
            !loadingTutorial && (
            <Joyride
                callback={handleCallback}
                steps={stepList}
                continuous
                hideCloseButton
                disableScrolling
                disableCloseOnEsc
                disableOverlayClose
                showSkipButton={false}
                stepIndex={step}
                run={run}
                tooltipComponent={IntroTooltip}
                styles={{
                  options: {
                    arrowColor: '#60DFFF',
                    overlayColor: 'rgba(0, 0, 12, 0.9)'
                  }
                }}
            />
            )}
            <section className="w-screen h-screen quiz-screen-background flex flex-col pt-6 px-6 pb-2 max-[480px]:py-0 max-[480px]:px-0">
                {
                  (!isMobile() || (isMobile() && !showMenu)) &&
                  <DashboardMenu currentPage={0} logoPosition='TOP' />
                }
                <div className="w-full h-[50px] max-[480px]:h-auto max-[480px]:mb-[10px] max-[480px]:px-3.5">
                    <div className="flex items-center justify-between max-[480px]:flex-col max-[480px]:gap-y-4 max-[480px]:w-full">
                        <div className="flex max-[480px]:gap-y-4 max-[480px]:w-full">
                            {
                                career != null &&
                                <Link to={`/${url}/career/${career.career_id}`} className={`w-[250px] h-[50px] ${isMobile() ? '' : 'dashboard-gradient'} bg-cover flex items-center justify-start gap-x-4 px-4 border-2 border-t-[#ffffff86] border-l-[#ffffff86] border-r-[#ffffff86] border-b-0 rounded-tl-[30px] rounded-tr-[8px] transition-all ease-in-out duration-200 opacity-50 hover:opacity-100 max-[480px]:w-[50px] max-[480px]:h-[60px] max-[480px]:border-none max-[480px]:p-0 max-[480px]:self-start max-[480px]:pt-[5px] max-[480px]:opacity-100`}>
                                    <div className="w-[30px] h-[30px] rounded-full border border-[#ffffff7a] flex items-center justify-center hover:scale-105 hover:opacity-100 max-[480px]:w-[32px] max-[480px]:h-[32px]">
                                        <img src={iconBackWhite} alt="iconWhite" width="12" height="12" />
                                    </div>
                                    {
                                      !isMobile() &&
                                      <div className="flex flex-col items-start justify-center">
                                        <p className="ff-m--regular text-white text-[12px] leading-none">Volver</p>
                                        <p className="ff-cp--bold text-white text-[16px] leading-none">Malla</p>
                                      </div>
                                    }
                                </Link>
                            }
                            <div className="absolute t-[25px] left-[275px] z-[10] w-[250px] h-[52px] dashboard-gradient bg-cover flex items-center justify-start gap-x-4 px-4 border border-t-[#fff] border-l-[#fff] border-r-[#fff] border-b-[#320a4e] border-b-[2px] rounded-tl-[30px] rounded-tr-[8px] max-[480px]:mt-0 max-[480px]:h-auto max-[480px]:border-none max-[480px]:w-full max-[480px]:justify-center max-[480px]:bg-none max-[480px]:pr-[45px] max-[480px]:relative max-[480px]:left-0">
                                {
                                  !isMobile() &&
                                  <img src={iconHeart} alt="iconHeart" width="30" height="30" />
                                }
                                <p className="text-white ff-cp--bold text-[20px] leading-none">Revisar Malla</p>
                            </div>
                        </div>
                        {
                          !isMobile() &&
                          <div className="flex items-center">
                            <div className="w-[36px] h-[36px] rounded-full border-2 border-white overflow-hidden mr-2">
                                <img src={avatarDefault} alt="" className="w-[36px] h-[36px]" />
                            </div>
                            <p className="ff-cp--bold text-[20px] text-white whitespace-nowrap">
                                {
                                    user &&
                                    user.name + ' ' + user.last_name
                                }
                            </p>
                          </div>
                        }
                    </div>
                </div>
                <div className="result-wrap w-full h-[calc(100%-100px)] relative overflow-hidden gap-x-4 dashboard-gradient border-2 border-white/[.8] rounded-b-xl rounded-tl-none rounded-tr-[50px] max-[480px]:border-[1px] max-[480px]:w-[calc(100%-25px)] max-[480px]:h-[calc(100%-65px)] max-[480px]:mx-auto max-[480px]:rounded-t-2xl max-[480px]:rounded-b-none max-[480px]:border-b-0">
                    <div className="w-full h-full flex flex-col overflow-auto items-center gap-[20px] px-[20px] py-[18px] xl:px-[37px] xl:py-[38px] relative max-[480px]:overflow-hidden max-[480px]:px-3">
                        <div className='flex flex-col w-full items-center'>
                            {
                              !isMobile() &&
                              <div className="flex items-center gap-[10px] mb-[10px]">
                                <div className="flex items-center relative gap-4">
                                    <p className="w-[30px] h-[24px] bg-cover bg-no-repeat cursor-pointer" style={{ backgroundImage: `url(${iconStarBig})` }}></p>
                                    <div className="w-[310px] text-center">
                                        <p className="ff-cp--bold text-[16px] leading-none text-white">{career?.career}</p>
                                    </div>
                                </div>
                                <div className='w-[20px] h-[20px]' style={{ backgroundImage: `url(${iconStar})` }}></div>
                                <div className="flex items-center relative gap-4">
                                    <div className="w-[310px] text-center">
                                        <h2 className="ff-cp--bold text-[16px] leading-none text-white">{institution?.name}</h2>
                                    </div>
                                    <p className="w-[30px] h-[24px] bg-cover bg-no-repeat cursor-pointer" style={{ backgroundImage: `url(${iconStarBig})` }}></p>
                                </div>
                              </div>
                            }

                            <div className="w-full flex justify-center max-w-[1127px] m-auto relative z-10">
                                <div className="w-[86px] h-[27px] xl:w-[116px] xl:h-[36px] flex justify-center bg-cover items-center after:absolute after:top-[11px] xl:after:top-[17px] after:w-full after:h-[1px] after:bg-white after:z-[-1]" style={{ backgroundImage: `url(${bgCounter})` }}>
                                    <span className='w-[92px] h-[21px] text-center ff-cp--bold text-[11px] xl:text-[16px] leading-[21px] text-white'>{courseCount}/{courseCount}</span>
                                </div>
                            </div>
                        </div>

                        <div className="grid max-[480px]:my-auto">
                            <div className="grid justify-items-center gap-y-2">
                                <div className="w-auto h-[155px] xl:h-[250px] aspect-[1.13] flex items-center justify-center bg-cover  max-[480px]:pb-2" style={{ backgroundImage: `url(${bgHearthBig})` }}>
                                    <div className="ff-cp--bold text-[calc(25px+3dvh)] xl:text-[calc(25px+5dvh)] text-white">{result.percentage}<span className="text-[30px]">%</span></div>
                                </div>
                                <div className="max-[480px]:px-2">
                                    <p className="ff-cp--bold text-[24px] text-center text-white leading-none mb-[5px] max-[480px]:text-[18px]">{result.title}</p>
                                    <p className="ff-cp--regular text-[16px] text-center text-white leading-none mb-[8px] max-[480px]:text-[15px]">{result.content}</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-3 justify-items-center max-[480px]:grid-cols-[135px_34px_135px] max-[480px]:justify-items-center">
                                <button
                                    onClick={() => {
                                      if (tutorialStatus) {
                                        setShowMenu(true);
                                        hidePanel();
                                      } else {
                                        navigate(`/${url}${PAGES.CAREER}${career?.career_id ?? ''}`)
                                      }
                                    }}
                                    className="w-[155px] h-[48px] xl:w-[230px] xl:h-[65px] mt-[40px] xl:mt-[60px] text-center max-[480px]:bg-center flex items-center pt-[4px] xl:pt-[12px] px-6 text-white text-[11px] xl:text-[14px] place-content-center bg-cover hover:scale-105 max-[480px]:w-[135px] max-[480px]:h-[47px] max-[480px]:text-[9px] max-[480px]:px-4 max-[480px]:py-0"
                                    style={{ backgroundImage: `url(${btnResult})` }}
                                >
                                    <span className="ff-cp--regular text-center font-bold leading-3 tracking-normal max-[480px]:leading-[9px]">No, todavía quiero ver más</span>
                                </button>
                                <button
                                    onClick={() => { window.open('https://wa.me/999999999', '_blank'); if (tutorialStatus) { setShowMenu(true); hidePanel() } }}
                                    className="w-[155px] h-[48px] xl:w-[230px] xl:h-[65px] text-center flex items-center max-[480px]:bg-center pt-[4px] xl:pt-[12px] px-6 text-white text-[11px] xl:text-[14px] place-content-center bg-cover hover:scale-105 max-[480px]:w-[135px] max-[480px]:h-[47px] max-[480px]:text-[9px] max-[480px]:px-4 max-[480px]:py-0"
                                    style={{ backgroundImage: `url(${btnResult})` }}
                                >
                                    <span className="ff-cp--regular text-center font-bold leading-3 tracking-normal max-[480px]:leading-[9px]">Necesito ayuda</span>
                                </button>
                                <button
                                    onClick={() => user.workspace?.logo ? selectContactInstitution() : selectNeedHelp()}
                                    className="w-[155px] h-[48px] xl:w-[230px] xl:h-[65px] mt-[40px] xl:mt-[60px] text-center max-[480px]:bg-center flex items-center pt-[4px] xl:pt-[12px] px-6 text-white text-[11px] xl:text-[14px] place-content-center bg-cover hover:scale-105 max-[480px]:w-[135px] max-[480px]:h-[47px] max-[480px]:text-[9px] max-[480px]:px-4 max-[480px]:py-0"
                                    style={{ backgroundImage: `url(${btnResult})` }}
                                >
                                    <span className="ff-cp--regular text-center font-bold leading-3 tracking-normal max-[480px]:leading-[9px]">Sí, me decidí por esta carrera</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="nothing"></div>
                </div>
            </section>
            {
                showPanel &&
                <div className={'w-full h-full fixed inset-0 bg-[#070625]/[.5] flex items-center justify-center delay-1000 overflow-hidden z-[200]'} style={{ transition: 'height ease 1200ms' }}>
                    <div className="rounded-full h-[90%] max-[480px]:w-full max-[480px]:h-[auto] max-[480px]:px-0 aspect-square p-[9px] grid justify-self-center bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${burblePopup})` }}>
                        <div className="w-full h-full rounded-full flex flex-col items-center justify-center pt-20">
                            <div className="w-[120%] justify-self-center">
                                <TitleMessage
                                    index={0}
                                    useLoading={false}
                                    size="w-full h-auto pl-[22%]"
                                    title=""
                                    message={messagePanel}
                                />
                            </div>
                            <div className="w-full flex justify-center gap-x-14">
                                <ButtonStar
                                  text='Seguir explorando'
                                  type='purple'
                                  textSize='max-[480px]:text-[10px]'
                                  callback={() => { if (tutorialStatus) { setShowMenu(true) }; hidePanel(); }}></ButtonStar>
                                <ButtonStar
                                    text={statusSelectedOption === 'CONTACT' ? 'Contactar con la Universidad' : 'Revisar otras Mallas'}
                                    type='purple'
                                    textSize='max-[480px]:text-[10px]'
                                    callback={statusSelectedOption === 'CONTACT' ? () => { window.open('https://wa.me/999999999', '_blank'); if (tutorialStatus) { setShowMenu(true); hidePanel() } } : () => { if (tutorialStatus) { setShowMenu(true); hidePanel() } else { navigate(`/${url}${PAGES.CAREER}${career?.career_id ?? ''}`) } } }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                showMenu &&
                <div className={'w-full h-full fixed inset-0 bg-[#070625]/[.5] flex items-center justify-center delay-1000 overflow-hidden z-[200]'} style={{ transition: 'height ease 1200ms' }}>
                    <button
                      onClick={() => handleTutorialEnd()}
                      className="absolute bottom-0 left-0 right-0 mx-auto w-[310px] h-[159px] bg-center bg-no-repeat bg-cover max-[480px]:top-[4px] max-[480px]:right-[10px] max-[480px]:w-[60px] max-[480px]:h-[60px] max-[480px]:opacity-100 max-[480px]:mx-0 max-[480px]:left-auto"
                      style={{ backgroundImage: `url(${isMobile() ? iconDashboardMobile : compassLight})` }}
                    />
                </div>
            }
        </article>
  )
}

export default Result

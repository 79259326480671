import { getShield } from '../../common/methods/Shield'

// Methods
import { isMobile } from '../../common/methods/devices'
import { getUser } from '../../common/methods/storage'

// Images
import { useRef } from 'react'
import bgCareerMobile from '../../assets/images/bg-career-mobile.svg'
import bgCareerMobileActive from '../../assets/images/bg-career-selected-mobile.svg'
import bgCareerActive from '../../assets/images/bg-career-selected.svg'
import bgCareer from '../../assets/images/bg-career.svg'

interface CardProps {
    career: any,
    favourite?: boolean,
    type: string,
    size?: string,
    percent?: string,
    active?: boolean,
    profiles?: string[],
    currentPage?: string,
    tab_menu_list?: number[],
    callback?: () => void,
    onDeselected?: (id: string) => void
}

const CardInput = ({ career, type, size, active, callback, onDeselected, profiles = [], currentPage, tab_menu_list }: CardProps) => {
  const user = getUser()
  const inputRef = useRef(null)
  return (
        <button
            key={career.id}
            onClick={callback}
            ref={inputRef}
            className={'relative grid justify-items-center w-full aspect-[0.83] transition ease scale-100 bg-cover cursor-pointer max-[480px]:w-[164px] max-[480px]:h-[128px]'}
            onMouseOver={() => {
              if (inputRef.current && !active) {
                const element: any = inputRef.current;
                element.style.backgroundImage = `url(${isMobile() ? bgCareerMobileActive : bgCareerActive})`;
              }
            }}
            onFocus={() => {
              if (inputRef.current && !active) {
                const element: any = inputRef.current;
                element.style.backgroundImage = `url(${isMobile() ? bgCareerMobileActive : bgCareerActive})`;
              }
            }}
            onMouseOut={() => {
              if (inputRef.current && !active) {
                const element: any = inputRef.current;
                element.style.backgroundImage = `url(${isMobile() ? bgCareerMobile : bgCareer})`;
              }
            }}
            onBlur={() => {
              if (inputRef.current && !active) {
                const element: any = inputRef.current;
                element.style.backgroundImage = `url(${isMobile() ? bgCareerMobile : bgCareer})`;
              }
            }}
            style={{ backgroundImage: `url(${active ? (isMobile() ? bgCareerMobileActive : bgCareerActive) : (isMobile() ? bgCareerMobile : bgCareer)})` }}
        >
            <div className={'w-full pt-[44px] px-6 pb-0 max-[480px]:pt-[30px] max-[480px]:px-[14px]'}>
                <h3 className={'ff-cp--bold text-white text-left text-sm leading-4 pr-[40px] mb-1 overflow-hidden text-clip whitespace-pre-wrap break-words max-[480px]:text-[12px] max-[480px]:leading-[14px]'}>{career.career ?? career.name}</h3>
                { type === 'complex' && <p className="text-[8.5px] text-white pr-[25px] mb-1 leading-tight">{career.description}</p> }
                { type !== 'hidden' && profiles.length > 0 && (
                    <div className="flex gap-1">
                        {
                            profiles.map(prof => {
                              return <img key={prof} className="w-6 h-6" src={getShield(prof).shield} alt="" />
                            })
                        }
                    </div>
                )}
                { !!career.HasInstitution && (
                    <div className="absolute bottom-[20px] left-[15px] right-[15px] max-[480px]:bottom-[12px] max-[480px]:left-[10px] max-[480px]:right-[10px] max-[480px]:p-0 flex items-center px-2">
                        {
                            !!user.workspace?.logo &&
                            <div className="min-w-[45px] h-[45px] max-[480px]:w-[35px] max-[480px]:h-[35px] flex items-center justify-center">
                                <img className="w-[36px] h-[36px] max-[480px]:w-[25px] max-[480px]:h-[25px]"
                                  src={user.workspace.logo} alt=""
                                />
                            </div>
                        }
                        <span className="text-[9px] text-white leading-[10px] max-[480px]:text-[8px] max-[480px]:leading-[9px]">{career.HasInstitution}</span>
                    </div>
                )}
            </div>
        </button>
  )
}

export default CardInput

import { getUser, setUser } from '../../common/methods/storage'
import { headers } from '../../constants/connection'
import { API_ROUTES } from './../../constants/api_routes'

export const getAllUsers = async () => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.USER.GET.ALL}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    }
  })
    .then((response) => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

export const setDemoUserStatus = async (demo: boolean) => {
  const user = getUser()
  if (user) {
    const body = { demo }
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.USER.UPDATE.DEMO}`, {
      method: 'PATCH',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      body: JSON.stringify(body)
    })
      .then((response) => response.json())
      .then(data => {
        if (data.status) {
          setUser({ ...user, demo_input: demo })
        }
      })
  }
}

export const setDemoCareerStatus = async (demo: boolean) => {
  const user = getUser()
  if (user) {
    const body = { demo }
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.USER.UPDATE.DEMO_CAREER}`, {
      method: 'PATCH',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      body: JSON.stringify(body)
    })
      .then((response) => response.json())
      .then(data => {
        if (data.status) {
          setUser({ ...user, demo_career: demo })
        }
      })
  }
}

export const setDemoMeshStatus = async (demo: boolean) => {
  const user = getUser()
  if (user) {
    const body = { demo }
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.USER.UPDATE.DEMO_MESH}`, {
      method: 'PATCH',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      body: JSON.stringify(body)
    })
      .then((response) => response.json())
      .then(data => {
        if (data.status) {
          setUser({ ...user, demo_mesh: demo })
        }
      })
  }
}

export const setDemoMenuStatus = async (demo: boolean) => {
  const user = getUser()
  if (user) {
    const body = { demo }
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.USER.UPDATE.DEMO_MENU}`, {
      method: 'PATCH',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      body: JSON.stringify(body)
    })
      .then((response) => response.json())
      .then(data => {
        if (data.status) {
          setUser({ ...user, demo_menu: demo })
        }
      })
  }
}

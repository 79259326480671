import { headers } from '../../constants/connection'

export const getWorkspaceById = async (id: string) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api/workspaces/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    }
  })
    .then((response) => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

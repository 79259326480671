import { useNavigate } from 'react-router-dom'

// Methods
import { getWorkspaceParams } from '../../../common/methods/params'
import { getRecord, getTutorialStatus } from '../../../common/methods/storage'

// Components
import ConstelationChart from '../../../components/charts/ConstelationChart'
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'

// Constants
import { PAGES } from '../../../constants/Pages'
import { STAR_CHART_URL } from '../../../constants/cards'
import { REACTIONS } from '../../../constants/reactions'

// Images
import btnSimpleBack from '../../../assets/images/buttons/btn-simple-back.svg'
import bgCardSimpleMobile from '../../../assets/images/cards/bg-card-simple-mobile.svg'
import bgCardSimple from '../../../assets/images/cards/bg-card-simple.svg'
import constelationNoun from '../../../assets/images/constelations/constelation-noun.svg'
import starReverseWhite from '../../../assets/images/stars/star-card-reverse-white.svg'
import { isMobile } from '../../../common/methods/devices'

const ResultsNouns = () => {
  const navigate = useNavigate()

  const { url } = getWorkspaceParams()
  const record = getRecord()

  const getNouns = () => {
    if (!record) return []

    const { features } = record

    const reactionLoveNouns:any[] = features.filter((feature: any) => feature.type === 'nouns' && feature.parent_id && feature.profiles.reaction === REACTIONS.LOVE)
    const reactionLikeNouns:any[] = features.filter((feature: any) => feature.type === 'nouns' && feature.parent_id && feature.profiles.reaction === REACTIONS.LIKE)

    let nouns = [...reactionLoveNouns, ...reactionLikeNouns]

    const positions: string[] = [
      'top-[220px] left-[206px]',
      'top-[220px] right-[206px]',
      'top-[285px] left-[136px]',
      'top-[285px] right-[136px]',
      'top-[336px] left-[254px]',
      'top-[186px] left-[165px]',
      'top-[186px] right-[165px]',
      'top-[150px] left-[125px]',
      'top-[150px] right-[125px]',
      'top-[125px] left-[252px]',
      'top-[85px] left-[196px]',
      'top-[85px] right-[196px]',
      'top-[54px] left-[253px]',
      '-top-[2px] left-[253px]',
      'top-[370px] left-[215px]',
      'top-[370px] right-[215px]',
      'top-[425px] left-[254px]',
      'top-[194px] left-[102px]',
      'top-[194px] right-[102px]',
      'top-[295px] left-[88px]',
      'top-[296px] right-[84px]',
      'top-[110px] left-[90px]',
      'top-[110px] right-[90px]',
      'top-[85px] left-[142px]',
      'top-[85px] right-[142px]',
      'top-[218px] left-[38px]',
      'top-[218px] right-[38px]',
      'top-[325px] left-[38px]',
      'top-[325px] right-[38px]',
      'top-[214px] -left-[40px]',
      'top-[214px] -right-[40px]'
    ]

    nouns = nouns.map((noun: any, index: number) => ({
      name: noun.name,
      star: STAR_CHART_URL.find(star => star.includes(noun.parent_id)),
      position: positions[index]
    }))

    return nouns
  }

  return (
        <article className="bg-dashboard" id="home">
            <section className="w-screen h-screen quiz-screen-background relative bg-compass flex flex-col">
                <ButtonRedirect text="" action="return" linkReturn={record?.last_page ?? PAGES.RESULT.GENERAL} />
                {
                  getTutorialStatus() &&
                  <ButtonRedirect
                      action="next"
                      text="next"
                      linkReturn={PAGES.RESULT.VOCATIONAL}
                  />
                }
                <BadgeStep type="user" />
                <div className={`fixed right-[50px] top-0 bottom-0 w-[264px] h-[151px] m-auto z-[60] bg-no-repeat bg-center bg-cover max-[480px]:m-0 max-[480px]:top-4 max-[480px]:left-2.5 max-[480px]:right-2.5 max-[480px]:w-[calc(100%-20px)] max-[480px]:h-auto max-[480px]:aspect-[4.7] ${isMobile() ? '' : 'left-arrow'}`} style={{ backgroundImage: `url(${isMobile() ? bgCardSimpleMobile : bgCardSimple})` }}>
                    <div className="w-full h-full flex items-center justify-center  py-5 px-6">
                      <p className="ff-m--regular text-[12px] leading-none text-white max-[480px]:text-[11px] max-[480px]:text-center">Este es el universo de sustantivos que componen tu vocación. Ellos representan las áreas de interés sobre las cuales tomas acción según tus verbos.</p>
                    </div>
                </div>
                {
                  !getTutorialStatus() &&
                  <button
                      onClick={() => navigate(`/${url}${PAGES.RESULT.VOCATIONAL}`)}
                      className="fixed left-[50px] top-0 bottom-0 w-[230px] h-[55.5px] m-auto z-[60] bg-no-repeat bg-center bg-cover cursor-pointer transition-all ease-in-out hover:scale-105"
                      style={{ backgroundImage: `url(${btnSimpleBack})` }}>
                      <div className="w-full h-full py-4 pl-14">
                          <p className="ff-m-regular text-[10px] leading-none text-white">Constelación</p>
                          <p className="ff-cp-bold text-[14px] leading-none text-white">Vocacional</p>
                      </div>
                  </button>
                }
                <div className={`w-full h-full relative flex flex-col items-center justify-center py-8 max-[480px]:overflow-hidden max-[480px]:pt-[calc(((100%-20px)*10/47)+60px)] ${isMobile() ? '' : 'bg-feature-big-simple before:w-[648px] before:h-[648px]'}`}>
                    <div className="h-full flex flex-col gap-y-2 items-center justify-start flex-1 px-10 py-10 z-50 max-[480px]:pt-1">
                        <div className="w-[370px] h-[70px] bg-center bg-no-repeat bg-cover max-[480px]:w-[266px] max-[480px]:h-[50px]" style={{ backgroundImage: `url(${starReverseWhite})` }}>
                            <div className="w-full h-full max-[480px]:h-[50px] flex flex-col items-center justify-center pt-1 pb-4 px-5 max-[480px]:pb-3">
                                <p className="ff-m--regular text-white text-xs max-[480px]:text-[11px] max-[480px]:leading-[12px]">Tu Constelación de Sustantivos</p>
                                <p className="ff-m--bold text-white text-xl max-[480px]:text-[14px] max-[480px]:leading-[15px]">Constelación de tus pasiones</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-center max-[480px]:scale-[.45] max-[480px]:h-[350px]">
                            <ConstelationChart chart={constelationNoun} features={getNouns()} shadowText />
                        </div>
                    </div>
                </div>
            </section>
        </article>
  )
}

export default ResultsNouns

import { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'

// Services
// import { getAllFeaturesByType } from '../../../services/apis/feature'
// import { createProfilesByList } from '../../../services/apis/profile'
// import { getCalculatedVocationalProfile, getCurrentRecord, updateRecord } from '../../../services/apis/record'

// Components
import AppMessage from '../../../components/shared/AppMessage'
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'

// Methods
// import { includeEvery } from '../../../common/methods/Includes'
// import { getWorkspaceParams } from '../../../common/methods/params'
import { getRecord } from '../../../common/methods/storage'

// Constants
import { PAGES } from '../../../constants/Pages'
import { profile_stat } from '../../../constants/lists/profiles'
import { REACTIONS } from '../../../constants/reactions'

// Images
// import iconSearch from '../../../assets/images/icon-search.svg'

function Profile () {
  // const { url } = getWorkspaceParams()

  // const navigate = useNavigate()

  const record = getRecord()

  const [isLoading, setIsLoading] = useState<boolean>(true)

  // const [selectedList, setSelectedList] = useState<any[]>([])
  // const [features, setFeatures] = useState<any[]>([])

  // const [showModal, setShowModal] = useState<string | null>(null)

  useEffect(() => {
    // getFeatures()
    setTimeout(() => {
      // getProfiles()
      setIsLoading(false)
    }, 300)
  }, [])

  // const getProfiles = () => {
  //   if (record) {
  //     let profiles = record.features.filter((feature: any) => feature.type === 'profile')
  //     profiles = profiles.map((profile: any) => ({ ...profile, feature: profile, selected: profile.profiles.selected, weight: null, feature_id: profile.id }))

  //     setSelectedList([...profiles ?? []])
  //   }
  // }

  // const onSetSelectedList = (data: any) => {
  //   const selected_list = selectedList

  //   const feature = features.find(ft => ft.id === data.id)
  //   const index = selected_list.findIndex(sl => sl.id === data.id)

  //   if (index === -1) {
  //     selected_list.push({
  //       ...feature,
  //       feature_id: data.id,
  //       feature,
  //       selected: true,
  //       weight: null
  //     })
  //   } else {
  //     selected_list[index].selected = !selected_list[index].selected
  //   }

  //   setSelectedList([...selected_list])
  // }

  // const isContainKey = (id: string) => selectedList.findIndex(sl => sl.id === id && sl.selected) !== -1

  // const getFeatures = async () => {
  //   await getAllFeaturesByType('profile')
  //     .then(data => setFeatures(data.data))
  // }

  // const onSetShowModal = (id: string) => {
  //   if (showModal) setShowModal(null)
  //   else setShowModal(id)
  // }

  // const onSetStep = async () => {
  //   await createProfilesByList(selectedList)
  //     .then(async (data) => {
  //       if (data.status) {
  //         await getCalculatedVocationalProfile()
  //           .then(async (result: any) => {
  //             let nextPage = '/loading/a460712c-9213-11ed-a1eb-0242ac120002'

  //             const { profiles } = result
  //             const profileCalculatedIds: any[] = profiles.filter((profile: any) => profile.status).map((profile: any) => profile.id)
  //             const selectedProfileIds = selectedList.filter((profile) => profile.selected).map((profile) => profile.feature_id)

  //             if (profileCalculatedIds.length !== selectedProfileIds.length || !includeEvery(profileCalculatedIds, selectedProfileIds)) {
  //               nextPage = PAGES.DECISION_3
  //             }

  //             await updateRecord({ step: 27 })
  //               .then(async () => {
  //                 await getCurrentRecord()
  //                   .then(() => {
  //                     navigate(`/${url}${nextPage}`)
  //                   })
  //               })
  //           })
  //       }
  //     })
  // }

  // const goToProfile = async () => {
  //   navigate(`/${url}${PAGES.PROFILE.WEAVER}`)
  //   // await createProfilesByList(selectedList)
  //   //   .then(() => {
  //   //   })
  // }

  const getReturnLink = () => {
    if (record) {
      const verbs = record.features.filter((ft: any) => ft.type === 'profile_verbs' && ft.profiles.reaction === REACTIONS.LOVE)
      if (verbs.length === 1) return PAGES.SELECTED_PROFILE_VERBS
      else return PAGES.TIERLIST_PROFILE_VERBS
    }
    return ''
  }

  return (

    <article className="bg-main bg-oldwoman" id="home">
      <section className="w-screen h-screen cloud-page max-[480px]:py-4  xl:py-4 flex flex-col gap-y-2">
        <ButtonRedirect action="return" linkReturn={getReturnLink()} text="return" />
        <BadgeStep currentStep={26} />
        <AppMessage
            index={0}
            useAnimationLoading={false}
            size="w-1/2 max-w-[800px] h-auto"
            title='Ahora te presentaré a mis amigos'
            message='Conoce a todos y cuéntame con quién o quienes te identificas'
          />
          <ButtonRedirect
            linkReturn={PAGES.PROFILE_DETAIL}
            action="next"
            // callback={goToProfile}
            text="next" />

          {/* selectedList.filter(item => item.selected).length > 0 && !isLoading && */}
        {
          !isLoading &&
          <div key="profiles-container" className="w-full flex-1 overflow-hidden px-[35px] relative gradient-purple max-[480px]:z-[40]">
            <div className="h-full animation-charge">
              {
                profile_stat.map((profile, index) => {
                  return (
                    <div key={profile.id} className={`fixed z-[450] w-auto xl:w-auto h-min z-${index < 2 ? 50 : 40} ${profile.side} ${profile.top} flex flex-col items-center justify-center gap-3 duration-300 opacity-100 cursor-pointer`}>
                      <img
                        className={`w-[100px] h-[150px] max-[480px]:w-[100px] max-[480px]:h-[200px] xl:w-[235px] xl:h-[340px] object-cover ${profile.animation}`}
                        alt="brand"
                        src={profile.profile}
                        style={{ maskImage: 'linear-gradient(black 80%, transparent)' }}
                      />
                      {/* <button onClick={() => onSetSelectedList(profile)} className='w-[150px] h-[40px] xl:w-[230px] xl:h-[60px] bg-cover bg-center bg-no-repeat flex items-center justify-center text-center pt-1 pl-5 transition-all ease-in hover:scale-110'>
                        <span className="text-white text-[14px] xl:text-[20px] ff-m--bold">{profile.name}</span>
                      </button> */}
                      {/* <div className="w-[30px] h-[30px] xl:w-[52px] xl:h-[52px] bg-[#070625] rounded-full border border-solid border-white flex items-center justify-center transition-all ease-in hover:scale-110">
                        <button onClick={() => saveProfiles(profile.id)} className="w-[40px] h-[40px] rounded-full border border-solid border-[#42324E]/[.42] flex items-center justify-center">
                          <img src={iconSearch} alt='search' className="w-[22px] h-[22px] bg-cover bg-center" ></img>
                        </button>
                      </div> */}
                    </div>
                  )
                })
              }
              {/* {
                  profile_stat.map(prof => {
                    return (
                      <div key={prof.id} className={`absolute top-[48px] left-[-5px] w-[490px] h-[276px] bg-contain bg-no-repeat bg-center transition ease-in duration-500 ${showModal === prof.id ? 'scale-100' : 'scale-0'} before:content-['']`} style={{ backgroundImage: `url(${prof.modal})` }}>
                        <button
                          className="absolute block right-[0px] top-[0px] w-[35px] h-[35px] bg-cover bg-center opacity-100 cursor-pointer hover:scale-110"
                          style={{ backgroundImage: `url(${prof.close})` }}
                          onClick={() => onSetShowModal(prof.id)}
                        />
                    </div>
                    )
                  })
              } */}
            </div>
          </div>
        }

        {/* <div className="hidden absolute border-t-2 border-[#07DACB] items-end justify-self-center bg-[#070625]/[.9] w-full h-full z-20">
            <button className="absolute top-[24px] left-0 right-0 m-auto w-[60px] h-[30px]"><img src={iconClose} alt="" className="w-[60px] h-[30px]" /></button>
            <h2 className='ff-cp--bold text-[56px] justify-self-center mb-[40px]'>¿hacia dónde vas?</h2>
            <div className='mb-4 grid grid-cols-2 place-items-center self-center'>
              <ButtonRedirect type="button" size="w-[340px] h-[90px]" text="Ir al Juego"></ButtonRedirect>
              <ButtonRedirect type="button" size="w-[340px] h-[90px]" text="Seguir en el buscador"></ButtonRedirect>
            </div>
          </div> */}
      </section>
    </article>
  )
}

export default Profile

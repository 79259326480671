export const STEPS_1 = [
  {
    target: '.career-ideal',
    title: 'ideal',
    content: 'Estas carreras las considero IDEALES para tí porque tienen muchísimo de lo que amas. ¡Investígalas y decide!',
    floaterProps: {
      hideArrow: true
    },
    placement: 'center'
  },
  {
    target: '.career-ideal',
    title: 'ideal',
    content: 'Tus Carreras Selecciondas inicialmente son una gran selección, no tengo nada mejor que proponerte.',
    floaterProps: {
      hideArrow: true
    },
    placement: 'center'
  }
]

export const STEPS_2 = [
  {
    target: '.career-ideal',
    title: 'ideal',
    content: 'Estas carreras las considero IDEALES para tí porque tienen muchísimo de lo que amas. ¡Investígalas y decide!',
    floaterProps: {
      hideArrow: true
    },
    placement: 'center'
  },
  {
    target: '.career-optima',
    title: 'optima',
    content: 'Estas carreras las considero ÓPTIMAS para tí porque tienen las suficientes cosas que amas. ¡Investígalas y decide!',
    floaterProps: {
      hideArrow: true
    },
    placement: 'center'
  },
  {
    target: '.career-complement',
    title: 'complement',
    content: 'Estas carreras tienen una parte de lo que amas y puedes COMPLEMENTARLAS con otras que tengan la otra parte. ¡Investígalas y decide!',
    floaterProps: {
      hideArrow: true
    },
    placement: 'center'
  },
  {
    target: '.career-ambiguous',
    title: 'ambiguous',
    content: 'Estas carreras son AMBIGUAS para tí porque tienen elementos que no se si te gustan o disgustan. Tú decides si quieres investigarlas.',
    floaterProps: {
      hideArrow: true
    },
    placement: 'center'
  },
  {
    target: '.career-defiant',
    title: 'defiant',
    content: 'Estas carreras tienen ADVERTENCIAS para tí porque tienen elementos que me has dicho que no te gustan. Míralas con cuidado antes de tomar una decisión.',
    floaterProps: {
      hideArrow: true
    },
    placement: 'center'
  }
]

export const STEPS_3 = [
  {
    target: '.career-ideal',
    title: 'ideal',
    content: 'Estas carreras las considero IDEALES para tí porque tienen muchísimo de lo que amas. ¡Investígalas y decide!',
    floaterProps: {
      hideArrow: true
    },
    placement: 'center'
  },
  {
    target: '.career-optima',
    title: 'optima',
    content: 'Estas carreras las considero ÓPTIMAS para tí porque tienen las suficientes cosas que amas. ¡Investígalas y decide!',
    floaterProps: {
      hideArrow: true
    },
    placement: 'center'
  },
  {
    target: '.career-complement',
    title: 'complement',
    content: 'Estas carreras tienen una parte de lo que amas y puedes COMPLEMENTARLAS con otras que tengan la otra parte. ¡Investígalas y decide!',
    floaterProps: {
      hideArrow: true
    },
    placement: 'center'
  },
  {
    target: '.career-ambiguous',
    title: 'ambiguous',
    content: 'Estas carreras son AMBIGUAS para tí porque tienen elementos que no se si te gustan o disgustan. Tú decides si quieres investigarlas.',
    floaterProps: {
      hideArrow: true
    },
    placement: 'center'
  },
  {
    target: '.career-defiant',
    title: 'toxic',
    content: 'Estas carreras tienen ADVERTENCIAS para tí porque tienen elementos que me has dicho que no te gustan. Míralas con cuidado antes de tomar una decisión.',
    floaterProps: {
      hideArrow: true
    },
    placement: 'center'
  }
]

export const STEPS_4 = [
  {
    target: '.career-defiant',
    title: 'toxic',
    content: 'Estas carreras tienen ADVERTENCIAS para tí porque tienen elementos que me has dicho que no te gustan. Míralas con cuidado antes de tomar una decisión.',
    floaterProps: {
      hideArrow: true
    },
    placement: 'center'
  }
]

export const REACTION_1 = [
  {
    target: '.profile-step',
    title: '¡Oh! Realmente amas “',
    content: '” y deseas que sea parte de tu vida profesional.',
    disableBeacon: true
  }
]

export const REACTION_2 = [
  {
    target: '.profile-step',
    title: '¡Vaya! Veo que te gusta “',
    content: '”.',
    disableBeacon: true
  }
]

export const REACTION_3 = [
  {
    target: '.profile-step',
    title: '¡Ah! ¿Entonces “',
    content: '” te da igual?, en ese caso no habría diferencia si esta o no en tu vida profesional.',
    disableBeacon: true
  }
]

export const REACTION_4 = [
  {
    target: '.profile-step',
    title: '¡Uy! Veo que no te agrada mucho “',
    content: '”, ¿será que no quieres que sea parte de tu vida profesional?',
    disableBeacon: true
  }
]

export const CAREER_1 = [
  {
    target: '.nothing',
    title: 'question',
    content: '¿Estás satisfecho con las CARRERAS que te muestro?',
    placement: 'center'
  },
  {
    target: '.nothing',
    title: '',
    content: 'Genial, sigue explorando tus carreras',
    placement: 'center'
  },
  {
    target: '.card-career',
    title: 'favorite',
    content: 'Si te interesa alguna carrera, no olvides activar su estrella en la esquina para guardarla.',
    placement: 'center'
  }
]

export const CAREER_2 = [
  {
    target: '.nothing',
    title: 'question',
    content: '¿Estás satisfecho con las CARRERAS que te muestro?',
    placement: 'center'
  },
  {
    target: '.tutorial-career',
    title: 'disable_next',
    content: 'Para ver otras opciones de carreras distintas a las que has seleccionado, utiliza este botón y te mostraré las carreras que tengo para tí.',
    placement: 'center'
  },
  {
    target: '.card-career',
    title: '',
    content: 'Estas son otras carreras que podrian interesarte.',
    placement: 'center'
  },
  {
    target: '.card-career',
    title: 'favorite',
    content: 'Si te interesa alguna carrera, no olvides activar su estrella en la esquina para guardarla.',
    placement: 'center'
  }
]

export const CAREER_3 = [
  {
    target: '.nothing',
    title: 'question',
    content: '¿Estás satisfecho con las CARRERAS que te muestro?',
    placement: 'center'
  },
  {
    target: '.nothing',
    title: '',
    content: 'Genial, sigue explorando tus carreras',
    placement: 'center'
  }
]

export const MESH = [
  {
    target: '.tab-institution',
    title: 'mesh',
    content: '¡Ahora revisa la malla curricular de alguna institución para que conozcas a la carrera!',
    placement: 'center'
  },
  {
    target: '.card-wrap',
    title: 'mesh',
    content: '',
    placement: 'center',
    disableBeacon: true
  },
  {
    target: '.institution-wrap',
    title: 'mesh',
    content: '',
    placement: 'center',
    disableBeacon: true
  },
  {
    target: '.result-wrap',
    title: 'mesh',
    content: 'Ahora, es momento de tomar una decisión y seguir un camino. Selecciona una opción para continuar.',
    placement: 'center',
    disableBeacon: true
  }
]

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Services
// import { getAllReferencesByRecord } from '../../../services/apis/career_reference'

// Components
import ConstelationChart from '../../../components/charts/ConstelationChart'
import AppMessage from '../../../components/shared/AppMessage'
import DashboardMenu from '../../../components/shared/DarshboardMenu'

// Methods
import { getWorkspaceParams } from '../../../common/methods/params'
import { getShield } from '../../../common/methods/Shield'
import { getDemoMenuStatus, getRecord, getTutorialStatus, getUser, setRecord, setUser } from '../../../common/methods/storage'

// Constants
import { STAR_CHART_URL } from '../../../constants/cards'
import { needs } from '../../../constants/lists/needs'
import { PAGES } from '../../../constants/Pages'
import { REACTIONS } from '../../../constants/reactions'

// Images
import constelationNoun from '../../../assets/images/constelations/constelation-noun.svg'
import { isMobile } from '../../../common/methods/devices'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
// import { setFavouriteStatus, setTutorialStatus } from '../../../services/apis/record'
import { setDemoMenuStatus } from '../../../services/apis/user'
// setDemoCareerStatus

const Dashboard = () => {
  const user = getUser()

  const navigate = useNavigate()

  const { url } = getWorkspaceParams()
  const tutorialStatus = getTutorialStatus()
  const tutorialMenuStatus = getDemoMenuStatus()
  const record = getRecord()

  const [shield, setShield] = useState<any>(null)
  const [message, setMessage] = useState<string>('')

  const [currentStep, setCurrentStep] = useState(0)
  const [totalSteps] = useState(tutorialMenuStatus ? 6 : 1)

  const [currentMenu, setCurrentMenu] = useState<number>(tutorialStatus ? 3 : 0)

  const [chargeAnimation, setChargeAnimation] = useState<boolean>(false);

  // const [hasFavourites, setHasFavourites] = useState<boolean>(false)

  const messages = [
    'Te mostraré los caminos que tengo para ti',
    '¡Este botón lo puedes utilizar para regrasar a este espacio!',
    '¡Aqui podrás ver las mallas curriculares que ya has revisado!',
    '¡Aqui podrás ver tus carreras candidatas!',
    'Si quisieras cambiar tus respuestas, siempre puedes revivir la experiancia...',
    '¡Puedes cerrar sesión con este botón, yo siempre estaré esperandote!',
    '¿A dónde vas?'
  ]

  const getDataShield = () => {
    if (!record) return

    const shield_id = record.shield
    const shieldData = getShield(shield_id)

    setShield(shieldData)
  }

  const getVerbs = () => {
    if (!record || !shield) return []

    const { features } = record

    const mostHighFeatures = features.filter((feature: any) => needs.includes(feature.type) && [3, 4].includes(parseInt(feature.profiles.weight)))

    let mainFeatures: any[] = []
    let secondayFeatures: any[] = []

    if (shield.type === 'SIMPLE') {
      mainFeatures = mostHighFeatures.filter((feature: any) => feature.parent_id === shield.id)
      secondayFeatures = mostHighFeatures.filter((feature: any) => feature.parent_id !== shield.id)
    } else {
      mainFeatures = features.filter((feature: any) => needs.includes(feature.type) && parseInt(feature.profiles.weight) === 4)
      secondayFeatures = features.filter((feature: any) => parseInt(feature.profiles.weight) === 3)
    }

    mainFeatures = mainFeatures.map((verb: any, index: number) => ({
      name: verb.name,
      star: STAR_CHART_URL.find(star => star.includes(verb.parent_id)),
      position: shield.positionsHigh[index]
    }))

    secondayFeatures = secondayFeatures.map((verb: any, index: number) => ({
      name: verb.name,
      star: STAR_CHART_URL.find(star => star.includes(verb.parent_id)),
      position: shield.positionsLow[index]
    })).slice(0, 6)

    const verbs = [...mainFeatures, ...secondayFeatures]

    return verbs
  }

  const getNouns = () => {
    if (!record) return []

    const { features } = record

    const reactionLoveNouns:any[] = features.filter((feature: any) => feature.type === 'nouns' && feature.parent_id && feature.profiles.reaction === REACTIONS.LOVE)
    const reactionLikeNouns:any[] = features.filter((feature: any) => feature.type === 'nouns' && feature.parent_id && feature.profiles.reaction === REACTIONS.LIKE)

    let nouns = [...reactionLoveNouns, ...reactionLikeNouns]

    const positions: string[] = [
      'top-[220px] left-[206px]',
      'top-[220px] right-[206px]',
      'top-[285px] left-[136px]',
      'top-[285px] right-[136px]',
      'top-[336px] left-[254px]',
      'top-[186px] left-[165px]',
      'top-[186px] right-[165px]',
      'top-[150px] left-[125px]',
      'top-[150px] right-[125px]',
      'top-[125px] left-[252px]',
      'top-[85px] left-[196px]',
      'top-[85px] right-[196px]',
      'top-[54px] left-[253px]',
      '-top-[2px] left-[253px]',
      'top-[370px] left-[215px]',
      'top-[370px] right-[215px]',
      'top-[425px] left-[254px]',
      'top-[194px] left-[102px]',
      'top-[194px] right-[102px]',
      'top-[295px] left-[88px]',
      'top-[296px] right-[84px]',
      'top-[110px] left-[90px]',
      'top-[110px] right-[90px]',
      'top-[85px] left-[142px]',
      'top-[85px] right-[142px]',
      'top-[218px] left-[38px]',
      'top-[218px] right-[38px]',
      'top-[325px] left-[38px]',
      'top-[325px] right-[38px]',
      'top-[214px] -left-[40px]',
      'top-[214px] -right-[40px]'
    ]

    nouns = nouns.map((noun: any, index: number) => ({
      name: noun.name,
      star: STAR_CHART_URL.find(star => star.includes(noun.parent_id)),
      position: positions[index]
    }))

    return nouns
  }

  const getMessageData = async () => {
    if (!tutorialStatus && !tutorialMenuStatus) {
      setMessage('¿A dónde vas?')
      /* getAllReferencesByRecord()
        .then(data => {
          if (data.status) {
            setHasFavourites(data.careerIdList.length > 0)
          }
        }) */
    }
  }

  const onViewDetailVocationalConstelation = () => {
    setRecord({
      ...record,
      last_page: PAGES.DASHBOARD_HOME
    })
    navigate(`/${url}${PAGES.RESULT.VOCATIONAL}`)
  }

  const onViewDetailNounConstelation = () => {
    setRecord({
      ...record,
      last_page: PAGES.DASHBOARD_HOME
    })
    navigate(`/${url}${PAGES.RESULT.NOUNS}`)
  }

  useEffect(() => {
    if (!tutorialStatus && tutorialMenuStatus) {
      setMessage(messages[currentStep])
      if (currentStep === 1) {
        setCurrentMenu(1)
      }
      if (currentStep === 2) {
        setCurrentMenu(2)
      }
      if (currentStep === 3) {
        setCurrentMenu(3)
      }
      if (currentStep === 4) {
        setCurrentMenu(4)
      }
      if (currentStep === 5) {
        setCurrentMenu(5)
      }
      if (currentStep === 6) {
        setCurrentMenu(0)
        setDemoMenuStatus(false)
        setUser({ ...user, demo_menu: false })
      }
    } else if (tutorialStatus) {
      setMessage('Ahora que has podido mirarte, te mostraré tus carreras candidatas.')
      if (isMobile()) {
        setTimeout(() => {
          setChargeAnimation(true)
        }, 3000);
      }
    } else if (!tutorialMenuStatus && !tutorialStatus) {
      setMessage('¿A dónde vas?')
    }
  }, [currentStep])

  useEffect(() => {
    // RESET DEMO
    // setTutorialStatus(false)
    // setDemoCareerStatus(true)
    // setFavouriteStatus(false)
    // setDemoMenuStatus(false)
    // setRecord({
    //   ...record,
    //   demo: true,
    //   favourite_courses: false
    // })
    // setUser({
    //   ...user,
    //   demo_menu: true,
    //   demo_career: true,
    //   demo_mesh: true
    // })

    getDataShield()
    getMessageData()
  }, [])

  return (
        <article className={isMobile() ? `bg-oldwoman-tutorial ${chargeAnimation ? 'before:opacity-0' : 'before:opacity-100'}` : 'bg-oldwoman'}>
            <section className="w-screen h-screen bg-main-alt cloud-page relative py-4">
                {
                  (chargeAnimation || !isMobile() || (isMobile() && !tutorialMenuStatus)) &&
                  <DashboardMenu
                    currentPage={currentMenu}
                    type='SIMPLE'
                    isTutorial={!tutorialStatus ? tutorialMenuStatus : tutorialStatus}
                    step={currentMenu}
                  />
                }
                <div className="gradient-purple" />
                {
                    !tutorialStatus && tutorialMenuStatus && (currentStep < totalSteps) &&
                    <ButtonRedirect
                        action="next"
                        text="next"
                        style="simple"
                        position="right"
                        linkReturn={ PAGES.DASHBOARD_HOME }
                        callback={ currentStep === totalSteps ? undefined : () => setCurrentStep(currentStep + 1) }
                    />
                }
                <AppMessage
                    index={0}
                    useAnimationLoading={false}
                    size="w-1/2 max-w-[640px] h-auto"
                    message ={message}
                />
                {
                    shield && !isMobile() &&
                    <div className="fixed w-1/2 h-full flex z-[99] transition-all ease-out duration-400 left-0 top-0 bottom-0 m-auto">
                        <div onClick={ onViewDetailVocationalConstelation } className={`cursor-pointer transition-all ease-out duration-400 max-[1749px]:scale-50 min-[1750px]:scale-[.6] opacity-50 ${tutorialStatus || tutorialMenuStatus ? 'pointer-events-none' : 'max-[1749px]:hover:scale-[.6] min-[1750px]:hover:scale-75 hover:opacity-100'}`}>
                            <ConstelationChart size={shield.sizeChart} chart={shield.chart} features={getVerbs()} />
                        </div>
                    </div>
                }
                {
                  !isMobile() &&
                    <div className="fixed w-1/2 h-full flex justify-end z-[99] transition-all ease-out duration-400 right-0 top-0 bottom-0 m-auto">
                        <div onClick={ onViewDetailNounConstelation } className={`cursor-pointer transition-all ease-out duration-400 max-[1749px]:scale-50 min-[1750px]:scale-[.6] opacity-50 ${tutorialStatus || tutorialMenuStatus ? 'pointer-events-none' : 'max-[1749px]:hover:scale-[.6] min-[1750px]:hover:scale-75 hover:opacity-100'}`}>
                            <ConstelationChart chart={constelationNoun} features={getNouns()} shadowText />
                        </div>
                    </div>
                  }
            </section>
        </article>
  )
}

export default Dashboard

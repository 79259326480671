import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import Joyride from 'react-joyride'
import Slider from 'react-slick'

// Services
import { getFeatureById } from '../../../services/apis/feature'
import { createProfilesByList } from '../../../services/apis/profile'
import { updateRecord } from '../../../services/apis/record'

// Methods
import { getRecord } from '../../../common/methods/storage'

// Components
import { ArrowLeft, ArrowRight } from '../../../components/arrows/SliderArrow'
import ReactionTooltip from '../../../components/intro/ReactionTooltip'
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import DisciplineCard from '../../../components/shared/DisciplineCard'

// Constants
import OptionButton from '../../../components/shared/OptionButton'
import TitleMessage from '../../../components/shared/TitleMessage'
import { PAGES } from '../../../constants/Pages'
import { ARROWS_NOUN_URL, CONSTELATION_URL } from '../../../constants/cards'
import { NOUNS } from '../../../constants/lists/nouns'
import { REACTIONS } from '../../../constants/reactions'
import { REACTION_1, REACTION_2, REACTION_3, REACTION_4 } from '../../../constants/tutorial'

const DisciplinesItems = () => {
  const { token } = useParams()

  const record = getRecord()

  const sliderRef = useRef<any>(null)

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [selectedList, setSelectedList] = useState<any[]>([])
  const [children, setChildren] = useState<any[]>([])

  const [constelation, setConstelation] = useState<string>('')
  const [next, setNext] = useState<string>('')
  const [last, setLast] = useState<any>(null)
  const [index, setIndex] = useState<number>(-1)

  const [arrows, setArrows] = useState<{right: string, left: string} | null>(null)
  const [currentIndex, setCurrentIndex] = useState<number>(1)

  const [viewTutorial, setViewTutorial] = useState<boolean>(false)
  const [steps, setSteps] = useState<any>(null)

  const CARD_COLORS = ['orange', 'red', 'blue', 'green', 'light-blue']

  const settings = {
    className: 'slick-center-slider',
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
    draggable: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          variableWidth: false
        }
      }
    ],
    afterChange: (index: number) => {
      setCurrentIndex(index + 1)
    }
  }

  const getFeature = async () => {
    if (!token) return

    await getFeatureById(token)
      .then(data => {
        if (data.status) {
          const { feature, children } = data.data
          const _index = NOUNS.findIndex(noun => noun.id === token)

          const color = CARD_COLORS[_index] as 'orange' | 'blue' | 'green' | 'red' | 'light-blue'

          const _url = CONSTELATION_URL.find(url => url.includes(feature.id))
          const _arrowsURL = ARROWS_NOUN_URL[color] as { left: string, right: string }

          const _children = children.map((child: any) => ({ ...child, color: feature.color }))

          setChildren(_children)
          setIndex(_index)

          if (_url) setConstelation(_url)
          if (_arrowsURL) setArrows(_arrowsURL)

          if (_index < 4) {
            const nextNoun = NOUNS[_index + 1]
            if (nextNoun) setNext(`${PAGES.DISCIPLINES}${nextNoun.id}`)
          } else {
            setNext(PAGES.CONVERSATION_3)
          }
        }
      })
  }

  const getDisciplinesItems = () => {
    if (!record || !token) return

    const { features } = record

    let disciplines = features.filter((fet: any) => fet.type === 'nouns' && fet.parent_id === token)

    disciplines = disciplines.map((discipline: any) => ({
      ...discipline,
      feature: discipline,
      reaction: discipline.profiles.reaction,
      feature_id: discipline.id,
      weight: null,
      selected: null
    }))

    setSelectedList([...disciplines])
  }

  const onChangeSelectedList = (data: any, reaction: string | null) => {
    if (!reaction) return

    let stepReaction : any = null

    const selected_list = selectedList
    const confirmTutorial = isFirst(reaction)

    const data_to_save = { ...data, feature: data, reaction, weight: null, feature_id: data.id }

    const _index = selected_list.findIndex(sl => sl.id === data.id)

    if (_index === -1) selected_list.push(data_to_save)
    else selected_list.splice(_index, 1, data_to_save)

    if (reaction === REACTIONS.LOVE) stepReaction = REACTION_1
    else if (reaction === REACTIONS.LIKE) stepReaction = REACTION_2
    else if (reaction === REACTIONS.DUDE) stepReaction = REACTION_3
    else if (reaction === REACTIONS.DISLIKE) stepReaction = REACTION_4

    setSteps([{
      target: stepReaction[0].target,
      title: stepReaction[0].title,
      content: `${data.name}${stepReaction[0].content}`,
      disableBeacon: true
    }])

    setLast(data)
    setViewTutorial(confirmTutorial)
    setSelectedList([...selected_list])

    setTimeout(() => {
      handleNextClick()
    }, 100)
  }

  const isFirst = (reaction: string | null): boolean => {
    if (!record || !reaction) return false

    const _recordList = record.features.filter((feature: any) => feature.profiles.reaction === reaction && feature.type === 'nouns' && feature.parent_id)
    const _selectedList = selectedList.filter((feature: any) => feature.reaction === reaction)

    return !_recordList.length && !_selectedList.length
  }

  const getFeatureSelected = () => selectedList.filter(sl => sl.reaction !== null).length
  const getReaction = (data: any) => selectedList.find(sl => sl.id === data.id)?.reaction ?? null

  const handleNextClick = () => {
    if (sliderRef.current && currentIndex < children.length) {
      sliderRef.current.slickNext()
    }
  }

  const onGoToNextStep = async () => {
    await createProfilesByList(selectedList)
      .then(async (data) => {
        if (data.status) await updateRecord({ step: 8 + index })
      })
  }

  useEffect(() => {
    setIsLoading(true)
    getFeature()
    setSelectedList([])
    setCurrentIndex(1)

    setTimeout(() => {
      setIsLoading(false)
      getDisciplinesItems()
    }, 700)
  }, [token])

  const handleCallback = (data: any) => {
    const { action, status } = data

    if (status === 'skipped') {
      const selected_list = selectedList

      const index = selected_list.findIndex((fet: any) => fet.id === last.id)
      if (index !== -1) selected_list.splice(index, 1)

      setSelectedList([...selected_list])
      setViewTutorial(false)
    }

    if (action === 'next') setViewTutorial(false)
  }

  return (
    <article className="bg-main" id="home">
      { viewTutorial && steps !== null &&
        <Joyride
          callback={handleCallback}
          steps={steps}
          continuous
          hideCloseButton
          disableOverlayClose
          disableScrolling
          showSkipButton={false}
          stepIndex={0}
          run={true}
          tooltipComponent={ReactionTooltip}
          styles={{
            options: {
              arrowColor: 'transparent',
              overlayColor: 'rgba(0, 0, 12, 0.8)'
            }
          }}
      />
      }
      <section className="w-screen h-screen quiz-screen-background bg-feature py-4">
        <div className="fixed w-full h-full flex items-start justify-center opacity-20">
          <img className="h-[115%] aspect-square object-fill" src={constelation} alt="constelation"></img>
        </div>
        <ButtonRedirect action="return" linkReturn={`/disciplines/${token}`} text="return" />
        { getFeatureSelected() === children.length && <ButtonRedirect action="next" callback={onGoToNextStep} linkReturn={next} text="next" /> }
        <BadgeStep currentStep={8 + index} />
        <TitleMessage
          index={0}
          useLoading={false}
          title=""
          message="¿Te gustaría que este sustantivo esté en tu futuro profesional?"
          size="w-1/2 max-w-[800px] h-auto pl-[10%]"
        />

        <div className="w-full h-[calc(100%-130px)] content-background relative">
          {
            isLoading
              ? <></>
              : <div className="w-full h-full flex flex-col relative py-8 animation-charge z-[200] justify-start">
                <div className="discipline-slider h-full relative rounded-[20px]">
                    <Slider
                      {...settings}
                      ref={sliderRef}
                      prevArrow={
                        <ArrowLeft
                          arrow={arrows?.left}
                          width="w-[50px!important]"
                          height="h-[50px!important]"
                        />
                      }
                      nextArrow={
                        <ArrowRight
                          arrow={arrows?.right}
                          onClick={handleNextClick}
                          width="w-[50px!important]"
                          height="h-[50px!important]"
                          count={children.length}
                          canShow={!!getReaction(children[currentIndex - 1])}
                        />
                      }
                    >
                      {
                        children.map((item: any) => {
                          return (
                            <DisciplineCard
                                key={item.id}
                                discipline={item}
                                state={getReaction(item)}
                                isItem={true}
                            >
                              <div className="slick-selector w-fit max-h-[262px] min-h-[262px] flex gap-2 justify-center z-[420] max-[480px]:grid-cols-[124px_54px_124px] max-[480px]:justify-items-center">
                                  <OptionButton
                                      url="DISLIKE"
                                      useIcon={false}
                                      type="FINGER"
                                      width='w-[155px] max-[480px]:w-[124px] xl:w-[241px]'
                                      text="No me gustaría"
                                      isSelected={getReaction(item) === REACTIONS.DISLIKE }
                                      callback={() => onChangeSelectedList(item, REACTIONS.DISLIKE)}
                                  />
                                  <OptionButton
                                      url="N/A"
                                      width='w-[155px] max-[480px]:w-[124px] xl:w-[241px]'
                                      useIcon={false}
                                      isSelected={getReaction(item) === REACTIONS.DUDE }
                                      callback={() => onChangeSelectedList(item, REACTIONS.DUDE)}
                                  />
                                  <OptionButton
                                      url="LOVE"
                                      width='w-[155px] max-[480px]:w-[124px] xl:w-[241px]'
                                      useIcon={false}
                                      type="FINGER"
                                      text="Me gustaría"
                                      isSelected={getReaction(item) === REACTIONS.LOVE }
                                      callback={() => onChangeSelectedList(item, REACTIONS.LOVE)}
                                  />
                              </div>
                            </DisciplineCard>
                          )
                        })
                      }
                    </Slider>
                  </div>
              </div>
          }
        </div>
      </section>
    </article>
  )
}

export default DisciplinesItems

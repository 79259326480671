export const REACTIONS = {
  DISLIKE: 'DISLIKE',
  DUDE: 'N/A',
  LIKE: 'LIKE',
  LOVE: 'LOVE'
}

export const REACTIONS_DATA = {
  0: {
    CODE: 'DISLIKE',
    TITLE: 'No me gusta',
    ALTERNATIVE_TITLE: 'Con dificultad'
  },
  1: {
    CODE: 'N/A',
    TITLE: 'Me da igual',
    ALTERNATIVE_TITLE: 'Sin problemas'
  },
  2: {
    CODE: 'LIKE',
    TITLE: 'Me gusta',
    ALTERNATIVE_TITLE: 'Destacado'
  },
  3: {
    CODE: 'LOVE',
    TITLE: '¡Me encanta!',
    ALTERNATIVE_TITLE: 'Brillante'
  }
}

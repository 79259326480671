import { getRecord } from '../../common/methods/storage'
import { API_ROUTES } from '../../constants/api_routes'
import { headers } from '../../constants/connection'

interface CareersApiProps {
    page?: number,
    size?: number,
    order?: boolean,
    gestion?: string,
    level?: string,
    graduate?: boolean,
    profiles?: string[],
    noun?: string | null,
    classification?: string | null,
    selected?: boolean,
    name?: string,
    signal?: AbortSignal
}

export const getCareerById = async (id: string) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api/careers/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    }
  })
    .then((response) => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

export const getCareerByIdWithClassification = async (id: string, recordId: string) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api/careers/${id}/getWithClassification/${recordId}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    }
  })
    .then((response) => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

export const getAllCareers = async ({ name, page, size, signal } : { name: string, page: number, size: number, signal?: AbortSignal }) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.CAREER.GET.ALL}?name=${name}&page=${page}&size=${size}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    },
    signal
  })
    .then((response) => response.json())
    .then(data => {
      return data
    })
    .catch(() => {
      return false
    })
}

export const getAllCareersWithClassification = async ({ page = 0, size = 16, order = false, gestion = 'ALL', level = 'ALL', graduate = false, profiles = [], signal, noun = null, classification, selected = false, name = '' }: CareersApiProps) => {
  const record = getRecord()
  if (record) {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.CAREER.GET.WITH.CLASSIFICATION}${record.id}?page=${page}&size=${size}&order=${order}&gestion=${gestion}&level=${level}&graduate=${graduate}&selected=${selected}&name=${name}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      signal
    })
      .then((response) => response.json())
      .then(data => {
        return data
      })
      .catch(() => {
        return false
      })
  }
}

export const getAllCareersWithReferences = async () => {
  const record = getRecord()
  if (record) {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.CAREER.GET.WITH.REFERENCES}${record.id}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      }
    })
      .then((response) => response.json())
      .then(data => {
        return data
      })
      .catch(() => {
        return false
      })
  }
}

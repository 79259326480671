export const NOUNS = [
  {
    id: 'f2b3b2e0-90a5-11ed-948b-834ce149ae0d',
    group: 'art',
    color: '#FFD300',
    background: '#4F4229',
    title: 'Artes',
    description: 'Estos es todo lo relaciona a el lado artistico de la humanidad'
  },
  {
    id: '01d6e740-90a8-11ed-948b-834ce149ae0d',
    group: 'machine',
    color: '#FC015E',
    background: '#510449',
    title: 'Máquinas',
    description: 'Estos es todo lo relaciona a el lado artistico de la humanidad'
  },
  {
    id: '2b7f4560-90a8-11ed-948b-834ce149ae0d',
    group: 'human',
    color: '#1CC9F1',
    background: '#063C69',
    title: 'Ser Humano',
    description: 'Estos es todo lo relaciona a el lado artistico de la humanidad'
  },
  {
    id: '59155870-90a8-11ed-948b-834ce149ae0d',
    group: 'nature',
    color: '#19DACA',
    background: '#043551',
    title: 'Naturaleza',
    description: 'Estos es todo lo relaciona a el lado artistico de la humanidad'
  },
  {
    id: '748608c0-90a8-11ed-948b-834ce149ae0d',
    group: 'other',
    color: '#FF009E',
    background: '#510449',
    title: 'Otros',
    description: 'Estos es todo lo relaciona a el lado artistico de la humanidad'
  }
]

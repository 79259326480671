import { getRecord } from '../../common/methods/storage'
import { API_ROUTES } from '../../constants/api_routes'
import { headers } from '../../constants/connection'

interface CourseLikePros {
    id: string,
    course?: any,
    course_id: string,
    reaction: 'LIKE' | 'DISLIKE' | 'N/A' | 'LIKE' | null,
}

export const createCourseLikeByList = async (CourseLikeList: CourseLikePros[], mesh_id: string) => {
  const record = getRecord()

  if (record) {
    const data = {
      course_list: CourseLikeList.map(co => ({ course_id: co.course_id, reaction: co.reaction })),
      record_id: record.id,
      mesh_id
    }

    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.COURSE_LIKE.CREATE.BY.LIST}`, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then(data => {
        return data
      })
      .catch(() => {
        return false
      })
  }
}

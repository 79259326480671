import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

// Services
import { CAREER_CLASSIFICATION, CAREER_CLASSIFICATION_COLOR, CAREER_CLASSIFICATION_LABEL } from '../../constants/classification'
import { createReference, deleteReference } from '../../services/apis/career_reference'

// Methods
import { getWorkspaceParams } from '../../common/methods/params'
import { getUser } from '../../common/methods/storage'

// Assets
import bgViejaToxic from '../../assets/images/bubble-vieja-destino-warning.svg'
import btnSmallLight from '../../assets/images/buttons/btn-small-light.svg'
import btnSmall from '../../assets/images/buttons/btn-small.svg'
import bgCareerActiveMobile from '../../assets/images/cards/bg-card-active-mobile.svg'
import bgCareerActive from '../../assets/images/cards/bg-card-active.svg'
import bgCareerMobile from '../../assets/images/cards/bg-card-mobile.svg'
import bgCareer from '../../assets/images/cards/bg-card.svg'
import iconClose from '../../assets/images/icons/icon-close.svg'
import iconStarBigActive from '../../assets/images/icons/icon-star-big-active.svg'
import iconStarBigDemoActive from '../../assets/images/icons/icon-star-big-demo-active.svg'
import iconStarBigDemo from '../../assets/images/icons/icon-star-big-demo.svg'
import iconStarBig from '../../assets/images/icons/icon-star-big.svg'
import { isMobile } from '../../common/methods/devices'

interface CardProps {
    id: string,
    career_reference_id: string | null,
    name: string,
    description?: string,
    HasInstitution?: string,
    type: string,
    size?: string,
    percent?: string,
    profiles?: string[],
    currentPage?: string,
    classification?: string,
    weight?: string,
    onFavorited?: boolean,
    onHovered?: boolean,
    onSelected?: (id: string, favourite: string) => void,
    onDeselected?: (id: string) => void
}

const CardCareer = ({ id, HasInstitution, career_reference_id, name, description, type, size, classification, onSelected, onDeselected, onHovered, onFavorited, profiles = [], percent, weight }: CardProps) => {
  const { url } = getWorkspaceParams()

  const user = getUser()

  const bgCardActive = isMobile() ? bgCareerActiveMobile : bgCareerActive
  const bgCardNoActive = isMobile() ? bgCareerMobile : bgCareer

  const [_favourite, setFavourite] = useState<boolean>(career_reference_id !== null)
  const [_careerReferenceId, setCareerReferenceId] = useState<string | null>(null)
  const [classificationCareer, setClassificationCareer] = useState<any>(null)

  const toxicPopup = useRef<any>(null)
  const [showToxicPopup, setShowToxicPopup] = useState<boolean>(false)
  const [hovered, setHovered] = useState<boolean>(false)

  useEffect(() => {
    setFavourite(career_reference_id !== null)
    setCareerReferenceId(career_reference_id)
    getDataClassification()
    getToxicPopup()
  }, [id])

  const getDataClassification = () => {
    if (!classification) return

    const classificationType = classification as 'OPTIMA' | 'IDEAL' | 'DEFIANT' | 'AMBIGUOUS' | 'COMPLEMENT'
    const type = classificationType
    const label = CAREER_CLASSIFICATION_LABEL[classificationType]
    const color = CAREER_CLASSIFICATION_COLOR[classificationType]

    setClassificationCareer({ label, color, type })
  }

  const getToxicPopup = () => {
    if (classification === CAREER_CLASSIFICATION.DEFIANT && profiles.length > 0) {
      const textReplace: string[] = profiles.map((feature: any) => feature.name)

      const lastFeatureToxic = textReplace.pop()

      const textToReplace = (textReplace.length > 0 ? (textReplace.slice(0, textReplace.length).join(', ') + ' y ') : '') + lastFeatureToxic

      return textToReplace
    }
  }

  const getMainSize = () => {
    if (size === 'sm') return 'w-[180px] h-[168px]'
    else if (size === 'xl') return 'w-[208px] h-[250px]'
    return 'w-[197px] h-[194px]'
  }

  const getBodySize = () => {
    if (size === 'sm') return 'w-[180px]'
    else if (size === 'xl') return 'w-[210px]'
    return 'w-[197px]'
  }

  const handleFavourite = async () => {
    if (_favourite && _careerReferenceId !== null) {
      await deleteReference(_careerReferenceId)
        .then(data => {
          if (data.status && _careerReferenceId) {
            setFavourite(false)
            setCareerReferenceId(null)
            if (onDeselected) onDeselected(id)
          }
        })
    } else {
      await createReference(id)
        .then(data => {
          if (data.status) {
            career_reference_id = data.data.id
            setCareerReferenceId(data.data.id)
            setFavourite(true)
            if (onSelected && career_reference_id) onSelected(id, career_reference_id)
          }
        })
    }
  }

  return (
        <>
            <div
              key={id}
              className={`${'card-career tutorial-career career-' + classificationCareer?.type?.toLowerCase()} ${classification === 'OPTIMA' || classification === 'IDEAL' || classification === 'COMPLEMENT' ? 'career-success' : ''} relative ${getMainSize()} transition-all ease-in-out duration-300 bg-cover cursor-pointer ${showToxicPopup ? 'after:fixed after:w-screen after:h-screen after:bg-[#0D0416]/[.75] after:top-0 after:bottom-0 after:right-0 after:left-0 after:z-[25]' : ''} max-[480px]:w-[163px] max-[480px]:h-[129px]`}>
                <button
                  className={`w-full h-full relative grid justify-items-center ${showToxicPopup ? 'z-[51]' : ''}`}
                  style={{ backgroundImage: `url(${hovered || career_reference_id ? bgCardActive : bgCardNoActive})` }}
                  onMouseEnter={() => {
                    if (!isMobile()) setHovered(true)
                  }}
                  onMouseLeave={() => {
                    if (!isMobile()) setHovered(false)
                  }}>
                    <Link to={`/${url}/career/${id}`} className={`w-full ${getBodySize()} pt-[20px] pl-[10px] pr-[15px] pb-0 max-[480px]:w-[160px] max-[480px]:pt-2.5 max-[480px]:px-0 max-[480px]:pb-0 max-[480px]:h-[78px]`}>
                        <button
                          onClick={classificationCareer?.type === CAREER_CLASSIFICATION.DEFIANT ? (e) => { setShowToxicPopup(!showToxicPopup); e.preventDefault() } : undefined}
                          className='w-[81px] h-[14px] block rounded-xl text-center text-white ff-cp--bold text-[8px] ml-[15px] mb-[11px] max-[480px]:mb-2 max-[480px]:text-[7.5px] max-[480px]:leading-[12px] max-[480px]:h-3 max-[480px]:w-[77px]'
                            style={{ backgroundColor: classificationCareer?.color }}>
                            { classificationCareer?.label }
                        </button>
                        <h3
                          className={`ff-cp--bold text-left ${type === 'hidden' ? 'h-fit' : 'h-[128px]'}  ${type === 'hidden' ? 'text-[20px]' : 'text-[14px]'} pl-[15px] pr-[40px] text-[#ffffff] mb-1 leading-none overflow-hidden text-clip whitespace-pre-wrap break-words max-[480px]:text-[11px] max-[480px]:h-[40px] max-[480px]:pr-2.5 max-[480px]:text-left`}>
                            {name}
                        </h3>
                        {
                          type === 'complex' &&
                          <p className="text-[8.5px] text-white pr-[25px] mb-1 leading-tight">
                            {description}
                          </p>
                        }
                    </Link>
                    { onFavorited
                      ? (
                      <button
                        className="absolute top-[3px] right-[6px] z-10 center w-[60px] h-[60px] max-[480px]:top-[9px] max-[480px]:right-[15px] max-[480px]:h-5 max-[480px]:w-5">
                        <img
                          alt=""
                          src={ career_reference_id ? iconStarBigDemoActive : iconStarBigDemo } className="favorite-step w-[60px] h-[60px] opacity-100 max-[480px]:h-5 max-[480px]:w-5"
                        />
                      </button>
                        )
                      : (
                      <button
                        onClick={handleFavourite}
                        className="absolute top-[19px] right-[23px] z-10 center w-[24px] h-[24px] max-[480px]:top-[9px] max-[480px]:right-[15px] max-[480px]:h-5 max-[480px]:w-5">
                        <img
                          alt=""
                          src={ _favourite ? iconStarBigActive : iconStarBig}
                          className={`favorite-step w-[27px] h-[27px] ${_favourite ? 'opacity-80 hover:opacity-100' : 'opacity-70 hover:opacity-100'} max-[480px]:h-5 max-[480px]:w-5`}
                        />
                      </button>
                        )}

                    { !!HasInstitution && (
                      <div className='absolute bottom-[65px] w-full px-[15px] max-[480px]:bottom-[15px]'>
                        <div className="w-full flex items-center justify-center gap-x-1 px-[7px] pt-[10px] border-t border-t-[#42324E] max-[480px]:border-none max-[480px]:pt-1 max-[480px]:px-0">
                            {
                                !!user.workspace?.logo &&
                                <div className="min-w-[45px] h-[45px] flex items-center justify-center max-[480px]:min-w-[30px] max-[480px]:h-[30px]">
                                  <img className="w-[36px] h-[36px] max-[480px]:w-[30px] max-[480px]:h-[30px] max-[480px]:object-contain" src={user.workspace.logo} alt="" />
                                </div>
                            }
                            <span className="text-[9px] text-white leading-[10px] max-[480px]:text-[8px] max-[480px]:leading-[9px] max-[480px]:text-left">
                              {HasInstitution}
                            </span>
                        </div>
                      </div>
                    )}
                    { type === 'complex' && !isMobile() &&
                        <div className="relative z-10 w-full grid place-content-end justify-center pb-[20px] gap-2 px-[18px] mb-0">
                            <Link to={`/${url}/career/${id}`} className={`instruction-step ${onHovered ? 'w-[190px] h-[65px] left-[-10px] relative' : 'w-[162px] h-[39px]'} text-white text-[10px] text-center flex justify-center items-center leading-[34px] bg-cover bg-center opacity-70 hover:opacity-100`} style={{ backgroundImage: `url(${onHovered ? btnSmallLight : btnSmall})` }}>Ver instituciones</Link>
                        </div>
                    }
                </button>
            </div>
            {
                showToxicPopup &&
                <div ref={toxicPopup} className="fixed bottom-[120px] w-full flex items-center justify-center left-0 right-0 m-auto z-[52]">
                    <div
                    className="relative bg-cover pt-[14px] pr-[50px] pb-[20px] pl-[185px] flex items-center w-[743px] h-[174px]"
                    style={{ backgroundImage: `url(${bgViejaToxic})` }}
                    >
                        <button onClick={() => setShowToxicPopup(false)} className="absolute top-[0] right-[-20px] w-[55px] h-[55px] z-[10]" style={{ backgroundImage: `url(${iconClose})` }}></button>
                        <div className="text-white text-[18px] leading-none tracking-tight text-center">
                            <label className="font-light">Esta carrera </label>
                            <label className="font-extrabold">{name} </label>
                            <label className="font-light">tiene estos elementos que me has dicho que no te gustan: </label>
                            <label className="font-extrabold underline">{getToxicPopup()}</label>
                            <label className="font-light">. Mírala con cuidado antes de tomar una decisión.</label>
                        </div>
                    </div>
                    <div className="flex justify-center gap-[40px] items-end">
                    </div>
                </div>
            }
        </>
  )
}

export default CardCareer

import avatarDefault from '../../assets/images/avatar-default.svg'
import logo from '../../assets/images/logos/logo.svg'
import { isMobile } from '../../common/methods/devices'
import { getUser } from '../../common/methods/storage'

interface BadgeProps {
  currentStep?: number,
  type?: string,
  bubbleSize?: 'small' | 'big'
}

const BadgeStep = ({ currentStep = 0, type = 'step', bubbleSize = 'small' }: BadgeProps) => {
  const user = getUser()

  const redirect = () => {
    window.open('https://www.movetuvocacion.com/', '_blank')
  }

  return (
    <>

        {
          type === 'step'
            ? (
                !isMobile() &&
                <div className="fixed w-[50px] h-[50px] xl:w-[65px] xl:h-[65px] 2xl:w-[55px] 2xl:h-[55px] grid place-content-center top-[34px] right-[44px] z-[100]">
                  <div className="relative w-[50px] h-[50px] xl:w-[65px] xl:h-[65px] 2xl:w-[55px] 2xl:h-[55px] grid place-content-center ff-cp--bold text-white text-xs xl:text-base 2xl:text-sm circle">
                    {currentStep}/26
                  </div>
                </div>
              )
            : (
                !isMobile() &&
                <div className="fixed flex gap-x-2 top-[34px] right-[44px] z-[100]">
                  <div className="w-[36px] h-[36px] flex items-center justify-center rounded-full bg-[#070625] border border-[#FFFFFF] overflow-hidden">
                    <img src={avatarDefault} className="h-full w-full" alt="avatar" />
                  </div>
                  <p className="ff-cp--bold text-[20px] text-white whitespace-nowrap self-center">{(user?.name + ' ' + user?.last_name) || 'NN'}</p>
                </div>
              )
        }
      <div className={`fixed flex justify-center items-center bottom-[34px] left-[44px] max-[480px]:h-min max-[480px]:left-[calc((100dvw*.9)-20px)] ${bubbleSize === 'small' ? 'max-[480px]:top-[calc((100dvw*.2)-5px)]' : 'max-[480px]:top-[calc(100dvw*.2)]'} z-[1000] transition-all duration-300 hover:scale-110 cursor-pointer`}>
        <div
            onClick={redirect}
            className="relative w-[50px] h-[50px] xl:w-[65px] xl:h-[65px] 2xl:w-[55px] 2xl:h-[55px] grid place-content-center ff-cp--bold text-white text-xs circle">
          <img src={logo} className="w-[16px] h-[16px] xl:w-[25px] xl:h-[25px] 2xl:w-[25px] 2xl:h-[25px]" alt='logo'></img>
        </div>
        { (user.workspace?.logo && !isMobile()) && (
            <div className="relative top-0 -left-[4px] bg-[#000000] w-[48px] h-[48px] flex justify-center items-center border-2 border-white rounded-full after:top-[19px] after:absolute after:left-[-5.5px] after:w-[8px] after:h-[8px] after:bg-white after:rotate-45">
              <img src={user.workspace.logo} className="rounded-full"></img>
            </div>
        )}
      </div>
    </>
  )
}

export default BadgeStep

const Tooltip = ({ text = '', children = <div></div>, color = '#19DACA', backgroundColor = '#043551', direction = 'right', useIcon = false, extraClass = '', bottom = '-bottom-[25px]' }) => {
  return (
    <div className={`tooltip relative inline-block ${extraClass}`}>
        { useIcon && <div className={`grid place-content-center bg-[${backgroundColor}] rounded-full w-[14px] h-[14px] tooltip-icon content-center cursor-pointer ff-cp--bold text-white text-center text-[10px] leading-[10px] absolute bottom-[5px] left-[17px] p-[1px] hover:hidden`}><span>?</span></div> }
        <div className={`tooltip-text bg-[${backgroundColor}] text-[${color}] text-[12px] w-[140px] py-1 px-3 absolute ${direction === 'right' ? 'top-[20px] left-[120px]' : 'left-[20px] right-0 -bottom-[25px] text-center'} transform -translate-x-1/2 pointer-events-none z-50 ff-cp--semibold`}>
            <span className="z-20">{text}</span>
            <span className={`absolute bg-[${backgroundColor}] w-[8px] h-[8px] transform rotate-45 ${direction === 'right' ? 'top-[9px] -left-[3px]' : '-top-[4px] left-[70px]'} z-0`}></span>
        </div>
        {children}
    </div>
  )
}

export default Tooltip

import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Services
import { getReviewedResultMesh } from '../../../services/apis/mesh'

// Components
import DashboardMenu from '../../../components/shared/DarshboardMenu'

// Constants
import { ADMINISTRATION, EDUCATION_LEVEL } from '../../../constants/Administration'

// Methods
import { getWorkspaceParams } from '../../../common/methods/params'
import { getUser } from '../../../common/methods/storage'

// Images
import avatarDefault from '../../../assets/images/avatar-default.svg'
import btnViewMore from '../../../assets/images/buttons/btn-purple.svg'
import iconDoubleArrow from '../../../assets/images/icons/icon-double-arrow.svg'
import iconSearch from '../../../assets/images/icons/icon-search.svg'
import iconLoading from '../../../assets/images/icons/icon-spinner.svg'
import logoCareers from '../../../assets/images/logos/logo-careers.svg'
import { isMobile } from '../../../common/methods/devices'
import CardMesh from '../../../components/cards/CardMesh'
import TitleMessage from '../../../components/shared/TitleMessage'
import { PAGES } from '../../../constants/Pages'

const DashboardMesh = () => {
  const navigate = useNavigate()
  const { url } = getWorkspaceParams()

  const DEFAULT_SIZE = 20
  const user = getUser()

  const listInnerRef = useRef<any>()
  const gestionRef = useRef<any>(null)
  const levelRef = useRef<any>(null)

  const [currPage, setCurrPage] = useState<number>(0)
  const [prevPage, setPrevPage] = useState<number>(-1)
  const [lastList, setLastList] = useState<boolean>(false)

  const [showMeshes, setShowMeshes] = useState<any[]>([])

  const [administrationOptions, setAdministrationOptions] = useState<any>(ADMINISTRATION[0])
  const [levelOptions, setLevelOptions] = useState<any>(EDUCATION_LEVEL[0])

  const [search, setSearch] = useState<string>('')

  const [showLevelPopup, setShowLevelPopup] = useState<boolean>(false)
  const [showAdministrationPopup, setShowAdministrationPopup] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)
  const [chargeMeshs, setChargeMeshs] = useState<boolean>(false)

  const setValues = (value: any, setter: React.Dispatch<React.SetStateAction<any>>) => {
    setter(value)
    setChargeMeshs(true)
  }

  const getMeshs = (signal?: AbortSignal) => {
    if (!lastList && prevPage !== currPage && chargeMeshs) {
      getReviewedResultMesh({
        signal,
        page: currPage,
        size: DEFAULT_SIZE,
        gestion: administrationOptions.code,
        level: levelOptions.code,
        name: search
      })
        .then((data: any) => {
          if (data.status) {
            if (!data.meshs.length) {
              setLastList(true)
            } else {
              setPrevPage(currPage - 1)
            }
            setShowMeshes([...(currPage === 0 ? [] : showMeshes), ...data.meshs])
            setLoading(false)
            setChargeMeshs(false)
          }
        })
    }
  }

  const handleSearch = (event: any) => {
    setTimeout(() => {
      setValues(event.target.value, setSearch)
    }, 200)
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight >= (scrollHeight - 1) && !chargeMeshs) {
        setCurrPage(currPage + 1)
        setChargeMeshs(true)
      }
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (levelRef.current?.contains(event.target)) setShowLevelPopup(true)
      else setShowLevelPopup(false)
    }

    window.addEventListener('click', handleClickOutside)

    return () => window.removeEventListener('click', handleClickOutside)
  }, [levelRef])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (gestionRef.current?.contains(event.target)) setShowAdministrationPopup(true)
      else setShowAdministrationPopup(false)
    }

    window.addEventListener('click', handleClickOutside)

    return () => window.removeEventListener('click', handleClickOutside)
  }, [gestionRef])

  useEffect(() => {
    if (chargeMeshs) {
      const abortControllerRef = new AbortController()
      const signal = abortControllerRef.signal

      getMeshs(signal)

      return () => abortControllerRef.abort()
    }
  }, [chargeMeshs])

  useEffect(() => {
    setShowMeshes([])
    setLastList(false)
    setPrevPage(-1)
    setCurrPage(0)
    setChargeMeshs(true)
  }, [administrationOptions, levelOptions, search])

  return (
    <article id="loading" className='bg-main-alt'>
      <section className="w-screen h-screen quiz-screen-background flex flex-col pt-6 px-6 max-[480px]:pt-4 max-[480px]:pb-0 max-[480px]:px-0">
        <DashboardMenu currentPage={2} type='SIMPLE' logoPosition='TOP' />
        <div className="w-full h-[50px] flex justify-between max-[480px]:h-auto max-[480px]:mb-[10px] max-[480px]:px-3">
          <div className="flex items-center justify-between relative gap-x-4 max-[480px]:flex-col max-[480px]:gap-y-4 max-[480px]:w-full">
            <div className="mt-[2px] z-[10] w-[310px] h-[52px] dashboard-gradient bg-cover flex items-center justify-start gap-x-4 px-4 border border-t-[#fff] border-l-[#fff] border-r-[#fff] border-b-[#320a4e] rounded-tl-[30px] rounded-tr-[8px] max-[480px]:mt-0 max-[480px]:h-auto max-[480px]:border-none max-[480px]:w-full max-[480px]:justify-center max-[480px]:bg-none max-[480px]:px-3">
             {
              !isMobile() &&
              <div className="w-[30px] h-[30px] rounded-full border border-[#ffffff7a] flex items-center justify-center hover:scale-105 hover:opacity-100">
                <img src={logoCareers} alt="logo-careers" width="12" height="12" />
              </div>
             }
              <p className="text-white ff-cp--bold text-[20px] leading-none">Mallas revisadas</p>
            </div>
            <div className="relative flex items-center w-[200px] xl:w-[367px] top-[2px] max-[480px]:w-full">
              <input type="search" className="w-full z-10 text-white pl-[18px] text-[10px] h-[36px] bg-[#070625] rounded-full border border-solir border-white " placeholder="Escribe una carrera en mente..." onChange={handleSearch} />
              <button className="w-[26px] h-[26px] absolute top-[5px] right-[5px] z-10 bottom-[5px] bg-cover transition ease opacity-70 hover:opacity-100" style={{ backgroundImage: `url(${iconSearch})` }}></button>
            </div>
          </div>
          {
            !isMobile() &&
            <div className="flex items-center">
              <div className="w-[36px] h-[36px] rounded-full border-2 border-white overflow-hidden mr-2">
                <img src={avatarDefault} alt="" className="w-[36px] h-[36px]" />
              </div>
              <p className="ff-cp--bold text-[14px] xl:text-[20px] text-white whitespace-nowrap">
                {
                  user &&
                  user.name + ' ' + user.last_name
                }
              </p>
            </div>
          }
        </div>
        <div className="w-full h-[calc(100%-110px)] flex flex-wrap relative dashboard-gradient border-2 border-white/[.8] rounded-b-xl rounded-tl-none rounded-tr-[50px] p-6 max-[480px]:w-full max-[480px]:h-[calc(100%-90px)] max-[480px]:rounded-none max-[480px]:overflow-hidden max-[480px]:border-0 max-[480px]:p-0">
          <div className="w-full h-full">
            <div id="careers-step"
              ref={listInnerRef}
              onScroll={onScroll}
              className="careers-step w-full h-full overflow-y-auto overflow-x-hidden">
              {!loading && (
                showMeshes?.length > 0
                  ? (
                    <>
                      <div className="grid grid-cols-[repeat(2,210px)] md:grid-cols-[repeat(3,210px)] lg:grid-cols-[repeat(4,210px)] xl:grid-cols-[repeat(5,210px)] gap-[20px] mb-14 max-[480px]:w-[calc(100%-25px)] max-[480px]:mx-auto max-[480px]:border-white/[.8] max-[480px]:border-t-[2px] max-[480px]:pt-3 max-[480px]:grid-cols-[repeat(auto-fit,160px)] max-[480px]:gap-x-1">
                        {showMeshes.map((data: any) => (
                          <CardMesh
                            key={data.id}
                            mesh={data}
                            callback={() => navigate(`/${url}/institution/${data.id}`)}
                          />
                        ))}
                      </div>
                      <section className="w-full flex items-center justify-center mb-[15px]">
                        <div className="w-full text-right pt-6 pb-10 text-[#fff] text-[25px] ff-cp--bold  z-[1]">
                          <TitleMessage
                            index={0}
                            useLoading={false}
                            size="w-[700px] max-w-[80%] min-w-[420px_!important] h-auto pl-[12dvw] max-[700px]:pl-[100px] max-[700px]:pt-[18px] mt-[-10px] max-[480px]:min-w-[auto!important]"
                            title=""
                            message="¿Quieres revisar más carreras?"
                          />
                          <div className="flex gap-x-2 items-center justify-center">
                            <button
                              onClick={() => navigate(`/${url}${PAGES.CAREERS}`)}
                              style={{ backgroundImage: `url(${btnViewMore})` }}
                              className="flex w-[220px] h-[58px] gap-4 cursor-pointer ff-cg--bold leading-none opacity-80 text-[16px] items-center justify-center hover:opacity-100 bg-center bg-cover bg-no-repeat max-[480px]:text-[12px] max-[480px]:w-[179px] max-[480px]:h-[47px] max-[480px]:pt-1">
                              Si, llevame a ver mis<br />carreras
                            </button>
                          </div>
                        </div>
                      </section>
                    </>
                    )
                  : (
                    <section className="w-full h-full flex items-center justify-center">
                      <div className="w-full text-right pt-6 pb-10 text-[#fff] text-[25px] ff-cp--bold z-[1]">
                        <TitleMessage
                          index={0}
                          useLoading={false}
                          size="w-[900px] max-w-[100%] min-w-[420px_!important] h-auto pl-[12dvw] max-[700px]:pl-[115px] max-[700px]:pt-[24px] mt-[-10px]"
                          title=""
                          message="Para tomar una decisión es necesario que investigues algunas mallas.<br />Mira tus carreras candidatas"
                        />
                        <div className="flex gap-x-2 items-center justify-center">
                          <button
                            onClick={() => navigate(`/${url}${PAGES.CAREERS}`)}
                            style={{ backgroundImage: `url(${btnViewMore})` }}
                            className="flex w-[220px] h-[58px] gap-4 cursor-pointer ff-cg--bold leading-none opacity-80 text-[16px] items-center justify-center hover:opacity-100 bg-center bg-cover bg-no-repeat">
                            Ir a carreras <br /> candidatas
                          </button>
                        </div>
                      </div>
                    </section>
                    )
              )}
              {
                loading &&
                <div className="w-full h-full flex items-center justify-center">
                  <img src={iconLoading} alt="" className="w-[50px] h-[50px]" />
                </div>
              }

            </div>
          </div>
        </div>
        {
          !isMobile() &&
          <div className="w-full flex-1 min-h-[50px] flex items-center gap-x-8">
            <div className="w-1/2 h-full flex items-center justify-start gap-x-10 px-[45px] xl:px-28"></div>
            <div className="w-1/2 h-full flex items-center justify-end gap-x-10 px-[45px] xl:px-28">
              <div className="flex gap-x-1 xl:gap-x-3 relative">
                <div className="w-[81px] h-[32px]">
                  <p className="ff-m--regular text-[14px] leading-none text-white">Nivel educativo</p>
                </div>
                <button
                  ref={levelRef}
                  onClick={() => setShowLevelPopup(!showLevelPopup)}
                  className="w-[96px] h-[32px] bg-[#070625] border border-white cursor-pointer flex items-center justify-between rounded-3xl px-2.5">
                  <p className="col-span-8 ff-m--bold text-[10px] leading-none text-white">{levelOptions.name}</p>
                  <img src={iconDoubleArrow} alt="double-arrow" width="10" height="10" />
                </button>
                {
                  showLevelPopup &&
                  <div className="absolute bottom-9 right-0 z-10 bg-[#f9f9f9] w-[100px] h-fit rounded-lg py-2">
                    {
                      EDUCATION_LEVEL.map(level => {
                        return (
                          <button
                            key={level.id}
                            className="h-[25px] px-2 flex items-center cursor-pointer hover:bg-[#e7e7e7]"
                            onClick={() => {
                              setValues(level, setLevelOptions)
                              setShowLevelPopup(false)
                            }}
                          >
                            <p className="text-[#070625] text-[12px] leading-none">{level.name}</p>
                          </button>
                        )
                      })
                    }
                  </div>
                }

              </div>
              <div className="flex gap-x-2 relative">
                <div className="w-[81px] h-[32px]">
                  <p className="ff-m--regular text-[14px] leading-none text-white">Tipo de gestión</p>
                </div>
                <button
                  ref={gestionRef}
                  onClick={() => setShowAdministrationPopup(!showAdministrationPopup)}
                  className="w-[96px] h-[32px] bg-[#070625] border border-white cursor-pointer flex items-center justify-between rounded-3xl px-2.5">
                  <p className="col-span-8 ff-m--bold text-[10px] leading-none text-white">{administrationOptions.name}</p>
                  <img src={iconDoubleArrow} alt="double-arrow" width="10" height="10" />
                </button>
                {
                  showAdministrationPopup &&
                  <div className="absolute bottom-9 right-0 z-10 bg-[#f9f9f9] w-[100px] h-fit rounded-lg py-2">
                    {
                      ADMINISTRATION.map(administration => {
                        return (
                          <button
                            key={administration.id}
                            className="h-[25px] px-2 flex items-center cursor-pointer hover:bg-[#e7e7e7]"
                            onClick={() => {
                              setValues(administration, setAdministrationOptions)
                              setShowAdministrationPopup(false)
                            }}
                          >
                            <p className="text-[#070625] text-[12px] leading-none">{administration.name}</p>
                          </button>
                        )
                      })
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </section>
    </article>
  )
}

export default DashboardMesh

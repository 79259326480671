import { useEffect, useState } from 'react';

import bgButtonStar from '../../assets/images/btn-star.svg';

import bgMessage from '../../assets/images/bubble-oldwoman-main-message.svg';

interface MessageProps {
  title?: string;
  message?: string;
  index: number;
  size?: string;
  useLoading?: boolean;
}

const TitleMessage = ({ message, title, size = 'w-[350px] h-[90px] pl-[10%]', index = -1, useLoading = true }: MessageProps) => {
  const [canShow, setCanShow] = useState<boolean>(false)
  const [isloading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const delay = 1500 * index
    const delay2 = delay + 1500
    setTimeout(() => {
      setCanShow(true)
    }, delay)
    setTimeout(() => {
      setLoading(false)
    }, delay2)
  }, [index])

  return (
    canShow
      ? <div className="w-full h-min my-1">
        {
          isloading && useLoading
            ? (
              <div className="chat-bubble">
                <div className="typing">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
              )
            : (
              <div key={index ?? '0'} className="flex justify-center">
                <div
                  className={`${size} min-w-[525px] z-[100] aspect-[5] relative grid place-content-center mt-[-6px] pt-[32px] xl:pt-[35px] pb-[10px] xl:pb-[18px] pr-[60px] ff-cp--normal text-white text-center bg-no-repeat bg-cover max-[480px]:max-w-[100%] max-[480px]:min-w-[50%] max-[480px]:w-[calc(100dvw-1px)] max-[480px]:bg-center max-[480px]:bg-contain max-[480px]:aspect-[4.2] max-[480px]:p-[25px_calc(100dvw*.08)_20px_calc(100dvw*.23)] ${useLoading ? 'animation-charge' : ''}`}
                  style={{ backgroundImage: `url(${bgMessage})` }} >
                  <div className="absolute left-[20%] top-[-5px] h-[34%] min-h-[28px] w-[23%]">
                    <div
                      className={'user-option flex items-center h-full aspect-[3.5] right-arrow pb-1 transition-all ease-in ff-cp--bold bg-cover justify-center pt-2 px-2 mb-[20px] leading-none'}
                      style={{ backgroundImage: `url(${bgButtonStar}` }}
                    >
                      <p className={'text-white text-[calc(5px+.60dvw)] leading-[calc(5px+.72dvw)]'}>{'Vieja Destino'}</p>
                    </div>
                  </div>
                  {
                    title?.split('<br />')
                      .map((phrase, index) => {
                        return (
                          <>
                            <p key={`title-${index}`} className={`ff-cp--bold max-[480px]:text-[13.5px] max-[480px]:leading-[16px] ${message ? 'text-[calc(6px+.912dvw)] leading-[calc(7px+.912dvw)]' : 'text-[calc(5px+1.25dvw)] leading-[calc(6px+1.25dvw)]'} text-white`}> {phrase} </p>
                          </>
                        )
                      })
                  }
                  {
                    message?.split('<br />')
                      .map((phrase, index) => {
                        return (
                          <>
                            <p key={`message-${index}`} className={`ff-cp--regular max-[480px]:text-[11px] max-[480px]:leading-[12px] text-center ${title ? 'text-[calc(10px+.395dvw)] leading-[calc(12px+.395dvw)]' : 'text-[calc(14px+.395dvw)] leading-[calc(16px+.395dvw)]'} `}> {phrase} </p>
                          </>
                        )
                      })
                  }
                </div>
              </div>
              )
        }
      </div>
      : <></>
  )
}

export default TitleMessage

import HumanityGalaxies from '../assets/images/galaxies/galaxy-5af0e1f0-9076-11ed-a312-89b20b8697fa.png'
import ArtsGalaxies from '../assets/images/galaxies/galaxy-0ce1aad0-9076-11ed-a312-89b20b8697fa.png'
import BusinessGalaxies from '../assets/images/galaxies/galaxy-49545e40-9076-11ed-a312-89b20b8697fa.png'
import ScienceGalaxies from '../assets/images/galaxies/galaxy-30c32730-9076-11ed-a312-89b20b8697fa.png'
import HumanitySelectedGalaxies from '../assets/images/galaxies/galaxy-selected-5af0e1f0-9076-11ed-a312-89b20b8697fa.png'
import ArtsSelectedGalaxies from '../assets/images/galaxies/galaxy-selected-0ce1aad0-9076-11ed-a312-89b20b8697fa.png'
import BusinessSelectedGalaxies from '../assets/images/galaxies/galaxy-selected-49545e40-9076-11ed-a312-89b20b8697fa.png'
import ScienceSelectedGalaxies from '../assets/images/galaxies/galaxy-selected-30c32730-9076-11ed-a312-89b20b8697fa.png'

export const PROFFESIONS_GALAXIES = [
  {
    simple: HumanityGalaxies,
    selected: HumanitySelectedGalaxies
  },
  {
    simple: ArtsGalaxies,
    selected: ArtsSelectedGalaxies
  },
  {
    simple: BusinessGalaxies,
    selected: BusinessSelectedGalaxies
  },
  {
    simple: ScienceGalaxies,
    selected: ScienceSelectedGalaxies
  }
]

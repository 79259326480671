import { getRecord, getUser } from '../../common/methods/storage'
import { API_ROUTES } from '../../constants/api_routes'
import { headers } from '../../constants/connection'

export const createReference = async (career_id: string) => {
  const user = getUser()
  if (user !== null) {
    const career_reference = {
      career_id,
      user_id: user.id
    }

    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.CAREER_REFERENCE.CREATE.ONLY}`, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      body: JSON.stringify(career_reference)
    })
      .then((response) => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
        console.log(error)
        return false
      })
  }
}

export const deleteReference = async (career_reference_id: string) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.CAREER_REFERENCE.DELETE}${career_reference_id}`, {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    }
  })
    .then((response) => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

export const createReferencesByList = async (careers_id_list: string[]) => {
  const userJSON = localStorage.getItem('tcm_auth_user')

  if (userJSON !== null) {
    const user = JSON.parse(userJSON)

    const data = {
      careers_id_list,
      user: user.id
    }

    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.CAREER_REFERENCE.CREATE.BY.LIST}`, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then(data => {
        return data
      })
      .catch((error: any) => {
        console.log(error)
        return {}
      })
  }
}

export const getCountReferences = async () => {
  const user = getUser()
  const record = getRecord()

  if (user && record) {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.CAREER_REFERENCE.GET.BY.USER.COUNT}${user.id}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      }
    })
      .then((response) => response.json())
      .then(data => {
        let result: any = { step: record.step }
        if (data.status) result = { ...result, count: data.count }

        return result
      })
      .catch((error: any) => {
        console.log(error)
        return {}
      })
  }
}

export const getAllReferencesByRecord = async () => {
  const user = getUser()

  if (user) {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.CAREER_REFERENCE.GET.BY.USER.ALL}${user.id}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      }
    })
      .then((response) => response.json())
      .then(data => {
        return data
      })
      .catch((error: any) => {
        console.log(error)
        return {}
      })
  }
}

export const deleteCareerReferenceByUserId = async () => {
  const user = getUser()

  if (user) {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.CAREER_REFERENCE.DELETE_BY_USER}`, {
      method: 'DELETE',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      }
    })
      .then((response) => response.json())
      .then(data => {
        return data
      })
      .catch((error: any) => {
        console.log(error)
        return {}
      })
  }
}

export class MessageResult {
  isView: boolean
  status: boolean
  message: string
  step?: number

  constructor (isView: boolean, status: boolean, message: string, step?: number) {
    this.isView = isView
    this.status = status
    this.message = message
    this.step = step
  }
}

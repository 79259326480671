import { Link } from 'react-router-dom'
import btnReturn from '../../assets/images/buttons/btn-return.svg'

interface buttonProps {
  action?: string,
  state?: any,
  linkReturn?: string
  callback?: () => void
}

const ButtonReturn = ({ action, state, linkReturn = '/', callback }: buttonProps) => {
  return (
    action === 'callback'
      ? (
        <button onClick={callback} className="button-return absolute top-[30px] left-[40px] z-[180] w-[40px] h-[40px] text-[0px] bg-cover transition ease opacity-60 right-arrow hover:scale-110 hover:opacity-100 max-[480px]:opacity-100" style={{ backgroundImage: `url(${btnReturn})` }}>Return</button>
        )
      : (
        <Link to={linkReturn} state={state} className="button-return absolute top-[30px] left-[40px] z-[180] w-[40px] h-[40px] text-[0px] bg-cover transition ease opacity-60 right-arrow hover:scale-110 hover:opacity-100 max-[480px]:opacity-100" style={{ backgroundImage: `url(${btnReturn})` }}>Return</Link>
        )
  )
}

export default ButtonReturn

import { Link } from 'react-router-dom'
import btnBack from '../../../assets/images/icons/icon-back.svg'
import { getWorkspaceParams } from '../../../common/methods/params'
import ButtonReturn from '../../../components/shared/ButtonReturn'

const RecoveryLinkExpired = () => {
  const { url } = getWorkspaceParams()

  return (
    <article className="bg-main auth-page bg-auth">
      <section className="grid grid-rows-1 w-screen h-screen px-8 max-[480px]:px-1">
        <ButtonReturn action="return" linkReturn={`/${url}/login`}></ButtonReturn>

        <div className="grid relative w-[1071px] items-center justify-self-center after:w-[534px] after:h-[700px] after:absolute after:bottom-0 after:left-0 after:right-0 after:mx-auto after:opacity-10 after:bg-gradient-to-t after:from-violet-600 after:to-transparent bg-login max-[480px]:w-full max-[480px]:after:w-full">
        <div className="col-span-2 px-[35px] relative self-center">
          <h2 className="ff-cp--bold text-[70px] text-white justify-self-center mb-4 leading-none max-[480px]:text-[45px]">
            El link ha expirado
          </h2>
          <p className="ff-m--regular text-[16px] text-white max-[480px]:text-[14px]">
            Intenta ingresar de nuevo al portal.
          </p>
          <div className="mt-3">
            <Link to={`/${url}/login`} className="text-[16px] text-white flex gap-2">
              <img src={btnBack} alt="" className="w-[16px] h-[16px] mt-1" /> Regresar al log in
            </Link>
          </div>
        </div>
        </div>
      </section>
    </article>
  )
}

export default RecoveryLinkExpired

export const getUser = () => {
  const user = localStorage.getItem('tcm_auth_user')
  if (user) return JSON.parse(user)
  return { id: '' }
}

export const setUser = (user: any) => {
  localStorage.setItem('tcm_auth_user', JSON.stringify(user))
}

export const getRecord = () => {
  const record = localStorage.getItem('tcm_current_record')
  if (record) return JSON.parse(record)
  return null
}

export const setRecord = (record: any) => {
  localStorage.setItem('tcm_current_record', JSON.stringify(record))
}

export const setFeatures = (profiles: any) => {
  const record = getRecord()
  if (record) {
    const features = record.features
    for (const profile of profiles) {
      const profile_data = {
        id: profile.feature_id,
        parent_id: profile.feature.parent_id,
        name: profile.feature.name,
        type: profile.feature.type,
        profiles: {
          selected: profile.selected,
          reaction: profile.reaction,
          weight: profile.weight
        }
      }
      const index = features.findIndex((fet:any) => fet.id === profile.feature_id)
      if (index === -1) features.push(profile_data)
      else features[index] = profile_data
    }

    record.features = features

    setRecord(record)
  }
}

export const getToken = () => {
  const token = localStorage.getItem('tcm_access_token')
  return token ?? null
}

export const getTutorialStatus = () => {
  const record = getRecord()
  let result = false

  if (record) result = record.demo
  return result
}

export const getDemoCareerStatus = () => {
  const record = getUser()
  let result = false

  if (record) result = record.demo_career
  return result
}

export const getDemoMeshStatus = () => {
  const record = getUser()
  let result = false

  if (record) result = record.demo_mesh
  return result
}

export const getDemoMenuStatus = () => {
  const record = getUser()
  let result = false

  if (record) result = record.demo_menu
  return result
}

export const getCurrentStep = () => {
  const record = getRecord()
  let current_step = 0

  if (record) current_step = record.current_step
  return current_step
}

export const getTutorialStep = () => {
  const record = getRecord()
  let step = ''
  if (record) step = record.tutorial_step
  return step
}

export const getRecordCareers = () => {
  const record = getRecord()
  let careers: any[] = []
  if (record) careers = [...record.careers]
  return careers
}

export const getCareerPage = () => {
  const record = getRecord()
  let career_page = 0

  if (record) career_page = record.career_page
  return career_page
}

export const getChargeResult = () => {
  const record = getRecord()
  let charge_result = false

  if (record) charge_result = record.charge_result
  return charge_result
}

export const getHasReferences = () => {
  const record = getRecord()
  let references = false

  if (record) references = record.references
  return references
}

export const getFavouriteCourses = () => {
  const record = getRecord()
  let favouriteCourse = false

  if (record) favouriteCourse = record.favourite_courses
  return favouriteCourse
}

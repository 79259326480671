import { useFormik } from 'formik'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import iconLock from '../../../assets/images/icons/icon-lock.svg'
import { getWorkspaceParams } from '../../../common/methods/params'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import ButtonReturn from '../../../components/shared/ButtonReturn'
import ButtonStar from '../../../components/shared/ButtonStar'
import ErrorMessage from '../../../components/shared/ErrorMessage'
import { PAGES } from '../../../constants/Pages'
import { MESSAGES } from '../../../constants/response_message'
import { MessageResult } from '../../../models/MessageResult'
import { resetPassword } from '../../../services/apis/auth'

const RecoveryChangePassword = () => {
  const { token } = useParams()
  const { url } = getWorkspaceParams()

  const navigate = useNavigate()

  const [message, setMessage] = React.useState(new MessageResult(false, false, ''))

  const [titlePopup, setTitlePopup] = React.useState<string>('')
  const [descriptionPopup, setDescriptionPopup] = React.useState<string>('')
  const [buttonTextPopup, setButtonTextPopup] = React.useState<string>('')
  const [redirectUrl, setRedirectUrl] = React.useState<string>('')
  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  const validateSchema = Yup.object().shape({
    password: Yup.string().required(MESSAGES.REGISTER.WRONG.EMPTY),
    repeat_password: Yup.string().required(MESSAGES.REGISTER.WRONG.EMPTY).oneOf([Yup.ref('password'), MESSAGES.REGISTER.WRONG.PASSWORD_MATCH])
  })

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validateSchema,
    initialValues: {
      password: '',
      repeat_password: '',
      token: ''
    },
    onSubmit: async (values) => {
      values.token = token!
      await resetPassword(values).then(data => {
        if (data.status) {
          navigate(`/${url}/recovery/change-success`, { state: { email: data.data.email, password: values.password } })
        } else if (data.message === MESSAGES.RECOVERY.PASSWORD.WRONG.EXPIRED) {
          setRedirectUrl(`/${url + PAGES.RECOVERY.EXPIRED}`)
          setTitlePopup('El link ha expirado')
          setDescriptionPopup('Intenta ingresar de nuevo al portal.')
          setButtonTextPopup('Regresar a Log in')
          setShowPopup(true)
        } else {
          setMessage({ isView: true, status: data.status, message: data.data.message })
        }
      })
        .catch(err => {
          console.log(err)
        })
    },
    validate: values => {
      validateSchema.validate(values).catch(err => {
        const error: string = err.errors[0]
        setMessage({ isView: true, status: false, message: error })
      })
    }

  })

  return (
    <article className="bg-main auth-page bg-auth">
       <section className="grid grid-rows-1 w-screen h-screen px-8 max-[480px]:px-1">
          <ButtonReturn action="return" linkReturn={`/${url}/recovery/reset-password`} />

          <div className="grid relative w-[1071px] items-center justify-self-center after:w-[534px] after:h-[700px] after:absolute after:bottom-0 after:left-0 after:right-0 after:mx-auto after:opacity-10 after:bg-gradient-to-t after:from-violet-600 after:to-transparent bg-login max-[480px]:w-full max-[480px]:after:w-full">
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <div className="flex flex-col h-full gap-5 place-content-center">
                    <div className="grid gap-1">
                      <label className="ff-m--regular text-base text-white max-[480px]:text-[14px]" htmlFor="password">Nueva contraseña</label>
                      <div className="relative">
                        <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[24px] h-[12px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconLock})` }}></span>
                          <input
                              id="password"
                              name="password"
                              type="password"
                              className="pl-[45px]"
                              onChange={formik.handleChange}
                              onPaste={e => e.preventDefault()}
                              value={formik.values.password}
                              placeholder="Password"
                          />
                      </div>
                    </div>

                    <div className="grid gap-1">
                      <label className="ff-m--regular text-base text-white max-[480px]:text-[14px]" htmlFor="repeat_password">Repite tu contraseña</label>
                      <div className="relative">
                        <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[24px] h-[12px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconLock})` }}></span>
                        <input
                            id="repeat_password"
                            name="repeat_password"
                            type="password"
                            className="pl-[45px]"
                            onChange={formik.handleChange}
                            onPaste={e => e.preventDefault()}
                            value={formik.values.repeat_password}
                            placeholder="Repeat Password"
                        />
                      </div>
                    </div>

                    <div className="mt-5">
                      {message.isView && !message.status && <ErrorMessage title={message.message}></ErrorMessage>}
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <ButtonRedirect
                      type="submit"
                      text="Cambiar contraseña"
                      borderColor='#A41AE8'
                      showDesing
                      next></ButtonRedirect>
                  </div>
                </div>
              </form>
          </div>

          {
            showPopup &&
            <div className="fixed flex items-center justify-center inset-0 bg-[#06010F]/50 z-[110]">
              <div className="flex flex-col justify-center items-center w-[600px] h-[600px] pt-[60px] bg-popup">
                <h3 className="ff-cp--bold text-center text-white text-[30px] font-bold leading-7 mb-4">{ titlePopup }</h3>
                <p className="ff-cp--light max-w-[490px] text-center text-white text-[20px] font-normal mb-[20px]">{ descriptionPopup }</p>
                <ButtonStar text={buttonTextPopup} type='purple' callback={() => navigate(redirectUrl)}></ButtonStar>
              </div>
            </div>
          }
        </section>
    </article>

  )
}

export default RecoveryChangePassword

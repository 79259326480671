import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// Services
import { getAllFeaturesByType } from '../../../services/apis/feature'
import { createProfilesByList } from '../../../services/apis/profile'

// Components
import CardNoun from '../../../components/cards/CardNoun'
import BadgeStep from '../../../components/shared/BadgeStep'
import TitleMessage from '../../../components/shared/TitleMessage'

// Constants
import { NOUNS } from '../../../constants/lists/nouns'
import { PAGES } from '../../../constants/Pages'
import { REACTIONS } from '../../../constants/reactions'

// Methods
import { getWorkspaceParams } from '../../../common/methods/params'

// Images
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import ButtonReturn from '../../../components/shared/ButtonReturn'

const Disciplines = () => {
  const { id } = useParams()
  const { url } = getWorkspaceParams()

  const [feature, setFeature] = useState<any>(null)
  const [disciplineSelected, setDisciplineSelected] = useState<any>(null)
  const [index, setIndex] = useState<number>(0)

  const [showPopup, setShowPopup] = useState<boolean>(false)

  const CARD_COLORS = ['orange', 'red', 'blue', 'green', 'light-blue']

  useEffect(() => {
    getFeatures()
    setDisciplineSelected(null)
  }, [id])

  const getFeatures = async () => {
    await getAllFeaturesByType('nouns')
      .then(data => {
        const features: any[] = data.data
        const feature = features.find((ft: any) => ft.id === id)
        setFeature(feature)

        const _index = NOUNS.findIndex(noun => noun.id === id)
        setIndex(_index)
      })
  }

  const goToReturn = () => {
    let page = PAGES.TALENTS
    if (index > 0) {
      const previousNoun = NOUNS[index - 1]
      page = PAGES.DISCIPLINES_ITEMS + previousNoun.id
    }
    return page
  }

  const onGoToNextStep = async () => {
    if (feature) {
      await createProfilesByList([{
        ...feature,
        feature_id: feature.id,
        feature,
        reaction: REACTIONS.LOVE,
        weight: null,
        selected: null
      }])
    }
  }

  return (
    <article className="bg-main" id="home">
      <section className="w-screen h-screen quiz-screen-background relative bg-feature-small py-4">
        <BadgeStep currentStep={ 7 + index }/>
        <ButtonReturn
          action={ showPopup ? 'callback' : 'return' }
          callback={ showPopup ? () => { setShowPopup(false) } : undefined }
          linkReturn={ '/' + url + goToReturn() }
        />
        <ButtonRedirect
          action="next"
          text='Continuar'
          callback={ onGoToNextStep }
          linkReturn={`${PAGES.DISCIPLINES_ITEMS}${id}`}
        />
        <TitleMessage
          index={0}
          useLoading={false}
          title=""
          message="Veamos de cerca la siguiente constelación."
          size="w-1/2 max-w-[800px] h-auto pl-[10%]"
        />
        {
          feature &&
          <div className='w-full h-[calc(100%-136px)] relative z-[120] flex justify-center items-center animation-charge py-6 px-[35px] max-[480px]:px-0'>
            <CardNoun
              key={feature.id}
              discipline={feature}
              color={CARD_COLORS[index]}
              state={disciplineSelected?.reaction}
            />
          </div>
        }
      </section>
    </article>
  )
}

export default Disciplines

import { useFormik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

// Services
import { REGISTER_FAIL, REGISTER_SUCCESS } from '../../actions/type'
import { getWorkspaceParams } from '../../common/methods/params'
import { checkEmail, register } from '../../services/apis/auth'

// Models
import { MessageResult } from '../../models/MessageResult'

// Constants
import { MESSAGES } from '../../constants/response_message'

// Components
import ButtonRedirect from '../../components/shared/ButtonRedirect'
import ButtonReturn from '../../components/shared/ButtonReturn'
import ButtonStar from '../../components/shared/ButtonStar'
import ErrorMessage from '../../components/shared/ErrorMessage'

// Images
import burblePopup from '../../assets/images/burble-popup.svg'
import iconLogo from '../../assets/images/icon-logo.svg'
import iconCalendar from '../../assets/images/icons/icon-calendar.svg'
import btnCancel from '../../assets/images/icons/icon-cancel.svg'
import iconCheckboxActive from '../../assets/images/icons/icon-checkbox-select.svg'
import iconCheckbox from '../../assets/images/icons/icon-checkbox.svg'
import iconEmail from '../../assets/images/icons/icon-email.svg'
import iconLock from '../../assets/images/icons/icon-lock.svg'
import iconSmile from '../../assets/images/icons/icon-smile.svg'
import callingLogo from '../../assets/images/logos/logo.svg'
import { isMobile } from '../../common/methods/devices'
import { PAGES } from '../../constants/Pages'

const Register = () => {
  const { url, workspace, group } = getWorkspaceParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [open, setOpen] = useState<boolean>(false)
  const [step, setStep] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [message, setMessage] = useState(new MessageResult(false, false, ''))

  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  const validationSchema = Yup.lazy(() => fieldsSchema())

  const updateStep = (step: number) => {
    if ((message?.isView && (message.step ?? -1) >= step) || !message.isView) setStep(step)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const prevStep = () => {
    if (step > 1) setStep(step - 1)
  }

  const nextStep = () => {
    if (step < 2) setStep(step + 1)
  }

  const fieldsSchema = () => {
    if (step === 1) {
      return Yup.object().shape({
        username: Yup.string().nonNullable().required(MESSAGES.REGISTER.WRONG.EMPTY),
        last_name: Yup.string().nonNullable().required(MESSAGES.REGISTER.WRONG.EMPTY),
        age: Yup.string().nonNullable().required(MESSAGES.REGISTER.WRONG.EMPTY)
      })
    }

    if (step === 2) {
      return Yup.object().shape({
        email: Yup.string()
          .required(MESSAGES.REGISTER.WRONG.EMPTY)
          .nonNullable()
          .email(MESSAGES.REGISTER.WRONG.EMAIL.PATH)
          .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, MESSAGES.REGISTER.WRONG.EMAIL.PATH),
        password: Yup.string()
          .required(MESSAGES.REGISTER.WRONG.EMPTY)
          .nonNullable(),
        repeat_password: Yup.string()
          .required(MESSAGES.REGISTER.WRONG.EMPTY)
          .nonNullable()
          .oneOf([Yup.ref('password')], MESSAGES.REGISTER.WRONG.PASSWORD_MATCH),
        terms_accept: Yup.bool()
          .required(MESSAGES.REGISTER.WRONG.TERMS)
          // .oneOf([true], MESSAGES.REGISTER.WRONG.TERMS).default(false),
      })
    }

    return Yup.object().shape({})
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    initialValues: {
      username: '',
      last_name: '',
      age: '',
      email: '',
      password: '',
      repeat_password: '',
      terms_accept: false,
      domain: workspace,
      group
    },
    onSubmit: async (values) => {
      if (step === 2) {
        if (!values.terms_accept) {
          setMessage({ isView: true, status: false, message: MESSAGES.REGISTER.WRONG.TERMS, step })
          return
        }
        await checkEmail(values.email, workspace, group || '')
          .then(result => {
            if (!result) {
              setMessage({ isView: true, status: false, message: 'Correo ya registrado', step })
            } else {
              setShowConfirm(true)
            }
          })
      } else {
        nextStep()
      }
    },
    validate: async (values) => {
      await validationSchema
        .validate(values)
        .then(() => {
          setMessage({ isView: false, status: true, message: '', step: -1 })
        }).catch((err: any) => {
          const error: string = err.errors[0]
          setMessage({ isView: true, status: false, message: error, step })
        })
    }
  })

  const handleBirthChange = (event: any) => {
    let inputDate = event.target.value
    inputDate = inputDate.replace(/[^0-9/]/g, '')

    if (inputDate.length > 2 && !inputDate.includes('/')) {
      const day = inputDate.slice(0, 2)
      inputDate = `${day <= 31 ? day : '01'}/${inputDate.slice(2)}`
    } else if (inputDate.length >= 6 && inputDate.indexOf('/', 3) === -1) {
      const month = inputDate.slice(3, 5)
      const year = inputDate.slice(5)
      inputDate = `${inputDate.slice(0, 2)}/${month <= 12 ? month : '01'}/${year}`
    }

    formik.setFieldValue('age', inputDate)
  }

  const getTermsWidth = () => {
    return `${window.innerWidth * 1.1}px`
  }

  const registerUser = async () => {
    setIsLoading(true)
    await register({ ...formik.values, name: formik.values.username })
      .then(data => {
        if (data.status) {
          dispatch({ type: REGISTER_SUCCESS })
          // if (isMobile()) {
          //   navigate(`/${url}` + PAGES.LOADING_5)
          // } else {
          navigate(`/${url}` + PAGES.LOADING_1)
          // }
        } else {
          setMessage({ isView: true, status: false, message: data.message, step })
          dispatch({ type: REGISTER_FAIL })
        }

        setIsLoading(false)
      }).catch(error => {
        setMessage({ isView: true, status: false, message: error.message, step })
        setIsLoading(false)
        dispatch({ type: REGISTER_FAIL })
      })
  }

  return (
    <article className="auth-page login-page bg-auth">
      <section className="grid grid-rows-1 w-screen h-screen">
        <ButtonReturn
          action={step > 1 ? 'callback' : 'return'}
          callback={step > 1 ? prevStep : undefined}
          linkReturn={step > 1 ? undefined : `/${url}`}
        />
        {
          open && (
            <div className="absolute h-full overflow-hidden text-center top-0 right-0 left-0 z-[200] p-10 max-[480px]:py-0 max-[480px]:px-[2px] bg-black/[.4]">
              <div className="w-full h-full grid place-content-center text-[16px] text-white justify-items-center max-[480px]:content-end">
                <div className="terms-conditions-popup w-[810px] h-[675px] max-[480px]:w-[100%] z-1 py-4 px-8 bg-cover bg-center bg-no-repeat rounded-[25px]" style={{ height: isMobile() ? getTermsWidth() : '675px' }}>
                  <div className="w-full h-full bg-[#070625] rounded-[25px] border border-solid border-[#fff]/[.5]">
                    <div className="break-words h-full text-left select-none">
                      <div className="w-full h-min border border-solid border-[#fff]/[.5] rounded-t-[25px] flex justify-between items-center py-4 px-6">
                        <img src={callingLogo} className="h-[40px] w-[40px] max-[480px]:h-[20px] max-[480px]:w-[20px]"></img>
                        <p className="ff-cp--bold text-4xl max-[480px]:text-sm">Términos y Condiciones</p>
                        <button onClick={handleClose} className="h-[45px] w-[45px] max-[480px]:h-[30px] max-[480px]:w-[30px] text-[0px] bg-contain bg-no-repeat opacity-70 hover:opacity-100 hover:scale-110" style={{ backgroundImage: `url(${btnCancel})` }}>Cancelar</button>
                      </div>

                      <div className="p-7 h-[calc(100%-80px)] overflow-auto scroll-m-0 ctm-scrollbar scrollbar-hide text-sm max-[480px]:text-[11px] max-[480px]:leading-[14px]">
                        <p className="mb-4">Al utilizar M.O.V.E Match declaro conocer y aceptar los t&eacute;rminos y condiciones del servicio especificados en el siguiente documento.</p>

                        <p className="mb-4">En ning&uacute;n caso el equipo detr&aacute;s de M.O.V.E Match se hace
                          responsable legalmente del mal uso de la aplicaci&oacute;n.</p>

                        <p className="mb-4">Asimismo, el usuario se compromete a:</p>

                        <ul className="mb-4">
                          <li>No usar la plataforma para otros fines comerciales fuera el uso personal.</li>
                          <li>No modificar ninguna parte del servicio.</li>
                        </ul>

                        <p className="mb-4">Nuestro servicio puede contener enlaces a p&aacute;ginas que no son operadas por
                          nosotros. Al no tener control sobre estas plataformas sugerimos que el usuario
                          lea los t&eacute;rminos y condiciones planteados por ellos.</p>

                        <p className="mb-4">Nuestro servicio utiliza Cookies para identificar &aacute;reas de la aplicaci&oacute;n
                          que has visitado. Esto nos permite brindarte la experiencia completa. Sin
                          embargo, no es necesario que mantengas esta funcionalidad activada. Ten en
                          cuenta que al no hacerlo puede que algunas partes del servicio no ser&aacute;n
                          accesibles.</p>

                        <p className="mb-4">Al usar el servicio, el usuario estar&aacute; brindando informaci&oacute;n
                          personal tal como usuario, nombre, email y contrase&ntilde;a. Esta informaci&oacute;n
                          nos permite brindar la experiencia completa del servicio al usuario. Asimismo es
                          responsabilidad de el guardar esta informaci&oacute;n de manera confidencial y
                          segura.</p>

                        <p className="mb-4">Nuestro servicio se provee como tal tomando en cuenta sus fallas y defectos sin
                          ofrecer garant&iacute;a alguna. De esta manera no podemos asegurar que cualquier
                          defecto ser&aacute; corregido o que podamos ofrecer una experiencia sin
                          errores.</p>

                        <p className="mb-4">En caso experimentes alg&uacute;n problema con el servicio o tengas alguna duda,
                          contactanos directamente.</p>

                        <p className="mb-4">Via EMail <a href="mailto:contacto@movetuvocacion.com">contacto@movetuvocacion.com</a></p>

                        <p className="mb-4">Via WhatsApp +51 939 376 767</p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        <div className={`grid relative w-[1071px] max-[480px]:w-full items-center justify-self-center after:w-[534px] after:h-[700px] after:max-w-[100%] after:absolute after:bottom-0 after:left-0 after:right-0 after:mx-auto after:opacity-10 after:bg-gradient-to-t after:from-violet-600 after:to-transparent ${showConfirm ? '' : 'bg-login'}`}>
          <form className="flex items-center justify-center" onSubmit={formik.handleSubmit}>
            <div className="m-auto pt-0 xl:pt-[65px]">
              <div className="flex flex-col gap-y-4">
                <div className="points flex justify-center">
                  <button type='button' onClick={() => updateStep(1)} className={'bg-white rounded-full w-[10px] h-[10px] mx-[10px] opacity-20' + (step === 1 ? ' !opacity-100' : '')}></button>
                  <button type='button' onClick={() => updateStep(2)} className={'bg-white rounded-full w-[10px] h-[10px] mx-[10px] opacity-20' + (step === 2 ? ' !opacity-100' : '')}></button>
                </div>
                <div className="flex flex-col h-full gap-y-[14px] max-[480px]:gap-y-2 place-content-center">
                  {
                    step === 1 && (
                      <>
                        <div className="grid gap-1">
                          <label className="ff-cp--bold text-[16px] max-[480px]:text-[14px] text-white" htmlFor="username">Nombre</label>
                          <div className="relative">
                            <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[22px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconSmile})` }}></span>
                            <input
                              id="username"
                              name="username"
                              type="text"
                              autoComplete="on"
                              className="pl-[45px]"
                              placeholder="Nombre"
                              onChange={formik.handleChange}
                              onPaste={e => e.preventDefault()}
                              value={formik.values.username}
                            />
                          </div>
                          <span className="text-white max-[480px]:text-[9px] max-[480px]:leading-[16px] text-sm text-center mt-1">Este será el nombre que se mostrará en toda la experiencia.</span>
                        </div>
                        <div className="grid gap-1">
                          <label className="ff-cp--bold text-[16px]  max-[480px]:text-[14px] text-white" htmlFor="last_name">Apellidos</label>
                          <div className="relative">
                            <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[22px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconSmile})` }}></span>
                            <input
                              id="last_name"
                              name="last_name"
                              type="text"
                              autoComplete="on"
                              className="pl-[45px]"
                              placeholder="Apellidos"
                              onChange={formik.handleChange}
                              onPaste={e => e.preventDefault()}
                              value={formik.values.last_name}
                            />
                          </div>
                        </div>
                        <div className="grid gap-1">
                          <label className="ff-cp--bold text-[16px]  max-[480px]:text-[14px] text-white" htmlFor="age">Fecha de nacimiento</label>
                          <div className="relative">
                            <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[22px] pointer-events-none bg-cover" style={{ backgroundImage: `url(${iconCalendar})` }}></span>
                            <input
                              id="age"
                              name="age"
                              type="text"
                              className="pl-[45px]"
                              placeholder="dd/mm/yyyy"
                              onChange={handleBirthChange}
                              value={formik.values.age}
                            />
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    step === 2 && (
                      <>
                        <div className="grid gap-1">
                          <label className="ff-cp--bold text-[16px]  max-[480px]:text-[14px] text-white" htmlFor="email">Correo</label>
                          <div className="relative">
                            <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[17px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconEmail})` }}></span>
                            <input
                              id="email"
                              name="email"
                              type="email"
                              className="pl-[45px]"
                              placeholder="email@email.com"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                          </div>
                        </div>
                        <div className="grid gap-1">
                          <label className="ff-cp--bold text-[16px]  max-[480px]:text-[14px] text-white" htmlFor="password">Contraseña</label>
                          <div className="relative">
                            <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[24px] h-[12px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconLock})` }}></span>
                            <input
                              id="password"
                              name="password"
                              type="password"
                              className="pl-[45px]"
                              placeholder="Contraseña"
                              onChange={formik.handleChange}
                              onPaste={e => e.preventDefault()}
                              value={formik.values.password}
                            />
                          </div>
                        </div>
                        <div className="grid gap-1">
                          <label className="ff-cp--bold text-[16px] max-[480px]:text-[14px]  text-white" htmlFor="repeat_password">Repetir contraseña</label>
                          <div className="relative">
                            <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[24px] h-[12px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconLock})` }}></span>
                            <input
                              id="repeat_password"
                              name="repeat_password"
                              type="password"
                              className="pl-[45px]"
                              placeholder="Repetir contraseña"
                              onChange={formik.handleChange}
                              onPaste={e => e.preventDefault()}
                              value={formik.values.repeat_password}
                            />
                          </div>
                        </div>
                        <div className="checkbox mt-2">
                          <div className="flex">
                            <input type="checkbox" checked={formik.values.terms_accept} onChange={formik.handleChange} className="hidden" value="remember-me" />
                            <span onClick={() => formik.setFieldValue('terms_accept', !formik.values.terms_accept)}
                              className="block w-[17px] h-[15px] mt-[2px] max-[480px]:mt-[1px] xl:mt-0 xl:w-[26px] xl:h-[24px] bg-cover bg-center cursor-pointer"
                              style={{ backgroundImage: `url(${formik.values.terms_accept ? iconCheckboxActive : iconCheckbox})` }} />
                            <p className="text-white text-[12px] max-[480px]:text-[9px] max-[480px]:leading-[16px] xl:text-sm ml-[10px] w-full">Estoy de acuerdo con los <b onClick={handleOpen} className="underline underline-offset-4 cursor-pointer opacity-70 hover:opacity-100 hover:scale-110">Términos y Condiciones</b>
                            </p>
                          </div>
                        </div>
                      </>
                    )
                  }
                </div>

                  {
                    message.isView && !message.status && (
                      <div className="my-2 h-[65px]">
                        <ErrorMessage title={message.message}></ErrorMessage>
                      </div>
                    )}

              </div>

              <div className="flex justify-center">
                <ButtonRedirect type="submit" text={step === 1 ? 'Continuar' : 'Crear'} borderColor='#A41AE8' showDesing next loading={isLoading}></ButtonRedirect>
              </div>
            </div>
          </form>
        </div>
        {
          showConfirm &&
          <div className="fixed flex items-center justify-center top-0 left-0 right-0 bottom-0 bg-[#06010F]/50 z-[200]">
            {
              !showConfirm &&
              <span className="absolute top-[55px] left-0 right-0 mx-auto w-[64px] h-[64px]" style={{ backgroundImage: `url(${iconLogo})` }}></span>
            }
            <div className="flex flex-col justify-center items-center w-[680px] aspect-square pt-[60px] bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${burblePopup})` }}>
              <h3 className="ff-cp--bold text-center text-white text-[30px] max-[480px]:text-[16px] font-bold leading-7 mb-4">¿Confirmar datos ingresados?</h3>
              <p className="ff-cp--light max-w-[490px] text-center text-white text-[20px] max-[480px]:text-[14px] font-normal mb-[40px]">Selecciona antes de continuar</p>
              <div className="flex w-full justify-between px-[15px] max-[480px]:px-[30px]">
                <ButtonStar text='Seguir editando' type='purple' callback={() => setShowConfirm(false)}></ButtonStar>
                <ButtonStar text='Confirmar' type='purple' callback={registerUser}></ButtonStar>
              </div>
            </div>
          </div>

        }
      </section>
    </article>
  )
}

export default Register

import { useNavigate } from 'react-router-dom'

import { isMobile } from '../../common/methods/devices'
import { getWorkspaceParams } from '../../common/methods/params'
import { getRecord, getUser, setRecord } from '../../common/methods/storage'

// Constants
import { PAGES } from '../../constants/Pages'

// Images
import { useEffect, useState } from 'react'
import btnReversePurple from '../../assets/images/button-star-purple-reverse.svg'
import iconDashboardMobile from '../../assets/images/logos/icon-dashboard-menu-mobile.svg'
import LogoCareers from '../../assets/images/logos/logo-careers.svg'
import LogoExperience from '../../assets/images/logos/logo-experience.svg'
import LogoHome from '../../assets/images/logos/logo-home.svg'
import LogoLogout from '../../assets/images/logos/logo-logout.svg'
import LogoMeshs from '../../assets/images/logos/logo-meshs.svg'
import logo from '../../assets/images/logos/logo.svg'

interface DashboardMenuProps {
  currentPage: number,
  type?: 'SIMPLE' | 'COMPLETE',
  isTutorial?: boolean,
  showMainMenu?: boolean,
  onHovered?: boolean,
  step?: number,
  disable?: boolean,
  logoPosition?: 'BOTTOM' | 'TOP'
}

const DashboardMenu = ({ currentPage, type = 'SIMPLE', isTutorial = false, showMainMenu = true, onHovered = false, step = 3, disable = false, logoPosition = 'BOTTOM' }: DashboardMenuProps) => {
  const { url } = getWorkspaceParams()
  const navigate = useNavigate()
  const user = getUser()

  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [chargeAnimation, setChargeAnimation] = useState<boolean>(false)

  useEffect(() => {
    if (isTutorial) {
      setTimeout(() => {
        setChargeAnimation(true)
      }, 3000);
    }
  }, [])

  const logoutSearch = () => {
    localStorage.clear()
    navigate(`/${url}/login`)
  }

  const ITEMS_MENU = [{
    key: 1,
    icon: LogoHome,
    name: 'Home',
    tooltip: 'Home',
    position: 'bottom-[55px] left-[105px]',
    callback: () => navigate(`/${url}${PAGES.DASHBOARD_HOME}`),
    activeInTutorial: true
  }, {
    key: 2,
    icon: LogoMeshs,
    name: 'Mallas',
    tooltip: 'Mallas Revisadas',
    position: 'bottom-[110px] left-[190px]',
    callback: () => navigate(`/${url}${PAGES.MESHS}`),
    activeInTutorial: true
  }, {
    key: 3,
    icon: LogoCareers,
    name: 'Carreras',
    tooltip: 'Carreras Candidatas',
    position: 'bottom-[140px] left-[260px]',
    callback: () => {
      const record = getRecord()
      if (record) {
        setRecord({
          ...record,
          career_page: 0
        })
      }
      navigate(`/${url}${PAGES.CAREERS}`)
    },
    activeInTutorial: true
  }, {
    key: 4,
    icon: LogoExperience,
    name: 'La experiencia',
    tooltip: 'Revive la experiencia',
    position: 'bottom-[110px] right-[190px]',
    callback: () => {
      const record = getRecord()
      if (record) {
        navigate(`/${url}${PAGES.EXPERIENCE_DECISION}`)
      }
    },
    activeInTutorial: true
  }, {
    key: 5,
    icon: LogoLogout,
    name: 'Cerrar sesión',
    tooltip: 'Cerrar sesión',
    position: 'bottom-[55px] right-[105px]',
    callback: logoutSearch,
    activeInTutorial: true
  }]

  const redirect = () => {
    window.open('https://www.movetuvocacion.com/', '_blank')
  }

  return (
       <>
       {
        ((!isMobile() && showMainMenu) || (isMobile() && showMenu && !isTutorial) || (showMainMenu && isTutorial)) &&
          <div className={`dashboard-menu ${isMobile() ? 'bg-main-alt' : ''} ${disable ? 'bg-menu-dashboard-disabled w-[127px] h-[62px] xl:w-[170px] xl:h-[85px] pointer-events-none opacity-60' : isTutorial ? `${isMobile() ? '' : 'dashboard-menu-tutorial'} w-[574px] h-[245px] max-[480px]:w-full max-[480px]:h-full` : `w-[127px] h-[62px] xl:w-[170px] xl:h-[85px] ${isMobile() ? '' : 'hover:w-[574px] hover:h-[245px]'} max-[480px]:w-full max-[480px]:h-full`} fixed bottom-0 left-0 right-0 m-auto ${isMobile() ? '' : 'bg-menu-dashboard'} z-[100] transition-all ease-in duration-300`}>
          {
              !isMobile() &&
              ITEMS_MENU.map((item, index) => {
                return (
                      <div key={item.key} onClick={item.callback} className={`item-menu absolute ${item.position} w-[52px] h-[52px] flex items-center justify-center bg-[#070625] cursor-pointer rounded-full transition-all ease-in-out duration-200 border ${(currentPage === item.key) ? 'z-[120]' : 'z-[110]'} ${(currentPage === item.key) || (isTutorial && item.activeInTutorial && item.key === step) ? 'border-[#A41AE8] border-[3px]' : (item.key === step || !isTutorial) ? 'border-[#a31ae880] hover:z-[120] hover:border-[#A41AE8] hover:border-[3px]' : 'pointer-events-none border-[#424259] '}`}>
                          <div className={`item-menu-body w-[40px] h-[40px] flex items-center justify-center bg-[#070625] rounded-full border ${currentPage === item.key || (isTutorial && item.activeInTutorial && item.key === step) ? 'border-[#ffffff8f]' : !(isTutorial && item.activeInTutorial && item.key === step) ? 'border-[#424259]' : 'border-[#a31ae880]'}`}>
                              <img className={ currentPage === item.key || (isTutorial && item.activeInTutorial && item.key === step) ? 'opacity-[1_!important]' : ''} src={item.icon} width="26" height="26" />
                              <div className={`card absolute w-[63px] h-[14px] -bottom-[5px] text-center rounded-[42px] text-white ff-cp--bold text-[8px] bg-[#A41AE8] ${!(isTutorial && item.activeInTutorial) || !isTutorial ? '' : 'hidden'}`}>{item.name}</div>
                              <div className={`${(currentPage === item.key && isTutorial) ? 'tooltip-active' : !isTutorial ? 'tooltip' : item.key === step ? '' : 'tooltip-inactive'}  pointer-events-none absolute w-[170px] h-[46px] z-[111] -top-[55px] text-center rounded-[42px] text-white ff-cp--bold bg-center bg-no-repeat bg-cover flex items-center justify-center pt-1 pb-2 text-[12px]`} style={{ backgroundImage: `url(${btnReversePurple})` }}>
                                  { item.tooltip }
                              </div>
                          </div>
                      </div>
                )
              })
          }
          {
            ((isMobile() && showMenu) || (isMobile() && isTutorial)) &&
            <div className={`w-full h-full flex justify-center items-center flex-col gap-y-4 ease-in transition-all duration-[2500] ${chargeAnimation || !isTutorial ? 'opacity-100' : 'opacity-0'}`}>
              {
                ITEMS_MENU.map((item, index) => {
                  return (
                    <div key={item.key} onClick={item.callback} className={`w-[200px] h-[55px] relative bg-no-repeat bg-center bg-contain flex justify-center items-center gap-x-1 pb-[6px] ${isTutorial && item.activeInTutorial && item.key === step ? 'scale-110' : `${(item.key === step || !isTutorial) ? 'scale-110' : 'opacity-50'}`}`} style={{ backgroundImage: `url(${btnReversePurple})` }}>
                      {
                        <img src={item.icon} alt={item.name} className="absolute left-[14px] top-[12px] w-6 h-6" />
                      }
                     <label className="text-white">{ item.name }</label>
                    </div>
                  )
                })
              }
            </div>
          }
        </div>
       }
        {
          !isMobile() &&
          <div className="fixed w-[50px] h-[50px] xl:w-[65px] xl:h-[65px] 2xl:w-[55px] 2xl:h-[55px] grid place-content-center transition-all ease-in duration-300 bottom-[6px] left-[12px] z-[100] max-[480px]:top-[calc((100dvw*.2)-5px)] max-[480px]:left-[calc((100dvw*.9)-20px)] max-[480px]:z-[1000]">
            <div
                onClick={redirect}
                className="relative w-[50px] h-[50px] xl:w-[65px] xl:h-[65px] 2xl:w-[55px] 2xl:h-[55px] grid place-content-center ff-cp--bold text-white text-xs circle hover:scale-110 cursor-pointer">
              <img src={logo} className="w-[16px] h-[16px] xl:w-[25px] xl:h-[25px] 2xl:w-[25px] 2xl:h-[25px]" alt='logo'></img>
            </div>
          </div>
        }
        { user.workspace?.logo && !isMobile() && (
            <div className="fixed bottom-[8px] left-[62px] z-[120] bg-[#000000] w-[48px] h-[48px] border-2 border-white rounded-full after:top-[19px] after:absolute after:left-[-5.5px] after:w-[8px] after:h-[8px] after:bg-white after:rotate-45">
              <img src={user.workspace.logo} className=""></img>
            </div>
        )}
        {
          isMobile() &&
          <button
            onClick={() => setShowMenu(value => !value)}
            className={`fixed ${logoPosition === 'BOTTOM' ? 'bottom-[10px]' : 'top-[4px]'} right-[10px] w-[50px] h-[50px] opacity-100 z-[100]`}
            style={{ backgroundImage: `url(${iconDashboardMobile})` }}
          />
        }
       </>
  )
}

export default DashboardMenu

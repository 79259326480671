interface ChartProps {
    size?: string,
    chart: string,
    features: any[],
    shadowText?: boolean
}

const ConstelationChart = ({ size, chart, features, shadowText = true }: ChartProps) => {
  return (
        <div className={`${size ?? 'w-[598px] h-[449px]'} relative bg-center bg-no-repeat bg-cover`} style={{ backgroundImage: `url(${chart})` }}>
            {
                features.map((feature: any) => {
                  return (
                        <div key={feature.id} className={`w-[90px] absolute flex flex-col items-center justify-center text-center ${feature.position}`}>
                            <img className="object-fill" src={feature.star} width="27" height="29" alt={feature.name} />
                            {
                              shadowText
                                ? <div className="shadow-[inset_0px_10px_30px_0px_rgba(0,0,0,.2)] rounded-[16px] py-2 px-4">
                                    <p className="ff-m--bold text-[12px] leading-none text-white">{feature.name}</p>
                                  </div>
                                : <p className="ff-m--bold text-[12px] leading-none text-white">{feature.name}</p>
                            }
                        </div>
                  )
                })
            }
        </div>
  )
}

export default ConstelationChart

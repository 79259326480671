import { useEffect, useState } from 'react'
import Joyride from 'react-joyride'
import { Link, useNavigate, useParams } from 'react-router-dom'

// Services
import { getCountByInstitutionAndCareer } from '../../services/apis/course'
import { getMeshWithData } from '../../services/apis/mesh'

// Methods
import { getWorkspaceParams } from '../../common/methods/params'
import { getDemoMeshStatus, getUser } from '../../common/methods/storage'

// Components
import CardCareerDetail from '../../components/cards/CardCareerDetail'
import ButtonStar from '../../components/shared/ButtonStar'
import DashboardMenu from '../../components/shared/DarshboardMenu'

// Constants
import { PAGES } from '../../constants/Pages'
import { MESH } from '../../constants/tutorial'

// Images
import avatarDefault from '../../assets/images/avatar-default.svg'
import burblePopup from '../../assets/images/burble-popup.svg'
import btnMeshLight from '../../assets/images/buttons/btn-mesh-light.svg'
import btnMesh from '../../assets/images/buttons/btn-mesh.svg'
import btnResult from '../../assets/images/buttons/btn-result.svg'
import iconBackWhite from '../../assets/images/icons/icon-back-white.svg'
import iconHeart from '../../assets/images/icons/icon-heart.svg'
import bgHearthBig from '../../assets/images/icons/icon-hearth-big.svg'
import separator from '../../assets/images/separator.svg'
import { isMobile } from '../../common/methods/devices'
import IntroTooltip from '../../components/intro/IntroTooltip'
import TitleMessage from '../../components/shared/TitleMessage'

const Institution = () => {
  const { token } = useParams()
  const { url } = getWorkspaceParams()
  const tutorialStatus = getDemoMeshStatus()
  const navigate = useNavigate()
  const user = getUser()

  const [courseCount, setCourseCount] = useState<number>(0)
  const [career, setCareer] = useState<any>(null)
  const [institution, setInstitution] = useState<any>(null)
  const [percentage, setPercentage] = useState<string>('??')
  const [showPanel, setShowPanel] = useState<boolean>(false)
  const [messagePanel, setMessagePanel] = useState<string>('')
  const [statusSelectedOption, setStatusSelectedOption] = useState<'HELP' | 'CONTACT'>('HELP')

  const [run, setRunTutorial] = useState<boolean>(false)
  const [stepList, setStepList] = useState<any>([])
  const [step, setStepTutorial] = useState<number>(0)

  const [loadingTutorial, setLoadingTutorial] = useState<boolean>(true)

  const getCoursesCount = (institution_id: string, career_id: string) => {
    getCountByInstitutionAndCareer(institution_id, career_id)
      .then(count => {
        setCourseCount(count)
      })
  }

  const selectNeedHelp = () => {
    setMessagePanel(`Te recomiendo contactar urgentemente con ${institution.name} para comunicarles tu interés por la carrera que acabas de revisar.`)
    setStatusSelectedOption('HELP')
    showPanelLoad()
  }

  const selectContactInstitution = () => {
    setMessagePanel(`Contacta con la ${institution.name} para comunicarles tu interés por la carrera que acabas de revisar.`)
    setStatusSelectedOption('CONTACT')
    showPanelLoad()
  }

  const showPanelLoad = () => {
    setShowPanel(true)
  }

  const hidePanel = () => {
    setShowPanel(false)
  }

  const getMesh = () => {
    if (!token) return

    getMeshWithData(token)
      .then(data => {
        if (data.status) {
          setInstitution(data.institution)
          setCareer(data.career)
          setPercentage(data.percentage)
          getCoursesCount(data.institution.id, data.career.career_id)
        }
      })
  }

  useEffect(() => {
    getMesh()
    if (tutorialStatus) {
      setStepList([...MESH])
      setStepTutorial(2)
      setRunTutorial(true)
      setLoadingTutorial(false)
    }
  }, [])

  const handleCallback = (data: any) => {
    if (!run) return

    const { action, index, type } = data

    if (action === 'next' && index === step && type === 'step:after') setStepTutorial(step + 1)
    if (action === 'prev' && index === step && type === 'step:after') setStepTutorial(step - 1)
    document.querySelectorAll('.joyride-element-show').forEach(function (elemento) {
      elemento?.classList.remove('joyride-element-show')
    })

    if (data.step?.target !== 'body' && !!data.step?.target) {
      const targetElements = document.querySelectorAll(data.step?.target)

      if (typeof data.step.index === 'number' && data.step.index >= 0 && data.step.index < targetElements.length) {
        targetElements.forEach((element, index) => {
          if (index === data.step.index) {
            element.classList.add('joyride-element-show')
          } else {
            element.classList.remove('joyride-element-show')
          }
        })
      } else {
        targetElements.forEach(element => {
          element.classList.add('joyride-element-show')
        })
      }
    }

    if (type === 'tour:end') {
      setLoadingTutorial(true)
      setStepTutorial(0)
      setRunTutorial(false)
    }
  }

  return (
    <article id="institution" className='bg-main-alt'>
      {
        !loadingTutorial && (
          <Joyride
            callback={handleCallback}
            steps={stepList}
            continuous
            hideCloseButton
            disableScrolling
            disableCloseOnEsc
            disableOverlayClose
            showSkipButton={false}
            stepIndex={step}
            run={run}
            tooltipComponent={IntroTooltip}
            styles={{
              options: {
                arrowColor: '#60DFFF',
                overlayColor: 'rgba(0, 0, 12, 0.9)'
              }
            }}
          />
        )}
      <section className="w-screen h-screen quiz-screen-background flex flex-col pt-6 px-6 pb-4 max-[480px]:py-0 max-[480px]:px-0">
        <DashboardMenu currentPage={2} logoPosition='TOP' />
        <div className="w-full h-[50px] flex justify-between max-[480px]:h-auto max-[480px]:mb-[10px] max-[480px]:px-3.5">
          <div className="w-full flex items-center justify-between max-[480px]:flex-col max-[480px]:gap-y-4 max-[480px]:w-full">
            <div className="flex items-center max-[480px]:gap-y-4 max-[480px]:w-full">
              {
                // career != null &&
                <Link to={`/${url}/career/${career?.career_id}`} className={`institution-wrap w-[250px] h-[50px] ${isMobile() ? '' : 'dashboard-gradient'} bg-cover flex items-center justify-start gap-x-4 px-4 border-2 border-t-[#ffffff86] border-l-[#ffffff86] border-r-[#ffffff86] border-b-0 rounded-tl-[30px] rounded-tr-[8px] transition-all ease-in-out duration-200 ${tutorialStatus ? 'opacity-70' : 'opacity-50'} hover:opacity-100 max-[480px]:w-[50px] max-[480px]:h-[60px] max-[480px]:border-none max-[480px]:p-0 max-[480px]:self-start max-[480px]:pt-[5px] max-[480px]:opacity-100`}>
                  <div className="w-[30px] h-[30px] rounded-full border border-[#ffffff7a] flex items-center justify-center hover:scale-105 hover:opacity-100 max-[480px]:w-[32px] max-[480px]:h-[32px]">
                    <img src={iconBackWhite} alt="icon-back" width="12" height="12" />
                  </div>
                  {
                    !isMobile() &&
                    <div className="flex flex-col items-start justify-center">
                      <p className="ff-m--regular text-white text-[12px] leading-none">Volver</p>
                      <p className="ff-cp--bold text-white text-[16px] leading-none">Instituciones</p>
                    </div>
                  }
                </Link>
              }
              <div className="mt-[4px] z-[99] w-[310px] h-[50px] dashboard-gradient bg-cover flex items-center justify-start gap-x-4 px-4 border-2 border-t-[#fff] border-l-[#fff] border-r-[#fff] border-b-[#320a4e] rounded-tl-[30px] rounded-tr-[8px] max-[480px]:mt-0 max-[480px]:h-auto max-[480px]:border-none max-[480px]:w-full max-[480px]:justify-center max-[480px]:bg-none max-[480px]:pr-[45px]">
                {
                  !isMobile() &&
                  <img src={iconHeart} alt="logo" width="30" height="30" />
                }
                <p className="text-white ff-cp--bold text-[20px] leading-none">Malla curricular</p>
              </div>
            </div>
            {
              !isMobile() &&
              <div className="flex items-center">
                <div className="w-[36px] h-[36px] rounded-full border-2 border-white overflow-hidden mr-2">
                  <img src={avatarDefault} alt="" className="w-[36px] h-[36px]" />
                </div>
                <p className="ff-cp--bold text-[20px] text-white whitespace-nowrap">
                  {
                    user &&
                    user.name + ' ' + user.last_name
                  }
                </p>
              </div>
            }
          </div>
        </div>
        <div className="w-full h-[calc(100%-110px)] flex-1 flex dashboard-gradient border-2 border-white/[.8] rounded-b-xl rounded-tl-none rounded-tr-[50px] max-[480px]:border-[1px] max-[480px]:w-[calc(100%-25px)] max-[480px]:mx-auto max-[480px]:rounded-2xl max-[480px]:h-[calc(100%-65px)] max-[480px]:rounded-b-none max-[480px]:border-b-0">
          {
            !isMobile() &&
            <div className="h-full sidebar-border pr-[15px]">
              <CardCareerDetail currentCareer={career} />
            </div>
          }

          <div className="institution-wrap w-full h-[calc(100%-110px)] overflow-auto px-[37px] py-5 rounded-tr-[20px] rounded-br-[20px] min-h-full grid justify-items-center items-center max-[480px]:overflow-hidden max-[480px]:px-1 max-[480px]:py-4 max-[480px]:h-[calc(100%-65px)]">
            <div className="px-[35px] max-[480px]:h-full max-[480px]:flex max-[480px]:flex-col max-[480px]:px-2">
              <div>
                <h2 className="ff-cp--bold text-[14px] xl:text-[20px] text-center leading-tight text-white mb-3">{institution?.name}</h2>
                <div className="flex justify-center gap-[20px]">
                  <span className={`block w-[81px] h-[14px] pt-[1px] ff-cp--bold px-[8px] mb-[6px] text-white text-center text-[8.5px] ${institution?.type === 'UNIVERSITY' ? 'bg-[#A533FF]' : 'bg-[#008AC5]'} rounded-xl`}>
                    {institution?.type === 'UNIVERSITY' ? 'Universidad' : 'Instituto'}
                  </span>
                  <span className={`block w-[81px] h-[14px] pt-[1px] ff-cp--bold px-[8px] ${institution?.gestion === 'PRIVATE' ? 'bg-[#7D3BF3]' : 'bg-[#006196]'} text-white text-center text-[8.5px] rounded-xl`}>
                    {institution?.gestion === 'PRIVATE' ? 'Privado' : 'Público'}
                  </span>
                </div>

                <div className="max-w-[853px] h-[14px] xl:mb-[12px] max-[480px]:opacity-50" style={{ backgroundImage: `url(${separator})` }}></div>
              </div>

              <div className="max-[480px]:m-auto">
                <div className="grid justify-items-center max-[480px]:px-3">

                  <div className={`${percentage !== '??' ? 'w-[291px] h-[266px]' : 'w-[183px] h-[163px] xl:w-[331px] xl:h-[296px]'} flex justify-center items-center bg-cover mb-[-30px] max-[480px]:mb-1.5`} style={{ backgroundImage: `url(${bgHearthBig})` }}>
                    <div className="ff-cp--bold text-[55px] text-white">{percentage}{percentage !== '??' && <span className="text-[35px]">%</span>}</div>
                  </div>

                  <p className="ff-cp--bold text-[20px] xl:text-[28px] text-center text-white leading-none mb-[5px] xl:mb-[20px] max-[480px]:text-[14px]">¿Serán tal para cuál?</p>
                  <p className="text-[12px] xl:text-[16px] text-center text-white leading-none mb-[20px] max-[480px]:text-[11px]">Revisa la malla y descubre que tan compatibiles son</p>

                  {/* <p className="text-white">{courseCount} cursos</p> */}
                </div>
                {courseCount > 0 &&
                  <div className={`max-w-[650px] grid ${!tutorialStatus && percentage !== '??' ? 'grid-cols-3 gap-3' : 'items-end place-content-center'}  justify-items-center`}>
                    {
                      !tutorialStatus && percentage !== '??' &&
                      <button
                        onClick={selectNeedHelp}
                        className="w-[145px] h-[38px] xl:w-[230px] xl:h-[65px] mt-[45px] text-center flex items-center pt-[12px] px-6 text-white text-[14px] place-content-center bg-cover transition-all duration-200 hover:scale-105"
                        style={{ backgroundImage: `url(${btnResult})` }}
                      >
                        <span className="ff-cp--regular text-center font-bold leading-3 tracking-normal">Necesito ayuda</span>
                      </button>
                    }
                    <Link to={`/${url}/courses/${token}`} className={`${run ? 'w-[145px] h-[38px] xl:w-[280px] xl:h-[65px]' : 'w-[145px] h-[38px] xl:w-[215px] xl:h-[50px]'} bg-cover text-[12px] xl:text-[20px] text-white flex justify-center items-center bg-center bg-no-repeat transition-all duration-200 hover:scale-105 max-[480px]:h-[36px] max-[480px]:text-[14px] max-[480px]:w-[180px]`} style={{ backgroundImage: `url(${run ? btnMeshLight : btnMesh})` }}>
                      <span className="text-center ff-cp--bold">Ver malla</span>
                    </Link>
                    {
                      !tutorialStatus && percentage !== '??' &&
                      <button
                        onClick={selectContactInstitution}
                        className="w-[145px] h-[38px] xl:w-[230px] xl:h-[65px] mt-[45px] text-center flex items-center pt-[12px] px-6 text-white text-[14px] place-content-center bg-cover transition-all duration-200 hover:scale-105"
                        style={{ backgroundImage: `url(${btnResult})` }}
                      >
                        <span className="ff-cp--regular text-center font-bold leading-3 tracking-normal">Sí, me decidí por esta carrera</span>
                      </button>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        {
          !isMobile() &&
          <div className="w-full h-[50px]"></div>
        }
      </section>
      {
        showPanel &&
        <div className={'w-full h-full fixed inset-0 bg-[#070625]/[.5] flex items-center justify-center delay-1000 overflow-hidden z-[120]'} style={{ transition: 'height ease 1200ms' }}>
          <div className="rounded-full h-[90%] aspect-square p-[9px] grid justify-self-center bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${burblePopup})` }}>
            <div className="w-full h-full rounded-full flex flex-col items-center justify-center pt-20">
              <div className="w-[120%] justify-self-center">
                <TitleMessage
                  index={0}
                  useLoading={false}
                  size="w-full h-auto pl-[22%]"
                  title="¡Felicitaciones! si deseas puedes seguir explorando más"
                  message={messagePanel}
                />
              </div>
              <div className="w-full flex justify-center gap-x-14">
                <ButtonStar text='Cerrar' type='purple' callback={hidePanel}></ButtonStar>
                <ButtonStar
                  text={statusSelectedOption === 'CONTACT' ? 'Contactar con la Universidad' : 'Revisar otras Mallas'}
                  type='purple'
                  callback={statusSelectedOption === 'CONTACT' ? () => window.open('https://wa.me/999999999', '_blank') : () => navigate(`/${url}${PAGES.CAREER}${career?.career_id ?? ''}`)}
                />
              </div>
            </div>
          </div>
        </div>
      }
    </article>
  )
}

export default Institution

import { API_ROUTES } from '../../constants/api_routes'
import { headers } from '../../constants/connection'

export const getAllByInstitutionAndCareer = async (institution_id: string, career_id: string) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.COURSE.GET.ALL.BY.INSTITUTION_CAREER}${institution_id}/${career_id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    }
  })
    .then((response) => response.json())
    .then(data => {
      if (data.status) return data
      return { courses: [], count: 0, mesh_id: '' }
    })
    .catch(error => {
      console.log(error)
      return { courses: [], count: 0, mesh_id: '' }
    })
}

export const getCountByInstitutionAndCareer = async (institution_id: string, career_id: string) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.COURSE.GET.COUNT.BY.INSTITUTION_CAREER}${institution_id}/${career_id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    }
  })
    .then((response) => response.json())
    .then(data => {
      if (data.status) return data.count
      return 0
    })
    .catch(error => {
      console.log(error)
      return 0
    })
}

import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './app/App'
import reportWebVitals from './reportWebVitals'
import { AuthContextProvider } from './contexts/authContext'
import './assets/styles/app.scss'
import TagManager from 'react-gtm-module'

const tagManagerArgs: any = {
  gtmId: process.env.REACT_APP_GTM_ID
}

TagManager.initialize(tagManagerArgs)

const children = (
<AuthContextProvider>
  <Router>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Router>
</AuthContextProvider>
)

const container = document.getElementById('root')

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children) // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { useEffect, useState } from 'react'

// Services
import { getWorkspaceById } from '../../../services/apis/workspace'

// Methods
import { getUser } from '../../../common/methods/storage'

// Components
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import AppMessage from '../../../components/shared/AppMessage'

// Constants
import { PAGES } from '../../../constants/Pages'

const Sponsored = () => {
  const user = getUser()

  const [workspace, setWorkspace] = useState<any>(null)

  const getWorkspace = async () => {
    if (user) {
      await getWorkspaceById(user.workspace_id)
        .then(result => {
          if (result.status) {
            setWorkspace(result.data)
          }
        })
    }
  }

  useEffect(() => {
    getWorkspace()
  }, [])

  return (
        <article className="bg-main bg-oldwoman bg-compass" id="home">
            <section className="w-screen h-screen overflow-hidden relative cloud-page py-4">
                <div className="gradient-purple" />
                <ButtonRedirect action="return" linkReturn={PAGES.DECISION_1} text="return" />
                <BadgeStep currentStep={3} />
                <ButtonRedirect action="next" linkReturn={PAGES.QUESTION} text="return" />
                <AppMessage
                    index={0}
                    useAnimationLoading={false}
                    size="w-1/2 ax-w-[740px] h-auto"
                    message={`Este viaje de autodescubrimiento será acompañado por <br />"${workspace?.title}"`} />
            </section>
        </article>
  )
}

export default Sponsored

export const API_ROUTES = {
  MAIL: '/mail/send',
  USER: {
    GET: {
      ALL: '/users'
    },
    UPDATE: {
      TUTORIAL: '/users/setStatusTutorial/',
      DEMO: '/users/setDemoUserStatus',
      DEMO_CAREER: '/users/setDemoCareerStatus',
      DEMO_MESH: '/users/setDemoMeshStatus',
      DEMO_MENU: '/users/setDemoMenuStatus'
    }
  },
  AUTH: {
    CHECK: '/auth/checkEmail',
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    FORGOT: {
      PASSWORD: '/auth/forgotPassword',
      RESET: '/auth/resetPassword'
    }
  },
  RECORD: {
    CREATE: '/records',
    UPDATE: '/records/',
    TUTORIAL: '/records/setStatusDemo/',
    FAVOURITE: '/records/setStatusFavourite/',
    GET: {
      ALL: '/records',
      CURRENT: '/records/getCurrent',
      VOCATIONAL: '/records/getVocationalProfile/',
      CALCULATED_VOCATIONAL: '/records/getCalculatedVocationalProfile/',
      BY: {
        ID: '/records/'
      }
    }

  },
  CAREER: {
    GET: {
      ALL: '/careers',
      WITH: {
        CLASSIFICATION: '/careers/getAllWithClassification/',
        REFERENCES: '/careers/getAllWithReferences/'
      }
    }
  },
  INSTITUTION: {
    GET: {
      ALL: {
        ONLY: '/institutions',
        BY: {
          CAREER: '/institutions/getByCareerId/'
        }
      },
      WITH: {
        CLASSIFICATION: '/careers/getAllWithClassification/'
      }
    }
  },
  CAREER_REFERENCE: {
    GET: {
      ALL: '/careersReferences',
      BY: {
        USER: {
          COUNT: '/careersReferences/getCountByUser/',
          ALL: '/careersReferences/getAllByUser/'
        }
      }
    },
    CREATE: {
      ONLY: '/careersReferences',
      BY: {
        LIST: '/careersReferences/createByList'
      }
    },
    DELETE: '/careersReferences/',
    DELETE_BY_USER: '/careersReferences/delete/deleteByUserId'
  },
  FEATURE: {
    GET: {
      ONE: {
        BY: {
          ID: '/features/'
        }
      },
      ALL: {
        BY: {
          TYPE: '/features/getAllByType/',
          PARENT: {
            ID: '/features/getAllByParentId/'
          }
        }
      }
    }
  },
  PROFILE: {
    CREATE: {
      BY: {
        LIST: '/profiles/createByList'
      }
    }
  },
  COURSE: {
    GET: {
      ALL: {
        BY: {
          INSTITUTION_CAREER: '/courses/getAllByInstitutionIdAndCareerId/'
        }
      },
      COUNT: {
        BY: {
          INSTITUTION_CAREER: '/courses/getCountByInstitutionIdAndCareerId/'
        }
      }
    }
  },
  COURSE_LIKE: {
    CREATE: {
      BY: {
        LIST: '/courseLikes/createByList'
      }
    }
  },
  MESH: {
    GET: {
      RESULT: '/meshs/getWithResult/',
      REVIEWED: '/meshs/getReviewedWithResult/',
      BY: {
        ID: {
          RESULT: '/meshs/getByIdWithData/'
        }
      }
    }
  }

}

import cardReactionNone from '../assets/images/cards/card-noun-no-reaction.svg'
import cardReactionDislike from '../assets/images/cards/card-noun-reaction-dislike.svg'
import cardReactionDude from '../assets/images/cards/card-noun-reaction-dude.svg'
import cardReactionLike from '../assets/images/cards/card-noun-reaction-like.svg'
import cardReactionLove from '../assets/images/cards/card-noun-reaction-love.svg'

export const CARD_REACTION = {
  LOVE: cardReactionLove,
  LIKE: cardReactionLike,
  'N/A': cardReactionDude,
  DISLIKE: cardReactionDislike,
  NONE: cardReactionNone
}

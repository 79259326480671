import { getRecord } from '../../common/methods/storage'
import { API_ROUTES } from '../../constants/api_routes'
import { headers } from '../../constants/connection'

export const getResultMesh = async (mesh_id: string) => {
  const record = getRecord()

  if (record) {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.MESH.GET.RESULT}${record.id}/${mesh_id}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      }
    })
      .then((response) => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
        console.log(error)
        return {}
      })
  }
}

export const getReviewedResultMesh = async ({ signal, page, size, gestion, level, name } : { signal?: AbortSignal, page: number, size: number, gestion: string, level: string, name: string }) => {
  const record = getRecord()

  if (record) {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.MESH.GET.REVIEWED}${record.id}?page=${page}&size=${size}&gestion=${gestion}&level=${level}&name=${name}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      signal
    })
      .then((response) => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
        console.log(error)
        return []
      })
  }
}

export const getMeshWithData = async (mesh_id: string) => {
  const record = getRecord()

  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.MESH.GET.BY.ID.RESULT}${record.id}/${mesh_id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    }
  })
    .then((response) => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.log(error)
      return []
    })
}

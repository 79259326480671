import { Link, useLocation } from 'react-router-dom'
import PageTitle from '../../../components/shared/PageTitle'
import { forgotPassword } from '../../../services/apis/auth'
import btnBack from '../../../assets/images/icons/icon-back.svg'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import { getWorkspaceParams } from '../../../common/methods/params'

const RecoverySendEmail = () => {
  const location = useLocation()

  const { url } = getWorkspaceParams()

  const onResendEmail = async () => {
    await forgotPassword({ email: location.state.email, role: 'STUDENT' })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <section className="recovery-page">
      <div className="grid grid-cols-5 grid-rows-1 w-screen h-screen">
        <ButtonRedirect action="return" linkReturn="/recovery/reset-password" text="return"></ButtonRedirect>
        <PageTitle
          title={{ text: 'Enviamos un correo a', color: '#60DFFF' }}
          subtitle={{ text: location.state.email, size: 'text-[50px]' }}
          useLink={true} useExtra={true}
          extra={<div className="mt-5 mb-1 text-gray-400 font-normal">¿No recibiste el correo?
            <span onClick={onResendEmail} className="font-semibold cursor-pointer"> Click aquí para reenviar.</span></div>}
          link={<div><Link to={`/${url}/login`} className="text-[16px] text-[#7F29FE] flex gap-2">
            <img src={btnBack} alt="" className="w-[16px] h-[16px] mt-1" /> Regresar al log in</Link></div>} />
      </div>
    </section>
  )
}

export default RecoverySendEmail

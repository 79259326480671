import React, { useState } from 'react'
import { TooltipRenderProps } from 'react-joyride'
import optionAcceptedLove from '../../assets/images/buttons/option-accepted.svg'
import optionAcceptedLoveSelected from '../../assets/images/buttons/option-accepted-selected.svg'
import optionAcceptedLike from '../../assets/images/buttons/option-accepted-like.svg'
import optionAcceptedLikeSelected from '../../assets/images/buttons/option-accepted-like-selected.svg'
import optionAcceptedDude from '../../assets/images/buttons/option-accepted-dude.svg'
import optionAcceptedDudeSelected from '../../assets/images/buttons/option-accepted-dude-selected.svg'
import optionAcceptedDislike from '../../assets/images/buttons/option-accepted-dislike.svg'
import optionAcceptedDislikeSelected from '../../assets/images/buttons/option-accepted-dislike-selected.svg'

import optionDenied from '../../assets/images/buttons/option-denied.svg'
import optionDeniedSelected from '../../assets/images/buttons/option-denied-selected.svg'
import viejaDestino from '../../assets/images/intro/bg-vieja-destino-rtl.png'

const ReactionTooltip = ({
  backProps,
  continuous,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  size,
  tooltipProps
}: TooltipRenderProps) => {
  const option_like = (step.title as string).includes('amas') ? optionAcceptedLove : ((step.title as string).includes('gusta') ? optionAcceptedLike : (step.title as string).includes('no te agrada') ? optionAcceptedDislike : optionAcceptedDude)

  const option_like_selected = (step.title as string).includes('amas') ? optionAcceptedLoveSelected : ((step.title as string).includes('gusta') ? optionAcceptedLikeSelected : (step.title as string).includes('no te agrada') ? optionAcceptedDislikeSelected : optionAcceptedDudeSelected)

  const [optionCancel, setOptionCancel] = useState<string>(optionDenied)
  const [optionAccepted, setOptionAccepted] = useState<string>(option_like)

  return (
        <div className="relative w-[100%] h-[250px]">
            <div
            className="bg-[#280772] border border-white border-1 rounded-[10px] h-[200px] w-[800px] shadow-sm"
            {...tooltipProps}
            >
                <div className="ff-cp--light text-[27px] text-center px-[60px] py-[60px] leading-tight text-[#FEDFFF]">
                   <p className="text-center">{step.content && <span>{step.title}{step.content}</span>}</p>
                </div>
            </div>

            <div className="absolute right-[70px] px-[14px] pb-[12px] -bottom-[5px] w-[350px]">
                <div className="flex justify-center">
                    <div className="flex gap-[15px]">
                        <button {...skipProps} className="w-[120px] h-[80px] contents"
                            onMouseOver={() => setOptionCancel(optionDeniedSelected)}
                            onMouseOut={() => setOptionCancel(optionDenied)}
                        >
                            <span className="w-full h-full" id="back"><img className="w-full h-full" src={optionCancel} alt="" /></span>
                        </button>
                        <button {...primaryProps} className="w-[120px] h-[80px] contents"
                            onMouseOver={() => setOptionAccepted(option_like_selected)}
                            onMouseOut={() => setOptionAccepted(option_like)}
                        >
                            <span className="w-full h-full"><img className="w-full h-full" src={optionAccepted} alt="" /></span>
                        </button>
                    </div>
                </div>
            </div>

                <div className="absolute -right-[22%] -top-[25%] bottom-0">
                    <img className="w-[300px] h-[380px]" src={viejaDestino} alt="" />
                </div>
        </div>
  )
}

export default ReactionTooltip

import { API_ROUTES } from '../../constants/api_routes'
import { headers } from '../../constants/connection'

export const sendMail = async (emails: string, reason: string, token: string, domain: string, group: string) => {
  const body = {
    emails,
    reason,
    token,
    role: 'STUDENT',
    domain,
    group

  }
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.MAIL}`, {
    method: 'POST',
    headers: {
      ...headers
    },
    body: JSON.stringify(body)
  })
    .then((response) => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

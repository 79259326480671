import { headers } from '../../constants/connection'
import { API_ROUTES } from './../../constants/api_routes'
import { sendMail } from './mail'
import { getCurrentRecord } from './record'

export const authLogin = async (user: any) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.AUTH.LOGIN}`, {
    method: 'POST',
    headers: {
      ...headers
    },
    body: JSON.stringify(user)
  }).then((response) => response.json())
    .then(async (data_auth) => {
      let result: any = {}
      result = { ...data_auth }
      if (data_auth.status) {
        localStorage.setItem('tcm_access_token', data_auth.access_token)
        localStorage.setItem('tcm_auth_user', JSON.stringify(data_auth.user))
        await getCurrentRecord()
          .then(async (data_record: any) => {
            if (data_record.status) {
              result = {
                ...result,
                page: data_record.page
              }
            }
          })
      }

      return result
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

export const register = async (user: any) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.AUTH.REGISTER}`, {
    method: 'POST',
    headers: {
      ...headers
    },
    body: JSON.stringify(user)
  }).then((response) => response.json())
    .then(async (data) => {
      let result: any = {}
      result = { ...data }
      if (data.status) {
        localStorage.setItem('tcm_access_token', data.access_token)
        localStorage.setItem('tcm_auth_user', JSON.stringify(data.user))
        await getCurrentRecord()
          .then((data_record: any) => {
            if (data_record.status) {
              result = {
                ...result,
                page: data_record.page
              }
            }
          })
          .catch(err => {
            result = err
          })
      } else {
        result = { ...data }
      }
      return result
    })
    .catch(() => {
      return false
    })
}

export const forgotPassword = async (user: any) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.AUTH.FORGOT.PASSWORD}`, {
    method: 'POST',
    headers: {
      ...headers
    },
    body: JSON.stringify(user)
  }).then((response) => response.json())
    .then(async (data) => {
      if (data.status) {
        await sendMail(user.email, 'recovery', `Bearer ${data.recovery_token}`, user.domain, user.group)
      }
      return data
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

export const checkEmail = async (email: any, domain: string, group: string) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.AUTH.CHECK}`, {
    method: 'POST',
    headers: {
      ...headers
    },
    body: JSON.stringify({ email, domain, group })
  }).then((response) => response.json())
    .then(async (data) => {
      if (data.status) {
        return data.check
      }
      return false
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

export const resetPassword = async (user: any) => {
  const body = {
    password: user.password
  }
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.AUTH.FORGOT.RESET}`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `${user.token}`
    },
    body: JSON.stringify(body)
  }).then((response) => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

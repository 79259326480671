import { getRecord, setRecord } from '../../common/methods/storage'
import { headers } from '../../constants/connection'
import { API_ROUTES } from './../../constants/api_routes'

interface RecordProps {
    step?: number,
    country?: string | null,
    department1?: string | null,
    department2?: string | null,
    department3?: string | null,
    type?: string | null,
    conversations?: string | null
}

export const getAllRecords = async () => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.RECORD.GET.ALL}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    }
  })
    .then((response) => response.json())
    .then(data => {
      if (data.status) {
        return data.data
      } else {
        return []
      }
    })
    .catch(error => {
      console.log(error)
      return []
    })
}

export const createRecord = async (record?: any) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.RECORD.CREATE}`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    },
    body: JSON.stringify(record)
  })
    .then((response) => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

export const getCurrentRecord = async () => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.RECORD.GET.CURRENT}`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    }
  })
    .then((response) => response.json())
    .then(data => {
      if (data.status) {
        setRecord({
          ...data.record,
          features: data.features,
          profiles: data.profiles,
          shield: data.shield,
          shieldTitle: data.shieldTitle,
          charge_result: true,
          references: data.references,
          favourite_courses: data.favourite_courses,
          lastCareerType: data.lastCareerType,
          careers: []
        })
      }
      return data
    })
    .catch(() => {
      return false
    })
}

export const getRecordById = async () => {
  const record = getRecord()

  if (record) {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.RECORD.GET.BY.ID}${record.id}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      }
    })
      .then((response) => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
        console.log(error)
        return false
      })
  }
}

export const getVocationalProfile = async () => {
  const record = getRecord()

  if (record) {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.RECORD.GET.VOCATIONAL}${record.id}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      }
    })
      .then((response) => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
        console.log(error)
        return false
      })
  }
}

export const getCalculatedVocationalProfile = async () => {
  const record = getRecord()
  if (record) {
    const result: { profiles: any[], shield: string, shieldTitle: string } = { profiles: [], shield: '', shieldTitle: '' }
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.RECORD.GET.CALCULATED_VOCATIONAL}${record.id}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      }
    })
      .then((response) => response.json())
      .then(data => {
        if (data.status) {
          result.profiles = data.profiles
          result.shield = data.shield
          result.shieldTitle = data.shieldTitle
        }

        return result
      })
      .catch(() => {
        return false
      })
  }
}

export const updateRecord = async ({ step, country, department1, department2, department3, type, conversations }: RecordProps) => {
  const record = getRecord()

  if (record) {
    record.step = step
    record.country = country ?? record.country
    record.department_1 = department1 ?? record.department_1
    record.department_2 = department2 ?? record.department_2
    record.department_3 = department3 ?? record.department_3
    record.type = type ?? record.type
    record.conversations = conversations ?? record.conversations

    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.RECORD.UPDATE}${record.id}`, {
      method: 'PATCH',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      body: JSON.stringify(record)
    })
      .then((response) => response.json())
      .then(() => {
        if (step) {
          record.current_step = step + 1
        }
        setRecord(record)
      })
  }
}

export const setTutorialStatus = async (status: boolean) => {
  const record = getRecord()
  if (record) {
    const body = { status }
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.RECORD.TUTORIAL}${record.id}`, {
      method: 'PATCH',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      body: JSON.stringify(body)
    })
      .then((response) => response.json())
      .then(data => {
        if (data.status) {
          setRecord({
            ...record,
            demo: status
          })
        }
      })
      .catch(() => {
        return false
      })
  }
}

export const setFavouriteStatus = async (favourite: boolean) => {
  const record = getRecord()
  if (record) {
    const body = { favourite }
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.RECORD.FAVOURITE}${record.id}`, {
      method: 'PATCH',
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
      },
      body: JSON.stringify(body)
    })
      .then((response) => response.json())
      .then(data => {
        if (data.status) {
          setRecord({
            ...record,
            favourite_courses: favourite
          })
        }
      })
      .catch(() => {
        return false
      })
  }
}

import { useEffect, useState } from 'react'

// Constants
import { REACTIONS } from '../../constants/reactions'

// Images
import BtnDislikeSelected from '../../assets/images/buttons/btn-dislike-selected.svg'
import BtnDudeSelected from '../../assets/images/buttons/btn-dont-selected.svg'
import BtnLikeSelected from '../../assets/images/buttons/btn-like-selected.svg'
import BtnOption from '../../assets/images/buttons/btn-star-deselected.svg'
import OptionDislike from '../../assets/images/buttons/option-dislike.svg'
import OptionLike from '../../assets/images/buttons/option-like.svg'
import OptionLove from '../../assets/images/buttons/option-love.svg'
import OptionDislikeSelected from '../../assets/images/icons/icon-dislike-active.svg'
import OptionDislikeFinger from '../../assets/images/icons/icon-dislike.svg'
import OptionDudeSelected from '../../assets/images/icons/icon-dont-active.svg'
import OptionDude from '../../assets/images/icons/icon-dont.svg'
import OptionLikeSelected from '../../assets/images/icons/icon-like-active.svg'
import OptionLikeFinger from '../../assets/images/icons/icon-like.svg'

interface ButtonProps {
    url: string;
    text?: string;
    type?: string;
    isSelected: boolean,
    useIcon?: boolean,
    width?: string,
    callback: () => void
}

const OptionButton = ({ url, isSelected = false, type = 'NORMAL', width, callback, useIcon = true, text }: ButtonProps) => {
  const [currentState, setCurrentState] = useState('')
  const [cardSelected, setCardSelected] = useState('')
  const [iconSelected, setIconSelected] = useState('')

  const getImageURL = () => {
    let image = ''
    if (url === REACTIONS.DISLIKE) {
      image = type === 'NORMAL' ? OptionDislike : OptionDislikeFinger
    } else if (url === REACTIONS.DUDE) {
      image = OptionDude
    } else if (url === REACTIONS.LIKE) {
      image = OptionLike
    } else {
      image = type === 'NORMAL' ? OptionLove : OptionLikeFinger
    }
    return image
  }

  const getCardSelectedURL = () => {
    let image = ''
    if (url === REACTIONS.DISLIKE) {
      image = BtnDislikeSelected
    } else if (url === REACTIONS.DUDE) {
      image = BtnDudeSelected
    } else if (url === REACTIONS.LIKE) {
      image = BtnLikeSelected
    } else {
      image = BtnLikeSelected
    }
    return image
  }

  const getIconSelectedURL = () => {
    let image = ''
    if (url === REACTIONS.DISLIKE) {
      image = OptionDislikeSelected
    } else if (url === REACTIONS.DUDE) {
      image = OptionDudeSelected
    } else if (url === REACTIONS.LIKE) {
      image = OptionLikeSelected
    } else {
      image = OptionLikeSelected
    }
    return image
  }

  useEffect(() => {
    const imageUrl = getImageURL()
    const cardSelectedUrl = getCardSelectedURL()
    const iconSelectedUrl = getIconSelectedURL()
    setCurrentState(imageUrl)
    setCardSelected(cardSelectedUrl)
    setIconSelected(iconSelectedUrl)
  }, [url])

  return (

    <div className={`tooltip relative flex flex-col items-center z-[420] ${width ?? 'w-[230px]'} ${type === 'FINGER' ? 'mt-[65px]' : ''}`}>
        <button
            onClick={callback}
            className={`w-[38px] h-[44px] xl:w-[58px] xl:h-[68px] bg-contain bg-no-repeat cursor-pointer transition ease-in ${isSelected ? 'scale-[1.8]' : 'hover:scale-110'}`}
            style={{ backgroundImage: `url(${isSelected ? iconSelected : currentState})` }}
        >
          {
            useIcon && !isSelected &&
            <div className="ff-cp--bold text-white text-center text-[12px] absolute -bottom-[15px] left-[45%] hover:hidden">
              <span>?</span>
            </div>
          }
        </button>

        <button onClick={callback} className={`bg-cover flex items-center justify-center pt-[2px] xl:pt-2 ${width ?? 'w-[230px]'} ${isSelected ? 'h-[44px] xl:h-[70px] max-[480px]:h-[36px]' : 'h-[41px] xl:h-[63px] max-[480px]:h-[34px]'} text-[12px] max-[480px]:text-[11px] xl:text-[14px] text-white`} style={{ backgroundImage: `url(${isSelected ? cardSelected : BtnOption})` }}>
            { text ?? (url === REACTIONS.DISLIKE ? 'No me gusta' : (url === REACTIONS.DUDE ? 'Me da igual' : 'Me gusta'))}
        </button>

      </div>

  )
}

export default OptionButton

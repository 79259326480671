import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Services
import { getWorkspaceParams } from '../../../common/methods/params'
import { setRecord } from '../../../common/methods/storage'
import { createRecord } from '../../../services/apis/record'

// Components
import AppMessage from '../../../components/shared/AppMessage'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import ButtonStar from '../../../components/shared/ButtonStar'

// Constants
import { isMobile } from '../../../common/methods/devices'
import DashboardMenu from '../../../components/shared/DarshboardMenu'
import { PAGES } from '../../../constants/Pages'

function ExperienceDecision () {
  const { url } = getWorkspaceParams()
  const navigate = useNavigate()

  const [isSelected, setIsSelected] = useState<any>(null)

  const handleChange = async (value: boolean) => {
    let nextPage = PAGES.DASHBOARD

    if (value) {
      nextPage = PAGES.DECISION_4
    }

    setIsSelected(value)
    if (!isMobile()) navigate(`/${url}${nextPage}`)
    else if (value) remakeQuiz()
  }

  const remakeQuiz = async () => {
    await createRecord({ type: 'search' })
      .then(data => {
        if (data.status) {
          const record = {
            ...data.record,
            features: [],
            profiles: [],
            shield: '',
            demo: true
          }
          setRecord(record)
          navigate(`/${url}${PAGES.CONVERSATION_1}`)
        }
      })
  }

  return (

    <article className="bg-main bg-oldwoman bg-compass" id="home">
        <section className="w-screen h-screen overflow-hidden quiz-screen-background cloud-page py-4 relative">
          <div className="gradient-purple"></div>

          <ButtonRedirect action="return" linkReturn={PAGES.DASHBOARD} text="return"/>
          <DashboardMenu currentPage={3} type='SIMPLE' showMainMenu={false} isTutorial={false} />
          <AppMessage
            index={0}
            useAnimationLoading={false}
            size="w-1/2 max-w-[800px] h-auto"
            title=""
            message="Si no estas seguro de lo que me has contado, siempre puedes revivir la experiencia. Pero ten en cuenta que tus selecciones anteriores se perderán."
          />

<div className="h-full">
              <div className="user-conversation-group z-[120]">
                <div className="flex gap-16 px-[75px]">
                    <ButtonStar
                        callback={() => handleChange(false)}
                        text='No se, me has dejado pensando'
                        type='white'
                        textSize='max-[480px]:text-[10.5px]'
                        selected={isSelected === false}
                    />
                    <ButtonStar
                        callback={() => handleChange(true)}
                        text='Si, quiero revivir la experiencia'
                        type='white'
                        textSize='max-[480px]:text-[10.5px]'
                        selected={isSelected === true}
                    />
                  </div>
              </div>
            </div>
        </section>
    </article>
  )
}

export default ExperienceDecision

import { API_ROUTES } from '../../constants/api_routes'
import { headers } from '../../constants/connection'

export const getAllFeaturesByType = async (type: string, isChildren: boolean = false) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.FEATURE.GET.ALL.BY.TYPE}${type}?isChildren=${isChildren}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    }
  })
    .then((response) => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

export const getAllFeaturesByParentId = async (parentId: string) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.FEATURE.GET.ALL.BY.PARENT.ID}${parentId}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    }
  })
    .then((response) => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

export const getFeatureById = async (id: string) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.FEATURE.GET.ONE.BY.ID}${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('tcm_access_token')}`
    }
  })
    .then((response) => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

import { useEffect, useState } from 'react'

// Images
import starDislike from '../../assets/images/star-status-dislike.svg'
import starDude from '../../assets/images/star-status-dude.svg'
import starLike from '../../assets/images/star-status-like.svg'
import starLove from '../../assets/images/star-status-love.svg'
import whiteStar from '../../assets/images/white-star.svg'
import { REACTIONS } from '../../constants/reactions'

const TalentCard = ({ title = '', state = 'null' }) => {
  const [starStatus, setStarStatus] = useState('DISLIKE')

  const changeStarStatus = () => {
    let starImage = ''

    if (state === REACTIONS.DISLIKE) starImage = starDislike
    else if (state === REACTIONS.DUDE) starImage = starDude
    else if (state === REACTIONS.LIKE) starImage = starLike
    else if (state === REACTIONS.LOVE) starImage = starLove

    setStarStatus(starImage)
  }

  useEffect(() => {
    changeStarStatus()
  }, [state])

  return (
    <div className="w-full flex flex-col gap-1 items-center">
      <div className="w-[52px] h-[52px] max-[480px]:w-[32px] max-[480px]:h-[32px] bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${starStatus})` }}></div>
      <img src={whiteStar} width='10' height='10' alt=''></img>
      <span className="ff-cp--bold text-base leading-4 text-white font-semibold max-[480px]:text-[12px]">{title}</span>
    </div>
  )
}

export default TalentCard

import machine from '../assets/images/card-nouns/card-noun-01d6e740-90a8-11ed-948b-834ce149ae0d.svg'
import human from '../assets/images/card-nouns/card-noun-2b7f4560-90a8-11ed-948b-834ce149ae0d.svg'
import others from '../assets/images/card-nouns/card-noun-748608c0-90a8-11ed-948b-834ce149ae0d.svg'
import nature from '../assets/images/card-nouns/card-noun-59155870-90a8-11ed-948b-834ce149ae0d.svg'
import art from '../assets/images/card-nouns/card-noun-f2b3b2e0-90a5-11ed-948b-834ce149ae0d.svg'
import art1 from '../assets/images/card-nouns/card-noun-986058c0-9122-11ed-b66e-69f87a45e458.svg'
import art2 from '../assets/images/card-nouns/card-noun-c1fbde40-9125-11ed-bb27-97b47bfa98cd.svg'
import art3 from '../assets/images/card-nouns/card-noun-f6b332f0-9125-11ed-bb27-97b47bfa98cd.svg'
import art4 from '../assets/images/card-nouns/card-noun-1059f8b0-9126-11ed-bb27-97b47bfa98cd.svg'
import art5 from '../assets/images/card-nouns/card-noun-2aeb1e70-9126-11ed-bb27-97b47bfa98cd.svg'
import art6 from '../assets/images/card-nouns/card-noun-3d2e9080-9126-11ed-bb27-97b47bfa98cd.svg'
import art7 from '../assets/images/card-nouns/card-noun-50e4f380-9126-11ed-bb27-97b47bfa98cd.svg'
import art8 from '../assets/images/card-nouns/card-noun-b1435c80-9126-11ed-bb27-97b47bfa98cd.svg'
import art9 from '../assets/images/card-nouns/card-noun-25f3fde0-9128-11ed-bb27-97b47bfa98cd.svg'
import art10 from '../assets/images/card-nouns/card-noun-35e6c930-9128-11ed-bb27-97b47bfa98cd.svg'
import machine1 from '../assets/images/card-nouns/card-noun-56235970-9128-11ed-bb27-97b47bfa98cd.svg'
import machine2 from '../assets/images/card-nouns/card-noun-67f014e0-9128-11ed-bb27-97b47bfa98cd.svg'
import machine3 from '../assets/images/card-nouns/card-noun-75e16ae0-9128-11ed-bb27-97b47bfa98cd.svg'
import machine4 from '../assets/images/card-nouns/card-noun-89316af0-9128-11ed-bb27-97b47bfa98cd.svg'
import human1 from '../assets/images/card-nouns/card-noun-e88fc1b0-912b-11ed-96ea-51d458e8221f.svg'
import human2 from '../assets/images/card-nouns/card-noun-20a27660-912c-11ed-96ea-51d458e8221f.svg'
import human3 from '../assets/images/card-nouns/card-noun-a36e6720-9131-11ed-805d-57e87a668058.svg'
import human4 from '../assets/images/card-nouns/card-noun-f9a75df0-912b-11ed-96ea-51d458e8221f.svg'
import human5 from '../assets/images/card-nouns/card-noun-8c6dad60-912c-11ed-96ea-51d458e8221f.svg'
import human6 from '../assets/images/card-nouns/card-noun-0f73f6c0-912c-11ed-96ea-51d458e8221f.svg'
import human7 from '../assets/images/card-nouns/card-noun-686ff670-912c-11ed-96ea-51d458e8221f.svg'
import human8 from '../assets/images/card-nouns/card-noun-d1e01be0-912b-11ed-96ea-51d458e8221f.svg'
import human9 from '../assets/images/card-nouns/card-noun-5390a3d0-912c-11ed-96ea-51d458e8221f.svg'
import human10 from '../assets/images/card-nouns/card-noun-7927bf70-912c-11ed-96ea-51d458e8221f.svg'
import human11 from '../assets/images/card-nouns/card-noun-32496810-9131-11ed-971c-3b7b7efa416f.svg'
import nature1 from '../assets/images/card-nouns/card-noun-07a6fd10-9132-11ed-805d-57e87a668058.svg'
import nature2 from '../assets/images/card-nouns/card-noun-1d4dbe60-9132-11ed-805d-57e87a668058.svg'
import nature3 from '../assets/images/card-nouns/card-noun-7f58f790-9133-11ed-805d-57e87a668058.svg'
import nature4 from '../assets/images/card-nouns/card-noun-91565ff0-9133-11ed-805d-57e87a668058.svg'
import nature5 from '../assets/images/card-nouns/card-noun-662c8760-9132-11ed-805d-57e87a668058.svg'
import nature6 from '../assets/images/card-nouns/card-noun-33d31fe0-9132-11ed-805d-57e87a668058.svg'
import nature7 from '../assets/images/card-nouns/card-noun-b057d820-9133-11ed-805d-57e87a668058.svg'
import nature8 from '../assets/images/card-nouns/card-noun-57ee22d0-9132-11ed-805d-57e87a668058.svg'
import nature9 from '../assets/images/card-nouns/card-noun-a028c720-9133-11ed-805d-57e87a668058.svg'
import other1 from '../assets/images/card-nouns/card-noun-05c871b0-9135-11ed-805d-57e87a668058.svg'
import other2 from '../assets/images/card-nouns/card-noun-f2581860-9134-11ed-805d-57e87a668058.svg'
import other3 from '../assets/images/card-nouns/card-noun-c98c85b0-9134-11ed-805d-57e87a668058.svg'
import other4 from '../assets/images/card-nouns/card-noun-dddf28b0-9134-11ed-805d-57e87a668058.svg'

import artConstelation from '../assets/images/constelation-f2b3b2e0-90a5-11ed-948b-834ce149ae0d.svg'
import machineConstelation from '../assets/images/constelation-01d6e740-90a8-11ed-948b-834ce149ae0d.svg'
import humanConstelation from '../assets/images/constelation-2b7f4560-90a8-11ed-948b-834ce149ae0d.svg'
import natureConstelation from '../assets/images/constelation-59155870-90a8-11ed-948b-834ce149ae0d.svg'
import otherConstelation from '../assets/images/constelation-748608c0-90a8-11ed-948b-834ce149ae0d.svg'

import btnPurpleReverse from '../assets/images/button-star-purple-reverse.svg'
import btnGreenReverse from '../assets/images/button-star-green-reverse.svg'
import btnLightBlueReverse from '../assets/images/button-star-light-blue-reverse.svg'
import btnOrangeReverse from '../assets/images/button-star-orange-reverse.svg'
import btnBlueReverse from '../assets/images/button-star-blue-reverse.svg'
import btnRedReverse from '../assets/images/button-star-red-reverse.svg'

import arrowRightOrange from '../assets/images/arrows/arrow-right-orange.svg'
import arrowLeftOrange from '../assets/images/arrows/arrow-left-orange.svg'
import arrowRightBlue from '../assets/images/arrows/arrow-right-blue.svg'
import arrowLeftBlue from '../assets/images/arrows/arrow-left-blue.svg'
import arrowRightGreen from '../assets/images/arrows/arrow-right-green.svg'
import arrowLeftGreen from '../assets/images/arrows/arrow-left-green.svg'
import arrowRightRed from '../assets/images/arrows/arrow-right-red.svg'
import arrowLeftRed from '../assets/images/arrows/arrow-left-red.svg'
import arrowRightLightBlue from '../assets/images/arrows/arrow-right-light-blue.svg'
import arrowLeftLightBlue from '../assets/images/arrows/arrow-left-light-blue.svg'

import starChartConqueror from '../assets/images/stars/star-chart-7d30fe80-91e3-11ed-be92-d7ec6112838f.svg'
import starChartWeaver from '../assets/images/stars/star-chart-9c58a6e0-91e4-11ed-be92-d7ec6112838f.svg'
import starChartDiver from '../assets/images/stars/star-chart-84740380-91e4-11ed-be92-d7ec6112838f.svg'
import starChartCreator from '../assets/images/stars/star-chart-f4819490-91e3-11ed-be92-d7ec6112838f.svg'
import starChartNature from '../assets/images/stars/star-chart-59155870-90a8-11ed-948b-834ce149ae0d.svg'
import starChartArts from '../assets/images/stars/star-chart-f2b3b2e0-90a5-11ed-948b-834ce149ae0d.svg'
import starCharHuman from '../assets/images/stars/star-chart-2b7f4560-90a8-11ed-948b-834ce149ae0d.svg'
import starChartMachine from '../assets/images/stars/star-chart-01d6e740-90a8-11ed-948b-834ce149ae0d.svg'
import starChartOthers from '../assets/images/stars/star-chart-748608c0-90a8-11ed-948b-834ce149ae0d.svg'

import descriptionCardArt from '../assets/images/card-nouns/description-card-ART000.svg'
import descriptionCardMachine from '../assets/images/card-nouns/description-card-MCH000.svg'
import descriptionCardNature from '../assets/images/card-nouns/description-card-NTR000.svg'
import descriptionCardHuman from '../assets/images/card-nouns/description-card-HUM000.svg'
import descriptionCardOther from '../assets/images/card-nouns/description-card-OTR000.svg'

export const CARDS_ULR = [
  machine,
  human,
  others,
  nature,
  art,
  art1,
  art2,
  art3,
  art4,
  art5,
  art6,
  art7,
  art8,
  art9,
  art10,
  machine1,
  machine2,
  machine3,
  machine4,
  human1,
  human2,
  human3,
  human4,
  human5,
  human6,
  human7,
  human8,
  human9,
  human10,
  human11,
  nature1,
  nature2,
  nature3,
  nature4,
  nature5,
  nature6,
  nature7,
  nature8,
  nature9,
  other1,
  other2,
  other3,
  other4
]

export const CONSTELATION_URL = [
  artConstelation,
  machineConstelation,
  humanConstelation,
  natureConstelation,
  otherConstelation
]

export const MESSAGE_CARD_URL = [
  btnPurpleReverse,
  btnRedReverse,
  btnBlueReverse,
  btnOrangeReverse,
  btnLightBlueReverse,
  btnGreenReverse
]

export const DESCRIPTION_CARD_URL = [
  descriptionCardArt,
  descriptionCardMachine,
  descriptionCardNature,
  descriptionCardHuman,
  descriptionCardOther
]

export const ARROWS_NOUN_URL = {
  orange: { right: arrowRightOrange, left: arrowLeftOrange },
  blue: { right: arrowRightBlue, left: arrowLeftBlue },
  red: { right: arrowRightRed, left: arrowLeftRed },
  green: { right: arrowRightGreen, left: arrowLeftGreen },
  'light-blue': { right: arrowRightLightBlue, left: arrowLeftLightBlue }
}

export const STAR_CHART_URL = [
  starChartConqueror,
  starChartCreator,
  starChartDiver,
  starChartWeaver,
  starCharHuman,
  starChartNature,
  starChartArts,
  starChartMachine,
  starChartOthers
]

import { useFormik } from 'formik'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import iconEmail from '../../../assets/images/icons/icon-email.svg'
import iconLock from '../../../assets/images/icons/icon-lock.svg'
import { getWorkspaceParams } from '../../../common/methods/params'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import ButtonReturn from '../../../components/shared/ButtonReturn'
import ErrorMessage from '../../../components/shared/ErrorMessage'
import { MESSAGES } from '../../../constants/response_message'
import { MessageResult } from '../../../models/MessageResult'
import { authLogin } from '../../../services/apis/auth'

const RecoveryChangeSuccess = () => {
  const [message, setMessage] = React.useState(new MessageResult(false, false, ''))

  const navigate = useNavigate()

  const location = useLocation()

  const { url, workspace, group } = getWorkspaceParams()

  const validateSchema = Yup.object().shape({
    email: Yup.string().email(MESSAGES.REGISTER.WRONG.EMAIL.PATH).required(MESSAGES.REGISTER.WRONG.EMPTY),
    password: Yup.string().required(MESSAGES.REGISTER.WRONG.EMPTY)
  })

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      email: location.state.email,
      password: location.state.password,
      domain: workspace,
      group
    },
    validationSchema: validateSchema,
    onSubmit: async (values) => {
      await authLogin(values).then(data => {
        if (data.status) {
          navigate(`/${url}` + data.page)
        } else {
          setMessage({ isView: true, status: data.status, message: data.message })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    validate: values => {
      validateSchema.validate(values).catch(err => {
        const error: string = err.errors[0]
        setMessage({ isView: true, status: false, message: error })
      })
    }
  })

  return (
    <article className="bg-main auth-page bg-auth">
      <section className="grid grid-rows-1 w-screen h-screen px-8 max-[480px]:px-1">
        <ButtonReturn linkReturn={`/${url}/login`} action="return"></ButtonReturn>

        <div className="grid relative w-[1071px] items-center justify-self-center after:w-[534px] after:h-[700px] after:absolute after:bottom-0 after:left-0 after:right-0 after:mx-auto after:opacity-10 after:bg-gradient-to-t after:from-violet-600 after:to-transparent bg-login max-[480px]:w-full max-[480px]:after:w-full">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="flex flex-col h-full gap-5 place-content-center">
                <div className="grid gap-1">
                  <label className="ff-m--regular text-base text-white max-[480px]:text-[14px]" htmlFor="email">Correo</label>
                  <div className="relative">
                    <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[17px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconEmail})` }}></span>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="pl-[45px]"
                      onChange={formik.handleChange}
                      onPaste={e => e.preventDefault()}
                      value={formik.values.email}
                      placeholder="abc@gmail.com"
                    />
                  </div>
                </div>
                <div className="grid gap-1">
                  <label className="ff-m--regular text-base text-white max-[480px]:text-[14px]" htmlFor="repeat_password">Contraseña</label>
                  <div className="relative">
                    <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[17px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconLock})` }}></span>
                    <input
                        id="repeat_password"
                        name="repeat_password"
                        type="password"
                        className="pl-[45px]"
                        onChange={formik.handleChange}
                        onPaste={e => e.preventDefault()}
                        value={formik.values.password}
                        placeholder="********"
                    />
                  </div>
                </div>
              </div>
              <div>
                {message.isView && !message.status && <ErrorMessage title={message.message}></ErrorMessage>}
              </div>
              <div className="flex justify-center">
                <ButtonRedirect type="submit" text="Ingresar" borderColor='#A41AE8' showDesing next></ButtonRedirect>
              </div>
            </div>
          </form>
        </div>
      </section>
    </article>
  )
}

export default RecoveryChangeSuccess

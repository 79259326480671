import { createStore } from 'redux'
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from '../actions/type'

const user = localStorage.getItem('tcm_auth_user') ? JSON.parse(localStorage.getItem('tcm_auth_user') || '{}') : ''

const intitialState = user
  ? { authenticated: true }
  : { authenticated: false }

const reducer = (state = intitialState, action: { type: any; }) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, authenticated: true }
    case LOGIN_FAIL:
      return { ...state, authenticated: false }
    case LOGOUT:
      return { ...state, authenticated: false }
    default:
      return state
  }
}

const store = createStore(reducer)

export default store
